export type SMAIndicatorData = { sma: number };
export type EMAIndicatorData = { ema1: number; ema2: number; ema3: number };
export type RSIIndicatorData = { rsi1: number; rsi2: number; rsi3: number };
export type BollingerBandsIndicatorData = { dn: number; mid: number; up: number };

export enum IndicatorEnum {
  SMA = 'SMA',
  EMA = 'EMA',
  RSI = 'RSI',
  BOLLINGER_BANDS = 'BollingerBands',
}
