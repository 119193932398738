import { OrderDurationEnum } from '../../interfaces';

export const calculateExpirationTime = (duration: OrderDurationEnum) => {
  const now = new Date();

  const dayInMs = 1000 * 60 * 60 * 24;

  switch (duration) {
    case OrderDurationEnum.DAY:
      return new Date(now.getTime() + dayInMs);

    case OrderDurationEnum.WEEK:
      return new Date(now.getTime() + dayInMs * 7);

    case OrderDurationEnum.MONTH: {
      const oneMonthLater = new Date(now);
      oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

      return oneMonthLater;
    }
    case OrderDurationEnum.THREE_MONTHS: {
      const threeMonthsLater = new Date(now);
      threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);

      return threeMonthsLater;
    }
    default:
      return now;
  }
};
