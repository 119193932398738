import { ethers } from 'ethers';
import { PriceConditionTypeEnum, Token } from '../../interfaces';

export const formatOrderPriceConditionValueToWei = (
  value: string,
  condition: PriceConditionTypeEnum,
  tokenIn: Token,
  tokenOut: Token,
) => {
  if (condition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) {
    return ethers.parseUnits(value, tokenOut.decimals).toString();
  }

  if (condition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) {
    return ethers.parseUnits(value, tokenIn.decimals).toString();
  }

  if (condition === PriceConditionTypeEnum.TOKEN_IN_USD) {
    return ethers.parseUnits(value, tokenIn.decimals).toString();
  }

  if (condition === PriceConditionTypeEnum.TOKEN_OUT_USD) {
    return ethers.parseUnits(value, tokenOut.decimals).toString();
  }

  return null;
};

export const formatWeiToReadablePriceConditionValue = (
  weiValue: string,
  condition: PriceConditionTypeEnum,
  tokenIn: Token,
  tokenOut: Token,
): string | null => {
  if (condition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) {
    return ethers.formatUnits(weiValue, tokenOut.decimals);
  }

  if (condition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) {
    return ethers.formatUnits(weiValue, tokenIn.decimals);
  }

  if (condition === PriceConditionTypeEnum.TOKEN_IN_USD) {
    return ethers.formatUnits(weiValue, tokenIn.decimals);
  }

  if (condition === PriceConditionTypeEnum.TOKEN_OUT_USD) {
    return ethers.formatUnits(weiValue, tokenOut.decimals);
  }

  return null;
};
