import { Token } from '../token';

export enum OHLCIntervalEnum {
  FIVE_MIN = 'ohlc_5min',
  ONE_MIN = 'ohlc_1min',
  THIRTY_MIN = 'ohlc_30min',
  ONE_HOUR = 'ohlc_1hour',
  TWO_HOUR = 'ohlc_2hour',
  ONE_DAY = 'ohlc_1day',
}

export interface OhlcPeriod {
  date: string;
  open: string;
  high: string;
  low: string;
  close: string;
  volume: string;
}

export type OHLCTOkenPairResponse = {
  pair: string;
  chainId: number;
  route: Token[];
  ohlcPeriod: OhlcPeriod[];
  oldestTime: string;
};
