import { PlusIcon } from 'lucide-react';
import { FC } from 'react';
import { Button } from '../../ui/button';

type CreateOrderActionsProps = {
  addOrder: () => void;
};

export const CreateOrderActions: FC<CreateOrderActionsProps> = ({ addOrder }) => (
  <Button
    variant="ghost"
    className="!text-xs w-full capitalize dark:hover:bg-primary-light-gray/40 dark:bg-primary-light-gray/20 text-primary-light-gray dark:text-white/80"
    onClick={addOrder}
  >
    <PlusIcon className="w-3 h-3 text-primary-light-gray dark:text-white/80" /> Create close order
  </Button>
);
