import { useCallback, useState } from 'react';
import { PriceConditionTypeEnum } from '../../../interfaces';

export type ValidatePriceConditionParams = {
  price: string;
  priceCondition: PriceConditionTypeEnum;
  latestTokenPairPrice: string | null;
  orderTokenInUsdPriceOracle: string | null;
  tokenInUsdPrice: number | null;
  orderTokenOutUsdPriceOracle: string | null;
  tokenOutUsdPrice: number | null;
};

export const useValidatePriceCondition = () => {
  const [priceDifferenceWarning, setPriceDifferenceWarning] = useState<{
    type: 'high' | 'low';
    delta: number;
  } | null>(null);

  const validatePrice = useCallback(
    ({
      price,
      priceCondition,
      latestTokenPairPrice,
      orderTokenInUsdPriceOracle,
      tokenInUsdPrice,
      orderTokenOutUsdPriceOracle,
      tokenOutUsdPrice,
    }: ValidatePriceConditionParams) => {
      if (!price || price === '0') {
        setPriceDifferenceWarning(null);

        return;
      }

      if (latestTokenPairPrice && priceCondition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) {
        const marketPrice = Number(latestTokenPairPrice);
        const userPrice = Number(price);

        const percentageDifference = Math.round((userPrice / marketPrice) * 100);

        if (percentageDifference > 120) {
          setPriceDifferenceWarning({ type: 'high', delta: percentageDifference });
        } else if (percentageDifference < 80) {
          setPriceDifferenceWarning({ type: 'low', delta: Math.abs(percentageDifference) });
        } else {
          setPriceDifferenceWarning(null);
        }
      } else if (latestTokenPairPrice && priceCondition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) {
        const userPrice = Number(price);
        const marketPrice = 1 / Number(latestTokenPairPrice);

        const percentageDifference = Math.round((userPrice / marketPrice) * 100);

        if (percentageDifference > 120) {
          setPriceDifferenceWarning({ type: 'high', delta: percentageDifference });
        } else if (percentageDifference < 80) {
          setPriceDifferenceWarning({ type: 'low', delta: Math.abs(percentageDifference) });
        } else {
          setPriceDifferenceWarning(null);
        }
      } else if (
        orderTokenInUsdPriceOracle &&
        tokenInUsdPrice &&
        priceCondition === PriceConditionTypeEnum.TOKEN_IN_USD
      ) {
        const marketPrice = 1 / Number(tokenInUsdPrice);
        const userPrice = Number(price);

        const percentageDifference = Math.round((userPrice / marketPrice) * 100);

        if (percentageDifference > 120) {
          setPriceDifferenceWarning({ type: 'high', delta: percentageDifference });
        } else if (percentageDifference < 80) {
          setPriceDifferenceWarning({ type: 'low', delta: Math.abs(percentageDifference) });
        } else {
          setPriceDifferenceWarning(null);
        }
      } else if (
        orderTokenOutUsdPriceOracle &&
        tokenOutUsdPrice &&
        priceCondition === PriceConditionTypeEnum.TOKEN_OUT_USD
      ) {
        const userPrice = Number(price);
        const marketPrice = 1 / Number(tokenOutUsdPrice);

        const percentageDifference = Math.round((userPrice / marketPrice) * 100);

        if (percentageDifference > 120) {
          setPriceDifferenceWarning({ type: 'high', delta: percentageDifference });
        } else if (percentageDifference < 80) {
          setPriceDifferenceWarning({ type: 'low', delta: Math.abs(percentageDifference) });
        } else {
          setPriceDifferenceWarning(null);
        }
      }
    },
    [],
  );

  return {
    validatePrice,
    priceDifferenceWarning,
    setPriceDifferenceWarning,
  };
};
