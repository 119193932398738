import { FC } from 'react';
import { cn } from '../../utils';
import { getNetworks } from '../../utils/blockchain/token-list';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../ui/select';
import { TypographyS } from '../ui/typography';

type ChainSelectorProps = {
  selectedChainId?: number;
  handleChainIdChange: (chainId: number) => void;
  className?: string;
};

export const ChainSelector: FC<ChainSelectorProps> = ({ className, handleChainIdChange, selectedChainId }) => {
  const networkList = getNetworks();

  const selectedNetwork = networkList.find((n) => n.chainId === selectedChainId);

  return (
    <Select
      onValueChange={(value) => {
        handleChainIdChange(+value);
      }}
    >
      <div className="flex items-center">
        <SelectTrigger className={cn(' bg-transparent cursor-pointer', className)}>
          <div className="flex items-center py-1 ">
            {selectedNetwork && (
              <img src={selectedNetwork.logoUri} alt={`${selectedNetwork.name} logo`} className="w-3.5 h-3.5 mr-1" />
            )}

            <TypographyS className="text-xs"> {selectedNetwork?.name ?? 'Select chain'}</TypographyS>
          </div>
        </SelectTrigger>
        <SelectContent>
          {networkList.map((option) => (
            <SelectItem className="cursor-pointer" key={option.chainId} value={option.chainId.toString()}>
              <div className="flex items-center py-1 ">
                <img src={option.logoUri} alt={`${option.name} logo`} className="w-4 h-4 mr-1" />

                <TypographyS className="text-xs">{option.name}</TypographyS>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </div>
    </Select>
  );
};
