import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ChevronDown, ChevronUp, TentIcon } from 'lucide-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector, useGetUserStopMarketOrders } from '../../../hooks';
import { OrderStatusEnum, SortOptionEnum, StopMarketOrderInfo } from '../../../interfaces';
import { cn } from '../../../utils';
import { SpinnerIcon } from '../../icons/spinner-icon';
import { TypographyP } from '../../ui/typography';
import { AmountColumn } from './columns/amount-column';
import { ChainColumn } from './columns/chain-column';
import { CreatedAtColumn } from './columns/created-at-column';
import { OrderActionColumn } from './columns/order-action-column';
import { OrderStatusColumn } from './columns/order-status-column';
import { PriceConditionsColumn } from './columns/price-conditions-column';
import { TokenPairColumn } from './columns/token-pair-column';
import { ChainFilter } from './filters/chain-filter';
import { TokenPairFilter } from './filters/token-pair-filter';
import { OrderListTabEnum, OrderTableFilterType } from './order-list';

type OrderTableProps = {
  type: OrderListTabEnum;
  activeFilters: OrderTableFilterType;
  setActiveFilters: React.Dispatch<
    React.SetStateAction<{
      active: OrderTableFilterType;
      history: OrderTableFilterType;
    }>
  >;
};

const PAGE_SIZE = 10;

export const OrderTable: FC<OrderTableProps> = ({ type, activeFilters, setActiveFilters }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const [sorting, setSorting] = useState<{
    createdAt: SortOptionEnum;
  }>({
    createdAt: SortOptionEnum.DESC,
  });

  const userOrdersFilter = useMemo(() => {
    const status =
      type === OrderListTabEnum.ACTIVE
        ? [OrderStatusEnum.CREATED]
        : [OrderStatusEnum.CANCELED, OrderStatusEnum.EXECUTED, OrderStatusEnum.EXPIRED, OrderStatusEnum.FAILED];

    const chainId = activeFilters.chainId;

    return {
      status,
      chainId,
      tokenInId: activeFilters.tokenInId,
      tokenOutId: activeFilters.tokenOutId,
      closeTokenInId: activeFilters.closeTokenInId,
      closeTokenOutId: activeFilters.closeTokenOutId,
    };
  }, [
    type,
    activeFilters.closeTokenInId,
    activeFilters.closeTokenOutId,
    activeFilters.tokenInId,
    activeFilters.tokenOutId,
    activeFilters.chainId,
  ]);

  const { fetchNextPage, orders, areUserStopMarketOrdersLoading } = useGetUserStopMarketOrders(
    {
      page: 1,
      size: PAGE_SIZE,
      filter: userOrdersFilter,
      sort: {
        createdAt: sorting.createdAt,
      },
    },
    isAuthenticated,
  );

  const columns: ColumnDef<StopMarketOrderInfo, StopMarketOrderInfo>[] = useMemo(
    () => [
      {
        accessorKey: 'chainId',
        enableSorting: false,
        header: 'Chain',
        id: 'chainId',
        cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <ChainColumn chainId={row.original.chainId} />,
      },
      {
        accessorKey: 'tokenPair',
        enableSorting: false,
        header: 'Pair',
        id: 'tokenPair',
        cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <TokenPairColumn order={row.original} />,
      },
      {
        accessorKey: 'conditions',
        enableSorting: false,
        header: 'Price Condition',
        cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <PriceConditionsColumn order={row.original} />,
      },
      {
        accessorKey: 'amountIn',
        enableSorting: false,
        header: 'Amount In',
        cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => (
          <AmountColumn amountIn={row.original.amountIn} tokenInId={row.original.tokenInId} />
        ),
      },
      {
        accessorKey: 'status',
        enableSorting: false,
        header: 'Status',
        cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <OrderStatusColumn order={row.original} />,
      },
      {
        accessorKey: 'createdAt',
        header: 'Date',
        enableSorting: true,
        sortDescFirst: true,
        cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => (
          <CreatedAtColumn createdAt={row.original.createdAt} />
        ),
      },
      {
        enableSorting: false,
        header: 'Actions',
        id: 'actions',
        cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => (
          <OrderActionColumn activeOrder={type === 'active'} order={row.original} />
        ),
      },
    ],
    [type, orders],
  );

  const table = useReactTable({
    data: orders,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    manualSorting: true,
  });

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 250 &&
          !areUserStopMarketOrdersLoading &&
          orders.length > 0 &&
          orders.length % PAGE_SIZE === 0
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, areUserStopMarketOrdersLoading],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const { rows } = table.getRowModel();

  return (
    <div>
      <div
        onScroll={(e) => fetchMoreOnBottomReached(e.currentTarget)}
        ref={tableContainerRef}
        className={cn('h-[300px]  overflow-auto relative border-t ', {})}
      >
        {areUserStopMarketOrdersLoading && isAuthenticated ? (
          <div className="w-full h-full absolute top-6 flex items-center justify-center  backdrop-blur-sm z-10">
            <SpinnerIcon />
          </div>
        ) : null}

        {(rows.length === 0 && !areUserStopMarketOrdersLoading) || !isAuthenticated ? (
          <div className="flex absolute top-6 h-full items-center w-full justify-center space-x-2">
            <TentIcon className="w-4 h-4 dark:text-white/50 text-black/50" />

            <TypographyP className="text-sm dark:text-white/50 text-black/50">No orders were found...</TypographyP>
          </div>
        ) : null}
        <table className="w-full text-left">
          <thead className="sticky top-0 z-10 dark:bg-primary-black bg-white">
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const canSort = header.column.getCanSort();

                    if (canSort) {
                      return (
                        <th
                          onClick={() =>
                            !areUserStopMarketOrdersLoading &&
                            setSorting({
                              createdAt:
                                sorting.createdAt === SortOptionEnum.DESC ? SortOptionEnum.ASC : SortOptionEnum.DESC,
                            })
                          }
                          key={header.id}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                  ? 'Sort descending'
                                  : 'Clear sort'
                              : undefined
                          }
                          className="p-0 text-center text-xs"
                        >
                          <div className="border border-t-0 border-l-0 w-full flex items-center justify-center  p-2 h-12">
                            {flexRender(header.column.columnDef.header, header.getContext())}

                            {sorting.createdAt === SortOptionEnum.DESC ? (
                              <ChevronDown className="w-4 h-4" />
                            ) : (
                              <ChevronUp className="w-4 h-4" />
                            )}
                          </div>
                        </th>
                      );
                    }

                    if (header.id === 'chainId') {
                      return (
                        <th key={header.id} className="p-0 text-center text-xs">
                          <div className="border border-t-0 w-full flex items-center justify-center  p-2 h-12">
                            {flexRender(header.column.columnDef.header, header.getContext())}

                            <ChainFilter
                              activeFilter={activeFilters.chainId}
                              onClearFilterClick={() => {
                                setActiveFilters((prev) => ({
                                  ...prev,
                                  active: {
                                    ...prev.active,
                                    chainId: undefined,
                                  },
                                }));
                              }}
                              onApplyFilterClick={(chainId) => {
                                setActiveFilters((prev) => ({
                                  ...prev,
                                  active: {
                                    ...prev.active,
                                    chainId,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </th>
                      );
                    }

                    if (header.id === 'tokenPair') {
                      return (
                        <th key={header.id} className="p-0 text-center text-xs">
                          <div className="border border-t-0 border-l-0 w-full flex items-center justify-center  p-2 h-12">
                            {flexRender(header.column.columnDef.header, header.getContext())}

                            <TokenPairFilter
                              activeFilter={{
                                tokenInId: activeFilters.tokenInId,
                                tokenOutId: activeFilters.tokenOutId,
                                chainId: activeFilters.chainId,
                              }}
                              onClearFilterClick={() => {
                                setActiveFilters((prev) => ({
                                  ...prev,
                                  active: {
                                    ...prev.active,
                                    tokenInId: undefined,
                                    tokenOutId: undefined,
                                    closeTokenInId: undefined,
                                    closeTokenOutId: undefined,
                                  },
                                }));
                              }}
                              onApplyFilterClick={(params) => {
                                setActiveFilters((prev) => ({
                                  ...prev,
                                  active: {
                                    ...prev.active,
                                    ...params,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </th>
                      );
                    }

                    return (
                      <th key={header.id} className="p-0 text-center text-xs">
                        <div className="border border-t-0 border-l-0 w-full flex items-center justify-center  p-2 h-12">
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody className="relative h-full ">
            {isAuthenticated
              ? rows.map((row, i) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={cn(`px-4 py-2 border text-xs`, {
                          'border-t-0': i === 0,
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        <div className="my-2 flex items-center justify-center ">
          {areUserStopMarketOrdersLoading ? <SpinnerIcon className="w-3 h-3" /> : null}
        </div>
      </div>
    </div>
  );
};
