import { utils } from 'klinecharts';
import { FC } from 'react';
import { TooltipLabel } from './tooltip-label';

type EMATooltipProps = {
  ema1: number;
  ema2: number;
  ema3: number;
  precision: number;
};

export const EMATooltip: FC<EMATooltipProps> = ({ ema1, ema2, ema3, precision }) => {
  return (
    <div className="flex gap-2 backdrop-blur-lg z-10 p-1 rounded-r-md w-fit bg-white/60 dark:bg-primary-black/20">
      <TooltipLabel title="EMA(6,12,20)" />
      <TooltipLabel
        className="text-orange-400 dark:text-orange-400"
        value={ema1 ? utils.formatFoldDecimal(ema1.toFixed(precision), precision) : 0}
        title="EMA6:"
      />
      <TooltipLabel
        className="text-purple-400 dark:text-purple-400"
        value={ema2 ? utils.formatFoldDecimal(ema2.toFixed(precision), precision) : 0}
        title="EMA12:"
      />
      <TooltipLabel
        className="text-blue-400 dark:text-blue-400"
        value={ema3 ? utils.formatFoldDecimal(ema3.toFixed(precision), precision) : 0}
        title="EMA20:"
      />
    </div>
  );
};
