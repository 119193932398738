import { FC } from 'react';
import { Token } from '../../../interfaces';
import { cn, formatAmount } from '../../../utils';
import { TokenLabel } from '../../custom/token-label';
import { TypographyS } from '../../ui/typography';

type PriceConditionSummaryProps = {
  value: string;
  tokenIn: Token;
  tokenOut: Token;
  closeOrder: boolean;
};

export const PriceConditionSummary: FC<PriceConditionSummaryProps> = ({ value, tokenIn, tokenOut, closeOrder }) => {
  return (
    <div className="flex gap-1">
      <TypographyS>price crosses </TypographyS>
      <TypographyS
        className={cn('mr-1 dark:text-yellow-400', {
          'dark:text-purple-400': closeOrder,
        })}
      >
        {formatAmount({
          value: +value,
          withoutFormatForSmallValue: true,
          minimumFractionDigits: 2,
          maximumFractionDigits: 4,
          precise: true,
        })}{' '}
      </TypographyS>
      <TokenLabel imgClassName="w-3.5 h-3.5" token={tokenOut} />
      <TypographyS>
        per{' '}
        <TypographyS
          className={cn('dark:text-yellow-400', {
            'dark:text-purple-400': closeOrder,
          })}
        >
          1
        </TypographyS>
      </TypographyS>
      <TokenLabel imgClassName="w-3.5 h-3.5" token={tokenIn} />
    </div>
  );
};
