import { FilterIcon } from 'lucide-react';
import { FC, useState } from 'react';
import { cn } from '../../../../utils';
import { getNetworks, getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { Button } from '../../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { TypographyP } from '../../../ui/typography';
import { TokenSelector } from '../../order/token-selector';

type TokenFilters = {
  tokenInId?: string;
  tokenOutId?: string;
  closeTokenInId?: string;
  closeTokenOutId?: string;
  chainId?: number;
};

type TokenPairFilterProps = {
  onClearFilterClick: () => void;
  onApplyFilterClick: (params: TokenFilters) => void;
  activeFilter: TokenFilters | undefined;
};

export const TokenPairFilter: FC<TokenPairFilterProps> = ({ onApplyFilterClick, onClearFilterClick, activeFilter }) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [filter, setFilter] = useState<
    | {
        tokenInId?: string;
        tokenOutId?: string;
        closeTokenInId?: string;
        closeTokenOutId?: string;
      }
    | undefined
  >(activeFilter);

  return (
    <Popover modal open={showFilters} onOpenChange={(open) => setShowFilters(open)}>
      <PopoverTrigger asChild>
        <Button variant="ghost" className="p-1 w-fit h-fit ml-1">
          <FilterIcon
            className={cn('dark:text-white/70 text-black/60 max-w-3 max-h-3', {
              'dark:text-blue-400 contrast-150 text-blue-400': activeFilter?.tokenInId || activeFilter?.tokenOutId,
            })}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        onClick={(e) => e.stopPropagation()}
        className="flex  mt-1 items-end justify-start w-fit flex-col gap-4 bg-white/80 dark:bg-transparent backdrop-blur-md p-3"
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-2">
            <TypographyP className="font-semibold dark:text-yellow-400 dark:text-black/50 text-[11px]">
              Filter by open pair
            </TypographyP>

            <TokenSelector
              className="px-1 mr-0 flex justify-between !w-full"
              tokenList={getNetworks()
                .filter((n) => (activeFilter?.chainId ? n.chainId === activeFilter.chainId : true))
                .flatMap((n) => n.tokens)}
              label="Token In"
              showNetworkLabel
              contentClassName="w-full flex justify-between"
              selectedToken={filter?.tokenInId ? getTokenByTokenId(filter.tokenInId) : undefined}
              handleTokenChange={(token) =>
                setFilter((prev) => ({
                  ...prev,
                  tokenInId: `${token.chainId}_${token.address}`,
                }))
              }
            />

            <TokenSelector
              className="px-1 mr-0 flex justify-between !w-full"
              tokenList={getNetworks().flatMap((n) => n.tokens)}
              label="Token Out"
              showNetworkLabel
              selectedToken={filter?.tokenOutId ? getTokenByTokenId(filter.tokenOutId) : undefined}
              handleTokenChange={(token) =>
                setFilter((prev) => ({
                  ...prev,
                  tokenOutId: `${token.chainId}_${token.address}`,
                }))
              }
              contentClassName="w-full flex justify-between"
            />
          </div>

          <div className="flex flex-col space-y-2">
            <TypographyP className="font-semibold dark:text-purple-400 dark:text-black/50 text-[11px]">
              Filter by close pair
            </TypographyP>

            <TokenSelector
              className="px-1 mr-0 flex justify-between !w-full"
              tokenList={getNetworks().flatMap((n) => n.tokens)}
              label="Token In"
              showNetworkLabel
              contentClassName="w-full flex justify-between"
              selectedToken={filter?.closeTokenInId ? getTokenByTokenId(filter.closeTokenInId) : undefined}
              handleTokenChange={(token) =>
                setFilter((prev) => ({
                  ...prev,
                  closeTokenInId: `${token.chainId}_${token.address}`,
                }))
              }
            />

            <TokenSelector
              className="px-1 mr-0 flex justify-between !w-full"
              tokenList={getNetworks().flatMap((n) => n.tokens)}
              label="Token Out"
              showNetworkLabel
              selectedToken={filter?.closeTokenOutId ? getTokenByTokenId(filter.closeTokenOutId) : undefined}
              handleTokenChange={(token) =>
                setFilter((prev) => ({
                  ...prev,
                  closeTokenOutId: `${token.chainId}_${token.address}`,
                }))
              }
              contentClassName="w-full flex justify-between"
            />
          </div>
        </div>

        <div className="flex gap-2 items-end mt-2">
          <Button
            onClick={() => {
              setFilter(undefined);
              onClearFilterClick();
              setShowFilters(false);
            }}
            variant="ghost"
            className="dark:text-white px-2 py-1.5 h-fit text-xs"
          >
            Clear
          </Button>
          <Button
            disabled={!filter}
            onClick={() => {
              filter && onApplyFilterClick(filter);
              setShowFilters(false);
            }}
            className="dark:bg-primary-light-gray dark:text-white px-2 py-1.5 h-fit text-xs"
            variant="ghost"
          >
            Apply
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
