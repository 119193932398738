import { ethers } from 'ethers';
import { Address, Chain, parseAbi, PublicClient, WalletClient } from 'viem';
import { getRpcProvider } from '../../providers';

const erc20Abi = [
  'function approve(address spender, uint256 amount) returns (bool)',
  'function balanceOf(address owner) view returns (uint256)',
  'function decimals() view returns (uint8)',
];
const parsedERC20ABI = parseAbi(erc20Abi);

export const approveERC20 = async (
  chain: Chain,
  tokenAddress: string,
  spenderAddress: string,
  amount: string,
  walletClient: WalletClient,
  publicClient: PublicClient,
) => {
  if (!walletClient) {
    console.error('No wallet client provided');

    throw new Error('No wallet client provided');
  }

  const [account] = await walletClient.getAddresses();

  const decimals = (await publicClient.readContract({
    address: tokenAddress as `0x${string}`,
    abi: parsedERC20ABI,
    functionName: 'decimals',
  })) as ethers.Numeric;

  const formattedAmount = ethers.parseUnits(amount.toString(), decimals);

  try {
    const hash = await walletClient.writeContract({
      address: tokenAddress as Address,
      abi: parsedERC20ABI,
      functionName: 'approve',
      args: [spenderAddress, formattedAmount],
      account,
      chain,
    });

    console.log(`Transaction hash: ${hash}`);
  } catch (error) {
    console.error('Approval failed:', error);

    throw error;
  }
};

export const getERC20Balance = async (chainId: number, tokenAddress: string, ownerAddress: string) => {
  const provider = getRpcProvider(chainId);

  if (!provider) {
    console.error('Unsupported network');

    throw new Error('Unsupported network');
  }

  const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider);

  const [rawBalance, decimals] = await Promise.all([tokenContract.balanceOf(ownerAddress), tokenContract.decimals()]);

  return {
    formattedTokenBalance: ethers.formatUnits(rawBalance, decimals),
    rawTokenBalance: rawBalance ? (rawBalance.toString() as string) : '0',
  };
};
