import { useMutation } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import { Address, UserRejectedRequestError } from 'viem';
import { useDisconnect, useSignMessage } from 'wagmi';
import { APIConfig } from '../../configs';
import { setAuthenticated } from '../../store';
import { localStorageManager } from '../../utils';
import { useAppDispatch } from '../store';
import { toast } from '../ui/use-toast';
import { useVerificationMessage } from './use-verification-message';

const signInUser = async (address: Address, signature: string) => {
  return await axios.post(
    `${APIConfig.baseURL}/u/sign-in`,
    {
      address,
      signature,
    },
    {
      withCredentials: true,
    },
  );
};

export const useAuthBySign = () => {
  const dispatch = useAppDispatch();

  const { generateVerificationMessage } = useVerificationMessage();

  const { disconnect } = useDisconnect();

  const { signMessageAsync } = useSignMessage();

  const authBySign = async (address: Address) => {
    const walletAddress = address.toLowerCase() as Address;

    const { nonce } = await generateVerificationMessage({ address: walletAddress });

    const sign = await signMessageAsync({ account: walletAddress, message: nonce });

    await signInUser(walletAddress, sign);

    return address;
  };

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: (address: Address) => authBySign(address),
    onSuccess: (address) => {
      localStorageManager.set(`auth-${address.toLowerCase()}`, true, 5 * 60 * 60 * 1000); // 5 hr expiration

      dispatch(setAuthenticated({ isAuthenticated: true }));

      toast({
        title: 'Sign in',
        description: 'Signed in to the DEX Standard platform',
      });
    },
    onError: (err) => {
      console.error(err);
      let message = `Unexpected error occurred`;

      dispatch(setAuthenticated({ isAuthenticated: false }));

      if (err instanceof UserRejectedRequestError) {
        disconnect();
        message = err.shortMessage;
      }

      if (isAxiosError(err)) {
        message = err.response?.data?.error || message;
      }

      toast({
        title: 'Sign in error',
        description: message,
      });
    },
  });

  return {
    authBySignError: error,
    isAuthBySignPending: isPending,
    authBySign: mutateAsync,
  };
};
