import { useEffect, useState } from 'react';
import { localStorageManager } from '../../utils';

export enum ThemeEnum {
  DARK = 'dark',
  LIGHT = 'light',
}

export const useTheme = (forceUpdate?: () => void) => {
  const [theme, setTheme] = useState<ThemeEnum>(ThemeEnum.LIGHT);
  const [isThemeSet, setThemeSet] = useState<boolean>(false);

  const toggleTheme = (updatedTheme: ThemeEnum) => {
    setTheme(updatedTheme);

    localStorageManager.set('theme', updatedTheme);

    document.documentElement.classList.toggle('dark', updatedTheme === ThemeEnum.DARK);

    forceUpdate && forceUpdate();
  };

  useEffect(() => {
    if (!isThemeSet) {
      const themeFromLocalStorage = (localStorageManager.get<ThemeEnum>('theme') as ThemeEnum) || ThemeEnum.LIGHT;

      document.documentElement.classList.toggle(
        'dark',
        themeFromLocalStorage === ThemeEnum.DARK ||
          (!themeFromLocalStorage && window.matchMedia('(prefers-color-scheme: dark)').matches),
      );

      setTheme(themeFromLocalStorage);
      setThemeSet(true);
    }
  }, [isThemeSet, setThemeSet, setTheme]);

  return {
    toggleTheme,
    theme,
    isThemeSet,
    isDarkTheme: theme === ThemeEnum.DARK,
    isLightTheme: theme === ThemeEnum.LIGHT,
  };
};
