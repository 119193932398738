import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenPair } from '../../interfaces';

export interface StopMarketOrderState {
  stopMarketOrderPair: TokenPair | null;
}

const initialState: StopMarketOrderState = {
  stopMarketOrderPair: null,
};

export const stopMarketOrderSlice = createSlice({
  name: 'stop-market-order',
  initialState,
  reducers: {
    setStopMarketOrderPair: (state, action: PayloadAction<TokenPair>) => {
      state.stopMarketOrderPair = action.payload;
    },
  },
});

export const { setStopMarketOrderPair } = stopMarketOrderSlice.actions;

export const stopMarketOrderSliceReducer = stopMarketOrderSlice.reducer;
