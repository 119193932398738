import { PriceConditionTypeEnum, Token } from '../../../interfaces';

export const getCloseOrderHeaderLabel = (
  tokenInSymbol: string,
  tokenOutSymbol: string,
  orderPriceCondition: PriceConditionTypeEnum,
) => {
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) return `When 1 ${tokenInSymbol} is worth`;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) return `When 1 ${tokenOutSymbol} is worth`;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_IN_USD) return `When 1 ${tokenInSymbol} is worth`;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_OUT_USD) return `When 1 ${tokenOutSymbol} is worth`;
};

export const getOpenOrderHeaderLabel = (
  tokenInSymbol: string,
  tokenOutSymbol: string,
  orderPriceCondition: PriceConditionTypeEnum,
) => {
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) return `When 1 ${tokenInSymbol} is worth`;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) return `When 1 ${tokenOutSymbol} is worth`;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_IN_USD) return `When 1 ${tokenInSymbol} is worth`;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_OUT_USD) return `When 1 ${tokenOutSymbol} is worth`;
};

export const getTokenFromPriceConditionType = (
  tokenIn: Token,
  tokenOut: Token,
  orderPriceCondition: PriceConditionTypeEnum,
) => {
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) return tokenOut;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) return tokenIn;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_IN_USD) return tokenOut;
  if (orderPriceCondition === PriceConditionTypeEnum.TOKEN_OUT_USD) return tokenIn;

  return tokenIn;
};
