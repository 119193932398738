import { useQuery } from '@tanstack/react-query';
import { getUsdPrice } from '../../utils';

export const useGetUSDTokenPrice = (
  requests: {
    oracleAddress: string;
    chainId: number;
  }[],
) => {
  const { data } = useQuery({
    queryKey: ['usdTokenPrices', requests.map((r) => `${r.chainId}/${r.oracleAddress}`).join('-')],
    queryFn: () => Promise.all(requests.map((r) => getUsdPrice(r.chainId, r.oracleAddress))),
    enabled: !!requests.length,
    refetchOnWindowFocus: true,
    staleTime: 1 * 60 * 1000, // 5 minutes
  });

  return data || [];
};
