import { Network } from '../network';
import { OrderFormType } from '../order/limit-order-interface';
import { StopMarketOrderInfo } from '../order/stop-market-order-interface';

export enum ModalName {
  CREATE_STOP_MARKET_ORDER_SUMMARY = 'createStopMarketOrderSummary',
  STOP_MARKET_ORDER_INFO = 'stopMarketOrderInfo',
}

export type ConditionalModalInfoType<T> = T extends ModalName.CREATE_STOP_MARKET_ORDER_SUMMARY
  ? {
      network: Network;
      chainId: number;
      orders: OrderFormType;
      onOrderCreated: () => void;
    }
  : T extends ModalName.STOP_MARKET_ORDER_INFO
    ? {
        order: StopMarketOrderInfo;
      }
    : never;
