import { useChainModal } from '@rainbow-me/rainbowkit';
import { useCallback, useEffect } from 'react';
import { Address } from 'viem';
import { useAccount, useAccountEffect } from 'wagmi';
import { SUPPORTED_CHAINS } from '../../configs';
import { setAuthenticated } from '../../store';
import { isWalletAuthenticatedLocally } from '../../utils';
import { useAppDispatch } from '../store';
import { useAuthBySign } from './use-auth-by-sign';
import { useCheckAuth } from './use-check-auth';
import { useSignOut } from './use-sign-out';

export const useVerifyAccountListener = () => {
  const { authBySign } = useAuthBySign();
  const { checkAuth } = useCheckAuth();
  const { signOut } = useSignOut();
  const dispatch = useAppDispatch();
  const { chain } = useAccount();
  const { openChainModal } = useChainModal();

  const { address, isReconnecting } = useAccount();

  useAccountEffect({
    async onDisconnect() {
      if (address) {
        const isAuthenticated = await checkAuth();

        if (isAuthenticated) {
          await signOut();
        }
      }
    },
  });

  const verifyAccountAndSignIn = useCallback(
    async (address: Address) => {
      const isAuthenticatedLocally = isWalletAuthenticatedLocally(address);

      if (!isAuthenticatedLocally) {
        await authBySign(address);
      } else {
        const isAuthenticated = await checkAuth();

        if (!isAuthenticated) {
          await authBySign(address);
        } else {
          dispatch(setAuthenticated({ isAuthenticated: true }));
        }
      }
    },
    [authBySign],
  );

  useEffect(() => {
    // we use custom listener to catch changing the addresses in metamask or others wallet managers
    if (address && !isReconnecting) {
      verifyAccountAndSignIn(address);
    }
  }, [address, isReconnecting]);

  useEffect(() => {
    // we use custom listener to catch changing the chain in metamask or others wallet managers
    if (!chain || (chain?.id && !SUPPORTED_CHAINS.find((c) => c.id === chain.id))) {
      openChainModal && openChainModal();
    }
  }, [chain, SUPPORTED_CHAINS, openChainModal, isReconnecting]);
};
