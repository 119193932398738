import { Indicator, IndicatorSeries, IndicatorTemplate, KLineData } from 'klinecharts';
import { IndicatorEnum } from '../../../../interfaces';

export interface BOLL {
  up?: number;
  mid?: number;
  dn?: number;
}

function getBollMd(dataList: KLineData[], ma: number): number {
  const dataSize = dataList.length;
  let sum = 0;
  dataList.forEach((data) => {
    const closeMa = data.close - ma;
    sum += closeMa * closeMa;
  });
  sum = Math.abs(sum);

  return Math.sqrt(sum / dataSize);
}

export const bollingerBandsIndicator: IndicatorTemplate<BOLL> = {
  name: IndicatorEnum.BOLLINGER_BANDS,
  shortName: 'BOLL',
  series: IndicatorSeries.Price,
  calcParams: [20, 2],
  precision: 2,
  shouldOhlc: true,
  figures: [
    { key: 'up', title: 'UP: ', type: 'line' },
    { key: 'mid', title: 'MID: ', type: 'line' },
    { key: 'dn', title: 'DN: ', type: 'line' },
  ],
  calc: (dataList: KLineData[], indicator: Indicator<BOLL>) => {
    const params = indicator.calcParams as number[];
    const p = params[0] - 1;
    let closeSum = 0;

    return dataList.map((kLineData: KLineData, i: number) => {
      const close = kLineData.close;
      const boll: BOLL = {};
      closeSum += close;

      if (i >= p) {
        boll.mid = closeSum / params[0];
        const md = getBollMd(dataList.slice(i - p, i + 1), boll.mid);
        boll.up = boll.mid + params[1] * md;
        boll.dn = boll.mid - params[1] * md;
        closeSum -= dataList[i - p].close;
      }

      return boll;
    });
  },
};
