import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { authSliceReducer } from './auth/auth-slice';
import { modalSliceReducer } from './global/modal-slice';
import { pairsOHLCSliceReducer } from './ohlc/pairs-ohlc-slice';
import { stopMarketOrderSliceReducer } from './order/stop-market-order-slice';

enableMapSet();
export const store = configureStore({
  reducer: {
    stopMarketOrder: stopMarketOrderSliceReducer,
    modal: modalSliceReducer,
    pairsOHLC: pairsOHLCSliceReducer,
    auth: authSliceReducer,
  },
  // middleware: () => new Tuple(createLogger()),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
