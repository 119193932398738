import { FC } from 'react';
import { Token } from '../../interfaces';
import { cn } from '../../utils';
import { TypographyS } from '../ui/typography';
import { TokenLogo } from './token-logo';

type TokenLabelProps = {
  token: Token;
  textClassName?: string;
  imgClassName?: string;
};

export const TokenLabel: FC<TokenLabelProps> = ({ token, textClassName, imgClassName }) => {
  return (
    <div className="flex gap-1 items-center">
      <TokenLogo token={token} imgClassName={imgClassName} />
      <TypographyS className={cn('text-xs', textClassName)}>{token.symbol}</TypographyS>
    </div>
  );
};
