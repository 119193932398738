import { FilterIcon } from 'lucide-react';
import { FC, useState } from 'react';
import { cn } from '../../../../utils';
import { ChainSelector } from '../../../network/chain-selector';
import { Button } from '../../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { TypographyP } from '../../../ui/typography';

type ChainFilterProps = {
  onClearFilterClick: () => void;
  onApplyFilterClick: (chainId: number) => void;
  activeFilter: number | undefined;
};

export const ChainFilter: FC<ChainFilterProps> = ({ onApplyFilterClick, onClearFilterClick, activeFilter }) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [filter, setFilter] = useState<number | undefined>(activeFilter);

  return (
    <Popover
      modal
      open={showFilters}
      onOpenChange={(open) => {
        setShowFilters(open);
        setFilter(undefined);
      }}
    >
      <PopoverTrigger asChild>
        <Button variant="ghost" className="p-1 w-fit h-fit ml-1 ">
          <FilterIcon
            className={cn('dark:text-white/70 text-black/60 max-w-3 max-h-3', {
              'dark:text-blue-400 contrast-150 text-blue-400': activeFilter,
            })}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        onClick={(e) => e.stopPropagation()}
        className="flex mt-1 items-center justify-start w-fit flex-col gap-4 bg-white/80 dark:bg-transparent backdrop-blur-md p-3"
      >
        <div className="flex flex-col items-start">
          <TypographyP className="text-xs font-semibold mb-1 dark:text-white">Filter by chain</TypographyP>
          <ChainSelector
            className=""
            selectedChainId={filter || activeFilter}
            handleChainIdChange={(chainId) => setFilter(chainId)}
          />

          <div className="flex gap-2 items-center mt-2">
            <Button
              onClick={() => {
                setFilter(undefined);
                onClearFilterClick();
                setShowFilters(false);
              }}
              variant="ghost"
              className="dark:text-white px-2 py-1.5 h-fit text-xs"
            >
              Clear
            </Button>
            <Button
              disabled={!filter}
              onClick={() => {
                filter && onApplyFilterClick(filter);
                setShowFilters(false);
              }}
              className="dark:bg-primary-light-gray dark:text-white px-2 py-1.5 h-fit text-xs"
              variant="ghost"
            >
              Apply
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
