import { Indicator, IndicatorSeries, IndicatorTemplate, KLineData } from 'klinecharts';
import { IndicatorEnum } from '../../../../interfaces';

export interface Ema {
  ema1?: number;
  ema2?: number;
  ema3?: number;
}

export const exponentialMovingAverageIndicator: IndicatorTemplate<Ema> = {
  name: IndicatorEnum.EMA,
  shortName: IndicatorEnum.EMA,
  series: IndicatorSeries.Price,
  calcParams: [6, 12, 20],
  precision: 2,
  shouldOhlc: true,
  figures: [
    { key: 'ema1', title: 'EMA6: ', type: 'line' },
    { key: 'ema2', title: 'EMA12: ', type: 'line' },
    { key: 'ema3', title: 'EMA20: ', type: 'line' },
  ],
  regenerateFigures: (params: unknown[]) =>
    params.map((p, i: number) => ({ key: `ema${i + 1}`, title: `EMA${p}: `, type: 'line' })),

  calc: (dataList: KLineData[], indicator: Indicator<Ema>) => {
    const { calcParams, figures } = indicator;
    let closeSum = 0;
    const emaValues: number[] = [];
    const params = calcParams as number[];

    return dataList.map((kLineData: KLineData, i: number) => {
      const ema: Record<string, number> = {};
      const close = kLineData.close;
      closeSum += close;

      params.forEach((p: number, index: number) => {
        if (i >= p - 1) {
          if (i > p - 1) {
            emaValues[index] = (2 * close + (p - 1) * emaValues[index]) / (p + 1);
          } else {
            emaValues[index] = closeSum / p;
          }
          ema[figures[index].key] = emaValues[index];
        }
      });

      return ema;
    });
  },
};
