import { ChartColumnStackedIcon } from 'lucide-react';
import { FC } from 'react';
import { Button } from '../../ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';
import { TypographyS } from '../../ui/typography';

type SetMarketPriceButtonProps = {
  onClick: () => void;
  disabled: boolean;
};

export const SetMarketPriceButton: FC<SetMarketPriceButtonProps> = ({ onClick, disabled }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={onClick}
            disabled={disabled}
            variant="ghost"
            className="p-0 w-3 h-3  hover:bg-transparent hover:dark:text-white/40 dark:text-white/60 hover:text-black/40 text-black/60"
          >
            <ChartColumnStackedIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="top" className="bg-white/30 shadow dark:bg-primary-black/30 backdrop-blur-lg p-2">
          <TypographyS className="dark:text-white text-black">Set to market price</TypographyS>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
