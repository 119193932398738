import { IndicatorTemplate, KLineData, LineType, PolygonType } from 'klinecharts';

export const flatCandleStickIndicator: (flatCandleStickData: KLineData[]) => IndicatorTemplate = (
  flatCandleStickData: KLineData[],
) => ({
  name: 'flatCandleStickIndicator',
  shortName: 'flatCandleStickIndicator',
  figures: [
    {
      key: 'close',
      type: 'circle',
      attrs: ({ coordinate }) => {
        return {
          x: coordinate?.current.x,
          // eslint-disable-next-line
          y: (coordinate?.current as any).close,
          r: 3,
        };
      },
      styles: () => ({
        style: PolygonType.Stroke,
        color: 'white',
        borderColor: '#babbbf',
        borderSize: 1,
        paddingTop: 2,
        paddingBottom: 2,
        borderStyle: LineType.Solid,
      }),
    },
  ],
  calc: (data) => {
    return data.map((d) => {
      const match = flatCandleStickData.find((f) => f.timestamp === d.timestamp);
      if (match) {
        return {
          close: d.close,
        };
      }
      return null;
    });
  },
});
