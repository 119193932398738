import { formatDate } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  ConditionalModalInfoType,
  ModalName,
  OrderStatusEnum,
  PriceConditionPositionTypeEnum,
  Token,
} from '../../../interfaces';
import { closeModal } from '../../../store';
import { cn, formatAmount } from '../../../utils';
import { getNetworkByChainId, getTokenByTokenId } from '../../../utils/blockchain/token-list';
import { TokenLabel } from '../../custom/token-label';
import { Button } from '../../ui/button';
import { Dialog, DialogContent, DialogFooter, DialogTitle } from '../../ui/dialog';
import { Separator } from '../../ui/separator';
import { TypographyH4, TypographyP, TypographyS } from '../../ui/typography';

export const StopMarketOrderInfoModal = () => {
  const dispatch = useAppDispatch();
  const activeModalInfo = useAppSelector((state) => state.modal);
  const { order } = activeModalInfo.modalInfo! as ConditionalModalInfoType<ModalName.STOP_MARKET_ORDER_INFO>;

  const onClose = () => {
    dispatch(closeModal());
  };

  const network = getNetworkByChainId(order.chainId)!;
  const tokenIn = getTokenByTokenId(order.tokenInId)!;
  const tokenOut = getTokenByTokenId(order.tokenOutId)!;

  const openOrderInfo = {
    tokenIn,
    tokenOut,
    amountIn: order.amountIn,
    priceCondition: order.priceConditions.find((c) => c.positionType === PriceConditionPositionTypeEnum.OPEN)!,
  };

  const closeOrderInfo = order.closeTokenOutId
    ? {
        tokenIn: tokenOut,
        tokenOut: getTokenByTokenId(order.closeTokenOutId)!,
        priceCondition: order.priceConditions.find((c) => c.positionType === PriceConditionPositionTypeEnum.CLOSE)!,
      }
    : null;

  const renderSwapAmount = (amountIn: string | null, tokenIn: Token, tokenOut: Token) => (
    <div className="text-sm flex">
      <TypographyP className="mr-1">
        Swap{' '}
        {amountIn
          ? formatAmount({
              value: +amountIn,
            })
          : ''}{' '}
      </TypographyP>
      {<TokenLabel token={tokenIn} />}
      <TypographyP className="mx-1"> for </TypographyP>
      {<TokenLabel token={tokenOut} />}
      <TypographyP className="mx-1"> when: </TypographyP>
    </div>
  );

  const renderPriceConditionSummary = (amountIn: string, tokenIn: Token, tokenOut: Token) => (
    <div className="text-sm flex">
      <TypographyP className="mr-1">
        Price crosses{' '}
        {formatAmount({
          value: +amountIn,
        })}{' '}
      </TypographyP>
      {<TokenLabel token={tokenIn} />}
      <TypographyP className="mx-1"> per 1 </TypographyP>
      {<TokenLabel token={tokenOut} />}
    </div>
  );

  return (
    <Dialog
      open={activeModalInfo.activeModal === ModalName.STOP_MARKET_ORDER_INFO}
      modal
      onOpenChange={() => dispatch(closeModal())}
    >
      <DialogTitle>order info</DialogTitle>
      <DialogContent>
        <TypographyH4 className="font-bold mb-3 items-center flex ">
          Order Info{' '}
          <TypographyS className="ml-2 pt-0.5 text-sm font-medium dark:text-white/60">#{order.id}</TypographyS>
        </TypographyH4>

        <div className="flex items-center mb-3">
          <img src={network.logoUri} alt={`${network.name} logo`} className="w-5 h-5 mr-2" />
          <span className="text-sm font-medium">{network.name}</span>
        </div>

        <ul className="mb-2">
          <li>
            <div className="mb-2">
              <TypographyP className="dark:text-yellow-400 font-medium text-sm">{'Open Position'}</TypographyP>
            </div>

            {renderSwapAmount(openOrderInfo.amountIn, openOrderInfo.tokenIn, openOrderInfo.tokenOut)}

            <ul className="list-disc pl-5 mt-1 text-sm">
              {renderPriceConditionSummary(openOrderInfo.priceCondition.value, tokenIn, tokenOut)}
            </ul>
          </li>

          <div className="flex items-center font-medium text-sm mt-2">
            <TypographyP className="font-medium mr-2">Order Status:</TypographyP>
            <TypographyS
              className={cn('capitalize text-red-500', {
                'text-green-500':
                  order.openStatus === OrderStatusEnum.CREATED || order.openStatus === OrderStatusEnum.EXECUTED,
              })}
            >
              {' '}
              {order.openStatus}
            </TypographyS>
          </div>

          {closeOrderInfo ? (
            <>
              <Separator className="my-4" />
              <li>
                <div className="mb-2">
                  <TypographyP className="dark:text-purple-400 font-medium text-sm">{'Close Position'}</TypographyP>
                </div>
                <TypographyP>{renderSwapAmount(null, closeOrderInfo.tokenIn, closeOrderInfo.tokenOut)}</TypographyP>
                <ul className="list-disc pl-5 mt-1">
                  {closeOrderInfo.tokenOut &&
                    renderPriceConditionSummary(
                      closeOrderInfo.priceCondition.value,
                      closeOrderInfo.tokenIn,
                      closeOrderInfo.tokenOut,
                    )}
                </ul>
              </li>
            </>
          ) : null}
        </ul>

        <div className="flex items-center  text-sm mb-2">
          <TypographyP className="font-medium mr-2">Created:</TypographyP>
          <TypographyS> {formatDate(order.createdAt, 'MMM d hh:mm:ss a')}</TypographyS>
        </div>

        {order.closeStatus ? (
          <div className="flex items-center  text-sm mb-2">
            <TypographyP className="font-medium mr-2">Close Order Status:</TypographyP>
            <TypographyS
              className={cn('capitalize text-red-500', {
                'text-green-500':
                  order.closeStatus === OrderStatusEnum.CREATED || order.closeStatus === OrderStatusEnum.EXECUTED,
              })}
            >
              {' '}
              {order.closeStatus}
            </TypographyS>
          </div>
        ) : null}

        <DialogFooter className="flex !justify-between mt-4">
          <Button variant="outline" className=" py-2 px-4 rounded-lg text-sm font-medium" onClick={onClose}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
