import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAccount } from 'wagmi';
import { z } from 'zod';
import {
  useAppDispatch,
  useAppSelector,
  useGetERC20Balance,
  useGetLatestPairPrice,
  useValidatePriceCondition,
} from '../../../hooks';
import {
  FormLimitCloseOrderSchema,
  FormLimitOpenOrderSchema,
  Network,
  OrderDurationEnum,
  PriceConditionTypeEnum,
  Token,
} from '../../../interfaces';
import { setStopMarketOrderPair } from '../../../store/order/stop-market-order-slice';
import { calculateExpirationTime } from '../../../utils';
import { getAvailableTokens, getNetworks } from '../../../utils/blockchain/token-list';
import { CustomConnectButton } from '../../custom/connect-button';
import { NetworkSelector } from '../../custom/network-selector';
import { Button } from '../../ui/button';
import { Card, CardContent, CardHeader } from '../../ui/card';
import { TypographyH4 } from '../../ui/typography';
import { CreateOrderActions } from '../order/create-order-actions';
import { StopMarketCloseOrderForm } from './close-order/stop-market-close-order-form';
import { StopMarketOrderDurationSelector } from './components/stop-market-order-duration-selector';
import { StopMarketOpenOrderForm } from './open-order/stop-market-open-order-form';
import { StopMarketOrderPreviewModal } from './preview-modal/stop-market-order-preview-modal';

type StopMarketOrderFormProps = {
  className?: string;
};

export const StopMarketOrderForm: FC<StopMarketOrderFormProps> = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((s) => s.auth);
  const [showOrderPreviewModal, setShowOrderPreviewModal] = useState<boolean>(false);
  const [orderDuration, setOrderDuration] = useState<OrderDurationEnum>(OrderDurationEnum.WEEK);

  const { address, isConnected, isConnecting, isReconnecting } = useAccount();

  const [network, setNetwork] = useState(getNetworks()[0]);
  const [enableCLoseOrderForm, setEnableCLoseOrderForm] = useState<boolean>(false);

  const networkTokens = useMemo(() => getAvailableTokens({ chainId: network.chainId }), [network.chainId]);
  const initialTokenIn = networkTokens.filter((t) => t.isStablecoin)[0];
  const initialTokenOut = networkTokens.filter((t) => !t.isStablecoin)[0];

  const {
    priceDifferenceWarning: openOrderPriceDifferenceWarning,
    setPriceDifferenceWarning: setOpenOrderPriceDifferenceWarning,
    validatePrice: validateOpenOrderPrice,
  } = useValidatePriceCondition();

  const formOpenOrder = useForm<z.infer<typeof FormLimitOpenOrderSchema>>({
    resolver: zodResolver(FormLimitOpenOrderSchema),
    defaultValues: {
      order: {
        tokenIn: initialTokenIn,
        tokenOut: initialTokenOut,
        amountIn: '',
        priceCondition: {
          value: '',
          conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
        },
      },
    },
    mode: 'onChange',
  });

  const openOrder = formOpenOrder.watch('order');

  const orderTokenOut = openOrder.tokenOut;
  const orderTokenIn = openOrder.tokenIn;

  const { isPairLatestPriceFetching, pairLatestPrice } = useGetLatestPairPrice({
    chainId: network.chainId,
    tokenInAddress: orderTokenIn.address,
    tokenOutAddress: orderTokenOut.address,
  });

  const selectedTokenBalance = useGetERC20Balance({
    isConnected,
    address,
    tokenAddress: openOrder.tokenIn.address,
    chainId: network.chainId,
  });

  const insufficientBalanceError = useMemo(
    () =>
      Boolean(
        selectedTokenBalance &&
          openOrder.amountIn &&
          parseFloat(selectedTokenBalance.formattedTokenBalance) < parseFloat(openOrder.amountIn),
      ),
    [selectedTokenBalance, openOrder.amountIn],
  );

  const networkTokensForCloseOrder = useMemo(
    () =>
      networkTokens.filter((t) => {
        const isTokenInStableCoin = orderTokenOut.isStablecoin;
        const isTheSameAddress = orderTokenOut.address === t.address;

        if (isTokenInStableCoin) {
          return !t.isStablecoin && !isTheSameAddress;
        }

        return !isTheSameAddress;
      }),
    [networkTokens, orderTokenOut.address],
  );

  const initialCLoseOrderTokenOut = networkTokensForCloseOrder[0];

  const formCloseOrder = useForm<z.infer<typeof FormLimitCloseOrderSchema>>({
    resolver: zodResolver(FormLimitCloseOrderSchema),
    defaultValues: {
      order: {
        tokenOut: initialCLoseOrderTokenOut!,
        priceCondition: {
          value: '',
          conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
        },
      },
    },
    mode: 'onChange',
  });

  const closeOrder = formCloseOrder.watch('order');

  const [openOrderTokenAmountInput, setOpenOrderTokenAmountInput] = useState<string>('');
  const [wasOpenOrderPriceConditionPriceInChanged, setWasOpenOrderPriceConditionPriceInChanged] =
    useState<boolean>(false);
  const [openOrderPriceConditionPriceInput, setOpenOrderPriceConditionPriceInput] = useState<string>(
    openOrder.priceCondition.value,
  );

  const handleOrderConditionPriceChange = (price: string, wasPriceConditionPriceInChangedManually?: boolean) => {
    formOpenOrder.setValue('order.priceCondition.value', price, {
      shouldValidate: true,
    });

    if (wasPriceConditionPriceInChangedManually)
      setWasOpenOrderPriceConditionPriceInChanged((prev) => (prev ? prev : true));
  };

  const handleSetMarketplacePrice = useCallback(() => {
    const price = parseFloat(pairLatestPrice).toFixed(6);

    setOpenOrderPriceConditionPriceInput(price);
    handleOrderConditionPriceChange(price, false);
  }, [pairLatestPrice]);

  const handleNetworkChange = useCallback(
    (n: Network) => {
      setNetwork(n);
      setOpenOrderTokenAmountInput('');
      setWasOpenOrderPriceConditionPriceInChanged(false);
      setOpenOrderPriceConditionPriceInput('');
      setOpenOrderPriceDifferenceWarning(null);

      const tokenIn = n.tokens.filter((t) => t.isStablecoin)[0];
      const tokenOut = n.tokens.filter((t) => !t.isStablecoin)[0];

      formOpenOrder.reset();

      formOpenOrder.setValue('order', {
        tokenIn,
        tokenOut,
        amountIn: '',
        priceCondition: {
          value: '',
          conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
        },
      });

      formCloseOrder.reset();

      setEnableCLoseOrderForm(false);

      dispatch(setStopMarketOrderPair({ tokenIn, tokenOut, chainId: n.chainId }));
    },
    [dispatch, setStopMarketOrderPair],
  );

  const handleSubmit = () => {
    setShowOrderPreviewModal(true);
  };

  const onOrderCreated = useCallback(() => {
    formCloseOrder.reset();
    formOpenOrder.reset();
    setShowOrderPreviewModal(false);
    setOpenOrderTokenAmountInput('');
    setWasOpenOrderPriceConditionPriceInChanged(false);
    setOpenOrderPriceConditionPriceInput('');
    setOpenOrderPriceDifferenceWarning(null);
    setEnableCLoseOrderForm(false);
    handleSetMarketplacePrice();
  }, [formOpenOrder, handleSetMarketplacePrice, formCloseOrder]);

  const handleOpenOrderSelectedTokensChange = useCallback(
    ({ tokenIn, tokenOut }: { tokenIn: Token; tokenOut: Token }) => {
      dispatch(setStopMarketOrderPair({ tokenIn, tokenOut, chainId: network.chainId }));

      setWasOpenOrderPriceConditionPriceInChanged(false);
      setOpenOrderTokenAmountInput('');
      setOpenOrderPriceConditionPriceInput('');
      setOpenOrderPriceDifferenceWarning(null);

      formOpenOrder.reset({
        order: {
          amountIn: '',
          tokenIn,
          tokenOut,
          priceCondition: {
            value: '',
            conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
          },
        },
      });

      const cLoseOrderTokenOut = networkTokens.find((t) => {
        const isTokenInStableCoin = tokenOut.isStablecoin;
        const isTheSameAddress = tokenOut.address === t.address;

        if (isTokenInStableCoin) {
          return !t.isStablecoin && !isTheSameAddress;
        }

        return !isTheSameAddress;
      });

      formCloseOrder.reset({
        order: {
          tokenOut: cLoseOrderTokenOut,
          priceCondition: {
            value: '',
            conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
          },
        },
      });
    },
    [network.chainId],
  );

  const handleAddCloseOrder = useCallback(() => {
    setEnableCLoseOrderForm(true);

    const cLoseOrderTokenOut = networkTokens.find((t) => {
      const isTokenInStableCoin = orderTokenOut.isStablecoin;
      const isTheSameAddress = orderTokenOut.address === t.address;

      if (isTokenInStableCoin) {
        return !t.isStablecoin && !isTheSameAddress;
      }

      return !isTheSameAddress;
    });

    formCloseOrder.setValue('order', {
      tokenOut: cLoseOrderTokenOut!,
      priceCondition: {
        value: '',
        conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
      },
    });
  }, [orderTokenOut, networkTokens]);

  const handleRemoveCloseOrder = useCallback(() => {
    setEnableCLoseOrderForm(false);

    formCloseOrder.reset();
  }, [formCloseOrder]);

  const isCreateButtonDisabled =
    !isConnected ||
    insufficientBalanceError ||
    !formOpenOrder.formState.isValid ||
    (enableCLoseOrderForm ? !formCloseOrder.formState.isValid : false);

  useEffect(() => {
    dispatch(setStopMarketOrderPair({ tokenIn: initialTokenIn, tokenOut: initialTokenOut, chainId: network.chainId }));
  }, []);

  return (
    <Card className="w-full ">
      <CardHeader>
        <TypographyH4>Stop Market Order</TypographyH4>
      </CardHeader>
      <CardContent className="flex flex-col px-3">
        <div className="mb-3">
          <NetworkSelector network={network} setNetwork={handleNetworkChange} />
        </div>

        <FormProvider {...formOpenOrder}>
          <StopMarketOpenOrderForm
            chainId={network.chainId}
            networkTokens={networkTokens}
            tokenAmountInput={openOrderTokenAmountInput}
            priceConditionPriceInput={openOrderPriceConditionPriceInput}
            priceDifferenceWarning={openOrderPriceDifferenceWarning}
            wasPriceConditionPriceInChanged={wasOpenOrderPriceConditionPriceInChanged}
            insufficientBalanceError={insufficientBalanceError}
            selectedTokenBalance={selectedTokenBalance?.formattedTokenBalance}
            isPairLatestPriceFetching={isPairLatestPriceFetching}
            setTokenAmountInput={setOpenOrderTokenAmountInput}
            setPriceConditionPriceInput={setOpenOrderPriceConditionPriceInput}
            handleSelectedTokensChange={handleOpenOrderSelectedTokensChange}
            validateOpenOrderPrice={validateOpenOrderPrice}
            handleOrderConditionPriceChange={handleOrderConditionPriceChange}
            handleSetMarketplacePrice={handleSetMarketplacePrice}
            pairLatestPrice={pairLatestPrice}
          />
        </FormProvider>

        {!enableCLoseOrderForm && (
          <div className="mt-2 self-end dark:w-full ">
            <CreateOrderActions addOrder={handleAddCloseOrder} />
          </div>
        )}

        {enableCLoseOrderForm ? (
          <div className="mt-4">
            <FormProvider {...formCloseOrder}>
              <StopMarketCloseOrderForm
                handleRemoveCloseOrder={handleRemoveCloseOrder}
                orderTokenIn={orderTokenOut}
                chainId={network.chainId}
                networkTokens={networkTokens}
              />
            </FormProvider>
          </div>
        ) : null}

        <div className="flex justify-between items-center mt-4 ">
          <TypographyH4 className="!text-sm  font-medium">Duration</TypographyH4>
          <StopMarketOrderDurationSelector orderDuration={orderDuration} setOrderDuration={setOrderDuration} />
        </div>

        <div className="flex text-sm font-medium mt-4 w-full">
          {isAuthenticated || isConnected ? (
            <Button
              className="bg-blue-500 text-white py-2 px-5 w-full rounded-lg shadow hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={handleSubmit}
              disabled={isCreateButtonDisabled}
              loading={isConnecting || isReconnecting}
            >
              Place {enableCLoseOrderForm ? 'Orders' : 'Order'}
            </Button>
          ) : (
            <CustomConnectButton buttonClassName="!text-sm w-full border-none !py-5 capitalize dark:hover:bg-primary-light-gray/20 dark:bg-primary-light-gray/20 text-primary-light-gray dark:text-white/80" />
          )}
        </div>
      </CardContent>

      {showOrderPreviewModal ? (
        <StopMarketOrderPreviewModal
          openOrder={openOrder}
          closeOrder={enableCLoseOrderForm ? closeOrder : null}
          network={network}
          onOrderCreated={onOrderCreated}
          onCloseModal={() => setShowOrderPreviewModal(false)}
          expirationTime={calculateExpirationTime(orderDuration)}
          tokenInUserBalance={selectedTokenBalance?.rawTokenBalance || '0'}
        />
      ) : null}
    </Card>
  );
};
