import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { APIConfig } from '../../configs';
import { StopMarketOrderQuoteEstimationType } from '../../interfaces';

export type GetStopMarketOrderQuoteEstimationParams = {
  chainId: number;
  tokenInId: string;
  tokenOutId: string;
  closeTokenOutId?: string;
  amountIn: string;
};

export const getStopMarketOrderQuoteEstimation = async (queryParams: string) => {
  return await axios.get<{
    openOrderQuoteEstimation: StopMarketOrderQuoteEstimationType;
    closeOrderQuoteEstimation: StopMarketOrderQuoteEstimationType | null;
  }>(`${APIConfig.baseURL}/ds/orders/estimate-quote?${queryParams}`, {
    withCredentials: true,
  });
};

export const useGetStopMarketOrderQuoteEstimation = (
  params: GetStopMarketOrderQuoteEstimationParams,
  enabled: boolean,
) => {
  const url = new URLSearchParams();

  url.set('tokenInId', `${params.tokenInId}`);
  url.set('tokenOutId', `${params.tokenOutId}`);
  url.set('amountIn', `${params.amountIn}`);
  url.set('chainId', `${params.chainId}`);

  if (params.closeTokenOutId) url.set('closeTokenOutId', `${params.closeTokenOutId}`);

  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryFn: () => getStopMarketOrderQuoteEstimation(url.toString()),
    queryKey: [
      'quoteEstimation',
      params.tokenInId,
      params.tokenOutId,
      params.amountIn,
      params.chainId,
      params.closeTokenOutId,
    ],
    enabled,
    staleTime: 1000 * 60 * 1,
  });

  return {
    isStopMarketOrderQuoteEstimationLoading: isFetching || isLoading,
    stopMarketOrderQuoteEstimation: data?.data,
    stopMarketOrderQuoteEstimationError: error,
    refetchStopMarketOrderQuoteEstimation: refetch,
  };
};
