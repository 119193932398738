import { FC } from 'react';
import { cn } from '../../../../utils';
import { TypographyP, TypographyS } from '../../../ui/typography';

type TooltipLabelProps = {
  title: string;
  value?: string | number;
} & React.HTMLAttributes<HTMLDivElement>;

export const TooltipLabel: FC<TooltipLabelProps> = ({ title, className, value, ...props }) => {
  return (
    <div className={cn('text-black/50 dark:text-white/80', className)} {...props}>
      <TypographyP className="text-xs ">
        {title} {typeof value !== 'undefined' ? <TypographyS>{value}</TypographyS> : null}
      </TypographyP>
    </div>
  );
};
