import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConditionalModalInfoType, ModalName } from '../../interfaces/store/modal';

export interface ModalState {
  activeModal: ModalName | null;
  modalInfo: ConditionalModalInfoType<ModalName> | null;
}

const initialState: ModalState = {
  activeModal: null,
  modalInfo: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setActiveModalInfo: (state, action: PayloadAction<ModalState>) => {
      state.activeModal = action.payload.activeModal;
      state.modalInfo = action.payload.modalInfo;
    },
    closeModal: (state) => {
      state.activeModal = null;
      state.modalInfo = null;
    },
  },
});

export const { setActiveModalInfo, closeModal } = modalSlice.actions;

export const modalSliceReducer = modalSlice.reducer;
