import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';
import { AlertOctagonIcon } from 'lucide-react';
import { FC } from 'react';
import { Token } from '../../../interfaces';
import { cn } from '../../../utils';
import { TokenLogo } from '../../custom/token-logo';
import { SpinnerIcon } from '../../icons/spinner-icon';
import { Input } from '../../ui/input';
import { TypographyS } from '../../ui/typography';
import { SetMarketPriceButton } from './set-market-price-button';

type PriceConditionInputProps = {
  open: boolean;
  inputValue: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  priceConditionValidationError: string;
  priceDifferenceWarning: {
    type: 'high' | 'low';
    delta: number;
  } | null;
  disabled: boolean;
  token: Token;
  handleSetMarketplacePrice: () => void;
};

export const PriceConditionInput: FC<PriceConditionInputProps> = ({
  priceDifferenceWarning,
  priceConditionValidationError,
  open,
  inputValue,
  onChange,
  disabled,
  token,
  handleSetMarketplacePrice,
}) => {
  return (
    <TooltipProvider>
      <Tooltip open={open}>
        <TooltipTrigger className="flex flex-col relative">
          <TokenLogo token={token} className="absolute top-1/2 left-1.5 -translate-y-1/2 " />
          <Input
            value={inputValue}
            disabled={disabled}
            onChange={onChange}
            placeholder="0.0"
            className={cn('p-1 pl-6 pr-6 border rounded ', {
              'text-red-500 border-red-400 focus-visible:ring-red-300': priceConditionValidationError,
            })}
          />
          <div className="absolute top-1/2 right-1.5 -translate-y-1/2 flex items-center justify-center">
            {disabled ? (
              <SpinnerIcon className="w-3 h-3" />
            ) : (
              <SetMarketPriceButton disabled={disabled} onClick={handleSetMarketplacePrice} />
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent
          align="center"
          side="bottom"
          className="bg-white/30 dark:bg-primary-black/30 max-w-[220px] backdrop-blur-lg p-2"
        >
          {priceConditionValidationError ? (
            <TypographyS className="text-red-500">{priceConditionValidationError}</TypographyS>
          ) : priceDifferenceWarning ? (
            <div className="flex items-center justify-center">
              <AlertOctagonIcon className="text-blue-500 mr-2 shrink-0 h-5 w-5" />
              <TypographyS className="text-blue-500 font-medium text-xs">
                Your price is <TypographyS className="font-semibold">{priceDifferenceWarning.delta}%</TypographyS> of
                the market price, are you sure?
              </TypographyS>
            </div>
          ) : null}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
