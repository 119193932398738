import { IndicatorFigure, IndicatorSeries, IndicatorTemplate, KLineData, utils } from 'klinecharts';

interface Vol {
  open: number;
  close: number;
  volume?: number;
  ma1?: number;
  ma2?: number;
  ma3?: number;
}

function getVolumeFigure(): IndicatorFigure<Vol> {
  return {
    key: 'volume',
    title: 'VOLUME: ',
    type: 'bar',
    baseValue: 0,
    styles: ({ data, indicator, defaultStyles }) => {
      const current = data.current;
      let color = utils.formatValue(indicator.styles, 'bars[0].noChangeColor', defaultStyles!.bars[0].noChangeColor);
      if (utils.isValid(current)) {
        if (current.close > current.open) {
          color = utils.formatValue(indicator.styles, 'bars[0].upColor', defaultStyles!.bars[0].upColor);
        } else if (current.close < current.open) {
          color = utils.formatValue(indicator.styles, 'bars[0].downColor', defaultStyles!.bars[0].downColor);
        }
      }
      return { color: color as string };
    },
  };
}

export const volumeIndicator = (data: KLineData[]): IndicatorTemplate<Vol> => ({
  name: 'custom-volume',
  shortName: 'custom-volume',
  series: IndicatorSeries.Volume,
  calcParams: [5, 10, 20],
  shouldFormatBigNumber: true,
  precision: 0,
  minValue: 0,
  maxValue: data.reduce((acc, val) => Math.max(acc, val.volume || 0), data[0]?.volume || 0),
  figures: [
    { key: 'ma1', title: 'MA5: ', type: '' },
    { key: 'ma2', title: 'MA10: ', type: '' },
    { key: 'ma3', title: 'MA20: ', type: '' },
    getVolumeFigure(),
  ],
  regenerateFigures: (params) => {
    const figures: Array<IndicatorFigure<Vol>> = params.map((p, i) => ({
      key: `ma${i + 1}`,
      title: `MA${p}: `,
      type: 'line',
    }));
    figures.push(getVolumeFigure());
    return [getVolumeFigure()];
  },
  calc: (dataList, indicator) => {
    const { figures } = indicator;
    const params = indicator.calcParams as number[];

    const volSums: number[] = [];

    return dataList.map((kLineData, i) => {
      const volume = kLineData.volume ?? 0;
      const vol: Vol = { volume, open: kLineData.open, close: kLineData.close };
      params.forEach((p, index) => {
        volSums[index] = (volSums[index] ?? 0) + volume;
        if (i >= p - 1) {
          vol[figures[index].key as keyof Vol] = volSums[index] / p;
          volSums[index] -= dataList[i - (p - 1)].volume ?? 0;
        }
      });
      return vol;
    });
  },
});
