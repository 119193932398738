import { useMutation } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import { APIConfig } from '../../configs';
import { setAuthenticated } from '../../store';
import { localStorageManager } from '../../utils';
import { useAppDispatch } from '../store';
import { toast } from '../ui/use-toast';

const signOutUser = async () => {
  return await axios.post(`${APIConfig.baseURL}/u/sign-out`, undefined, {
    withCredentials: true,
  });
};

export const useSignOut = () => {
  const dispatch = useAppDispatch();

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: signOutUser,
    onSuccess: async () => {
      localStorageManager.removeByPrefix(`auth-`);
      dispatch(setAuthenticated({ isAuthenticated: false }));

      toast({
        title: 'Sign out',
        description: 'Signed out from the DEX Standard platform',
      });
    },
    onError(err) {
      console.error(err);
      let message = `Unexpected error occurred: ${err.message}`;

      if (isAxiosError(err)) {
        message = err.response?.data?.error || message;
      }

      toast({
        title: 'Sign out error',
        description: message,
      });
    },
  });

  return {
    signOutError: error,
    isSignOutPending: isPending,
    signOut: mutateAsync,
  };
};
