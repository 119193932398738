import { utils } from 'klinecharts';
import { FC } from 'react';
import { TooltipLabel } from './tooltip-label';

type SMATooltipProps = {
  sma: number;
  precision: number;
};

export const SMATooltip: FC<SMATooltipProps> = ({ sma, precision }) => {
  return (
    <div className="flex gap-2 backdrop-blur-lg z-10 p-1 rounded-r-md w-fit bg-white/60 dark:bg-primary-black/20">
      <TooltipLabel title="SMA(12,2)" />
      <TooltipLabel
        className="text-orange-400 dark:text-orange-400"
        value={sma ? utils.formatFoldDecimal(sma.toFixed(precision), precision) : 0}
        title="SMA:"
      />
    </div>
  );
};
