import { ethers } from 'ethers';
import { TriangleAlert, XIcon } from 'lucide-react';
import { FC, useCallback, useMemo } from 'react';
import { useAccount } from 'wagmi';
import { ORDER_EXECUTOR_PROXY_ADDRESS } from '../../../../constants';
import {
  useAppDispatch,
  useCreateStopMarketOrder,
  useGetAllowance,
  useGetStopMarketOrderQuoteEstimation,
  useGetUserStopMarketOrdersTokensAllowance,
  useTokenApproval,
} from '../../../../hooks';
import { LimitCloseOrderType, LimitOpenOrderType, Network } from '../../../../interfaces';
import { closeModal } from '../../../../store';
import { formatOrderPriceConditionValueToWei } from '../../../../utils';
import { getTokenId } from '../../../../utils/blockchain/token-list';
import { NetworkLabel } from '../../../custom/network-label';
import { Button } from '../../../ui/button';
import { Dialog, DialogContent, DialogFooter, DialogTitle } from '../../../ui/dialog';
import { TypographyH4, TypographyP } from '../../../ui/typography';
import { StopMarketOrderClosePositionPreview } from './stop-market-order-close-position-preview';
import { StopMarketOrderOpenPositionPreview } from './stop-market-order-open-position-preview';
import { StopMarketOrderPreviewModalActions } from './stop-market-order-preview-modal-actions';

type StopMarketOrderPreviewModalProps = {
  network: Network;
  expirationTime: Date;
  openOrder: LimitOpenOrderType['order'];
  closeOrder: LimitCloseOrderType['order'] | null;
  tokenInUserBalance: string;
  onOrderCreated: () => void;
  onCloseModal: () => void;
};

export const StopMarketOrderPreviewModal: FC<StopMarketOrderPreviewModalProps> = ({
  network,
  openOrder,
  closeOrder,
  expirationTime,
  tokenInUserBalance,
  onCloseModal,
  onOrderCreated,
}) => {
  const dispatch = useAppDispatch();

  const tokenInId = getTokenId(openOrder.tokenIn.chainId, openOrder.tokenIn.address);
  const tokenOutId = getTokenId(openOrder.tokenOut.chainId, openOrder.tokenOut.address);

  const { address } = useAccount();
  const chainId = network.chainId;

  const openOrderTokenInAmountInWei = useMemo(
    () => ethers.parseUnits(openOrder.amountIn, openOrder.tokenIn.decimals).toString(),
    [openOrder.amountIn, openOrder.tokenIn.decimals],
  );

  const {
    stopMarketOrdersTokensAllowance,
    areStopMarketOrdersTokensAllowanceLoading,
    refetchStopMarketOrdersTokensAllowance,
    stopMarketOrdersTokensAllowanceError,
  } = useGetUserStopMarketOrdersTokensAllowance({
    tokenInId,
    tokenOutId,
  });

  const {
    approveToken: approveOpenOrderToken,
    error: approveOpenOrderTokenError,
    isApproving: isOpenOrderTokenApproving,
    isApproved: isOpenOrderTokenApproved,
  } = useTokenApproval();
  const {
    approveToken: approveCloseOrderToken,
    error: approveCloseOrderTokenError,
    isApproving: isCloseOrderTokenApproving,
    isApproved: isCloseOrderTokenApproved,
  } = useTokenApproval();

  const {
    allowance: openOrderTokenInAllowance,
    allowanceError: openOrderTokenInAllowanceError,
    isAllowanceLoading: isOpenOrderTokenInAllowanceLoading,
    refetchTokenAllowance: refetchOpenOrderTokenInAllowance,
  } = useGetAllowance({
    tokenAddress: openOrder.tokenIn.address,
    ownerAddress: address!,
    spenderAddress: ORDER_EXECUTOR_PROXY_ADDRESS,
  });

  const amountOfOpenOrderTokenInToBeApprovedForOrders = useMemo(
    () =>
      stopMarketOrdersTokensAllowance
        ? (
            ethers.toBigInt(openOrderTokenInAmountInWei) +
            ethers.toBigInt(stopMarketOrdersTokensAllowance.totalAmountTokenIn)
          ).toString()
        : undefined,
    [stopMarketOrdersTokensAllowance, openOrderTokenInAmountInWei],
  );

  const showInsufficientTokenInBalanceError = useMemo(
    () =>
      !stopMarketOrdersTokensAllowance || !amountOfOpenOrderTokenInToBeApprovedForOrders
        ? false
        : ethers.toBigInt(tokenInUserBalance) < ethers.toBigInt(amountOfOpenOrderTokenInToBeApprovedForOrders),
    [tokenInUserBalance, amountOfOpenOrderTokenInToBeApprovedForOrders],
  );

  const isOpenOrderTokenTotalAmountApproved = useMemo(() => {
    if (isOpenOrderTokenApproved) return true;

    if (amountOfOpenOrderTokenInToBeApprovedForOrders && openOrderTokenInAllowance) {
      return (
        ethers.toBigInt(amountOfOpenOrderTokenInToBeApprovedForOrders) <= ethers.toBigInt(openOrderTokenInAllowance)
      );
    }

    return false;
  }, [amountOfOpenOrderTokenInToBeApprovedForOrders, isOpenOrderTokenApproved, openOrderTokenInAllowance]);

  const {
    stopMarketOrderQuoteEstimation,
    isStopMarketOrderQuoteEstimationLoading,
    refetchStopMarketOrderQuoteEstimation,
    stopMarketOrderQuoteEstimationError,
  } = useGetStopMarketOrderQuoteEstimation(
    {
      amountIn: openOrderTokenInAmountInWei,
      chainId,
      tokenInId,
      tokenOutId,
      closeTokenOutId: closeOrder ? getTokenId(closeOrder.tokenOut.chainId, closeOrder.tokenOut.address) : undefined,
    },
    isOpenOrderTokenTotalAmountApproved,
  );

  const {
    allowance: closeOrderTokenInAllowance,
    allowanceError: closeOrderTokenInAllowanceError,
    isAllowanceLoading: isCloseOrderTokenInAllowanceLoading,
    refetchTokenAllowance: refetchCloseOrderTokenInAllowance,
  } = useGetAllowance({
    tokenAddress: openOrder.tokenOut.address,
    ownerAddress: address!,
    spenderAddress: ORDER_EXECUTOR_PROXY_ADDRESS,
    enabled: !!closeOrder,
  });

  const closeOrderTokenInAmountInWei = stopMarketOrderQuoteEstimation?.closeOrderQuoteEstimation?.amountIn;

  const amountOfCloseOrderTokenInToBeApprovedForOrders = useMemo(
    () =>
      stopMarketOrdersTokensAllowance && closeOrderTokenInAmountInWei
        ? (
            ethers.toBigInt(closeOrderTokenInAmountInWei) +
            ethers.toBigInt(stopMarketOrdersTokensAllowance.totalCloseAmountTokenIn)
          ).toString()
        : undefined,
    [stopMarketOrdersTokensAllowance, closeOrderTokenInAmountInWei],
  );

  const isCloseOrderTokenTotalAmountApproved = useMemo(() => {
    if (isCloseOrderTokenApproved) return true;

    if (amountOfCloseOrderTokenInToBeApprovedForOrders && closeOrderTokenInAllowance) {
      return (
        ethers.toBigInt(amountOfCloseOrderTokenInToBeApprovedForOrders) <= ethers.toBigInt(closeOrderTokenInAllowance)
      );
    }

    return false;
  }, [amountOfCloseOrderTokenInToBeApprovedForOrders, isCloseOrderTokenApproved, closeOrderTokenInAllowance]);

  const { createStopMarketOrder, isCreateStopMarketOrderPending } = useCreateStopMarketOrder();

  const approveOpenOrderTokenHandler = useCallback(async () => {
    if (!amountOfOpenOrderTokenInToBeApprovedForOrders) return;

    await approveOpenOrderToken({
      amountWei: amountOfOpenOrderTokenInToBeApprovedForOrders,
      spenderAddress: ORDER_EXECUTOR_PROXY_ADDRESS,
      token: openOrder.tokenIn,
    });
  }, [openOrder.tokenIn, amountOfOpenOrderTokenInToBeApprovedForOrders]);

  const approveCloseOrderTokenHandler = useCallback(async () => {
    if (!amountOfCloseOrderTokenInToBeApprovedForOrders) return;

    await approveCloseOrderToken({
      amountWei: amountOfCloseOrderTokenInToBeApprovedForOrders,
      spenderAddress: ORDER_EXECUTOR_PROXY_ADDRESS,
      token: openOrder.tokenOut,
    });
  }, [amountOfCloseOrderTokenInToBeApprovedForOrders, openOrder.tokenOut.address]);

  const createStopMarketOrderHandler = async () => {
    const openOrderPriceConditionValueWei = formatOrderPriceConditionValueToWei(
      openOrder.priceCondition.value,
      openOrder.priceCondition.conditionType,
      openOrder.tokenIn,
      openOrder.tokenOut,
    );

    const closeOrderPriceConditionValueWei = closeOrder
      ? formatOrderPriceConditionValueToWei(
          closeOrder.priceCondition.value,
          closeOrder.priceCondition.conditionType,
          openOrder.tokenOut,
          closeOrder.tokenOut,
        )
      : null;

    if (!openOrderPriceConditionValueWei || (closeOrder && !closeOrderPriceConditionValueWei)) {
      throw new Error('Cannot parse order price condition value');
    }

    const res = await createStopMarketOrder({
      chainId,
      closeOrder:
        closeOrder && closeOrderPriceConditionValueWei && stopMarketOrderQuoteEstimation?.openOrderQuoteEstimation
          ? {
              priceCondition: {
                ...closeOrder.priceCondition,
                value: closeOrderPriceConditionValueWei,
              },
              tokenIn: openOrder.tokenOut.address,
              tokenOut: closeOrder.tokenOut.address,
              amountIn: stopMarketOrderQuoteEstimation.openOrderQuoteEstimation.minAmountOut,
            }
          : null,
      openOrder: {
        amountIn: openOrderTokenInAmountInWei,
        priceCondition: {
          ...openOrder.priceCondition,
          value: openOrderPriceConditionValueWei,
        },
        tokenIn: openOrder.tokenIn.address,
        tokenOut: openOrder.tokenOut.address,
      },
      expirationTime,
    });

    if (res.data.orderId) {
      dispatch(closeModal());
      onOrderCreated();
    }
  };

  const onClose = () => {
    dispatch(closeModal());
    onCloseModal();
  };

  return (
    <Dialog open={true}>
      <DialogContent showClose={false}>
        <Button
          variant="link"
          className="dark:hover:text-white/80 hover:text-black/70 absolute top-2 right-2 dark:text-white "
          onClick={onClose}
        >
          <XIcon />
        </Button>

        <DialogTitle></DialogTitle>
        <TypographyH4 className="font-bold mb-3">Order Review</TypographyH4>

        <NetworkLabel network={network} className="mb-3" />

        <StopMarketOrderOpenPositionPreview
          amountOfOpenOrderTokenInToBeApprovedForOrders={amountOfOpenOrderTokenInToBeApprovedForOrders}
          approveOpenOrderTokenError={approveOpenOrderTokenError}
          areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
          isOpenOrderTokenApproving={isOpenOrderTokenApproving}
          isStopMarketOrderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
          isTokenInApproved={isOpenOrderTokenTotalAmountApproved}
          openOrder={openOrder}
          openOrderQuoteEstimation={stopMarketOrderQuoteEstimation?.openOrderQuoteEstimation || null}
          stopMarketOrdersTokensAllowanceError={stopMarketOrdersTokensAllowanceError?.message}
          stopMarketOrderQuoteEstimationError={stopMarketOrderQuoteEstimationError?.message}
          isOpenOrderTokenInAllowanceLoading={isOpenOrderTokenInAllowanceLoading}
          openOrderTokenInAllowanceError={openOrderTokenInAllowanceError?.message}
          approveOpenOrderTokenHandler={approveOpenOrderTokenHandler}
          refetchStopMarketOrdersTokensAllowance={refetchStopMarketOrdersTokensAllowance}
          refetchStopMarketOrderQuoteEstimation={refetchStopMarketOrderQuoteEstimation}
          refetchOpenOrderTokenInAllowance={refetchOpenOrderTokenInAllowance}
        />

        {closeOrder ? (
          <div className="mt-2">
            <StopMarketOrderClosePositionPreview
              openOrder={openOrder}
              closeOrder={closeOrder}
              approveCloseOrderTokenError={approveCloseOrderTokenError}
              isCloseOrderTokenTotalAmountApproved={isCloseOrderTokenTotalAmountApproved}
              isCloseOrderTokenApproving={isCloseOrderTokenApproving}
              areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
              amountOfCloseOrderTokenInToBeApprovedForOrders={amountOfCloseOrderTokenInToBeApprovedForOrders}
              stopMarketOrdersTokensAllowanceError={stopMarketOrdersTokensAllowanceError?.message}
              isOpenOrderTokenTotalAmountApproved={isOpenOrderTokenTotalAmountApproved}
              isStopMarketOrderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
              closeOrderQuoteEstimation={stopMarketOrderQuoteEstimation?.closeOrderQuoteEstimation || null}
              approveOpenOrderMessage={
                isOpenOrderTokenTotalAmountApproved ? '' : `approve ${openOrder.tokenIn.symbol} first to get estimation`
              }
              stopMarketOrderQuoteEstimationError={stopMarketOrderQuoteEstimationError?.message}
              closeOrderTokenInAllowanceError={closeOrderTokenInAllowanceError?.message}
              isCloseOrderTokenInAllowanceLoading={isCloseOrderTokenInAllowanceLoading}
              refetchCloseOrderTokenInAllowance={refetchCloseOrderTokenInAllowance}
              approveCloseOrderTokenHandler={approveCloseOrderTokenHandler}
            />
          </div>
        ) : null}

        <div className="flex mt-4 items-center">
          <TriangleAlert className="text-yellow-400 mr-2" />

          <TypographyP className="text-xs">
            Do not close this modal until you approve tokens usage and confirm the order placement!
          </TypographyP>
        </div>

        <DialogFooter className="flex !justify-between mt-4 !flex-col">
          <StopMarketOrderPreviewModalActions
            amountOfOpenOrderTokenInToBeApprovedForOrders={amountOfOpenOrderTokenInToBeApprovedForOrders}
            showInsufficientTokenInBalanceError={showInsufficientTokenInBalanceError}
            openOrder={openOrder}
            isOpenOrderTokenTotalAmountApproved={isOpenOrderTokenTotalAmountApproved}
            areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
            isOpenOrderTokenApproving={isOpenOrderTokenApproving}
            isStopMarketOrderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
            isCloseOrderTokenApproving={isCloseOrderTokenApproving}
            closeOrder={closeOrder}
            isCloseOrderTokenTotalAmountApproved={isCloseOrderTokenTotalAmountApproved}
            isCreateStopMarketOrderPending={isCreateStopMarketOrderPending}
            approveOpenOrderTokenHandler={approveOpenOrderTokenHandler}
            approveCloseOrderTokenHandler={approveCloseOrderTokenHandler}
            createStopMarketOrderHandler={createStopMarketOrderHandler}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
