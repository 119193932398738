import { PriceConditionTypeEnum } from './limit-order-interface';

export enum OrderStatusEnum {
  CREATED = 'created',
  CANCELED = 'canceled',
  EXECUTED = 'executed',
  EXPIRED = 'expired',
  FAILED = 'failed',
}

export enum SortOptionEnum {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum PriceConditionPositionTypeEnum {
  CLOSE = 'close',
  OPEN = 'open',
}

export type StopMarketOrderInfo = {
  id: number;
  userAddress: string;
  amountIn: string;
  tokenInId: string;
  tokenOutId: string;
  chainId: number;
  createdAt: string;
  updatedAt: string;
  openStatus: OrderStatusEnum;
  closeStatus: OrderStatusEnum | null;
  closeAmountIn: string | null;
  closeTokenOutId: string;
  priceConditions: {
    id: number;
    conditionType: PriceConditionTypeEnum;
    positionType: PriceConditionPositionTypeEnum;
    value: string;
    createdAt: string;
  }[];
};

export type UserStopMarketOrdersResponse = {
  results: StopMarketOrderInfo[];
  page: number;
  size: number;
};

export type UserStopMarketOrdersTokensAllowance = {
  totalAmountTokenIn: string;
  totalCloseAmountTokenIn: string;
};

export enum OrderDurationEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  THREE_MONTHS = 'threeMonths',
}

export enum StopMarketOrderType {
  CLOSE = 'close',
  OPEN = 'open',
}

export enum OrderGasUsageEnum {
  STANDARD = 'standard',
  LOW = 'low',
}

export type StopMarketOrderQuoteEstimationType = {
  amountIn: string;
  platformFee: string;
  floatingGasFee: string;
  estimatedTxFee: string;
  minAmountOut: string;
  maxAmountOut: string;
  route: {
    amountIn: string;
    path: string[];
    pools: string[];
  }[];
};
