import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import { useState } from 'react';
import { APIConfig } from '../../configs';
import { toast } from '../ui/use-toast';

const cancelStopMarketOrder = async (orderId: number) => {
  return await axios.patch<{ success: boolean }>(`${APIConfig.baseURL}/ds/orders/${orderId}/cancel`, undefined, {
    withCredentials: true,
  });
};

export const useCancelStopMarketOrder = () => {
  const client = useQueryClient();

  const [cancelingOrderId, setCancelingOrderId] = useState<number | null>(null);

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: cancelStopMarketOrder,
    onSuccess: () => {
      toast({
        title: 'Order canceled',
        description: 'Stop market order was canceled!',
      });

      client.invalidateQueries({
        queryKey: ['userOrders'],
      });
    },
    onMutate(variables) {
      setCancelingOrderId(variables);
    },
    onSettled() {
      setCancelingOrderId(null);
    },
    onError(err) {
      console.error(err);
      let message = `Unexpected error occurred: ${err.message}`;

      if (isAxiosError(err)) {
        message = err.response?.data?.error || message;
      }

      toast({
        title: 'Order canceling error',
        description: message,
      });
    },
  });

  return {
    cancelStopMarketOrderError: error,
    isCancelStopMarketOrderPending: isPending,
    cancelStopMarketOrder: mutateAsync,
    cancelingOrderId,
  };
};
