import { FC } from 'react';
import { cn, formatAmount } from '../../../../utils';
import { getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { TypographyP } from '../../../ui/typography';

type AmountColumnProps = {
  tokenInId: string;
  amountIn: string;
};

export const AmountColumn: FC<AmountColumnProps> = ({ tokenInId, amountIn }) => {
  const tokenIn = getTokenByTokenId(tokenInId);

  return (
    <div className="flex items-center w-full justify-end">
      <TypographyP className=" text-[11px]">
        {formatAmount({
          value: parseFloat(amountIn),
        })}
      </TypographyP>
      {tokenIn && <img src={tokenIn.logoUri} alt={tokenIn.symbol} className={cn('w-3.5 h-3.5 ml-1')} />}
    </div>
  );
};
