import { useMutation } from '@tanstack/react-query';
import { ethers } from 'ethers';
import { UserRejectedRequestError } from 'viem';
import { SpinnerIcon, TokenLabel, TypographyP } from '../../components';
import { Token } from '../../interfaces';
import { toast } from '../ui/use-toast';
import { useEthersSigner } from './use-ethers-signer';

const ERC20_ABI = ['function approve(address spender, uint256 amount) public returns (bool)'];

/**
 * Custom hook for approving ERC20 tokens.
 */
export const useTokenApproval = () => {
  const signer = useEthersSigner();

  const approveToken = async ({
    amountWei,
    token,
    spenderAddress,
  }: {
    amountWei: string;
    token: Token;
    spenderAddress: string;
  }) => {
    if (!signer) throw new Error('Wallet not connected');

    const tokenContract = new ethers.Contract(token.address, ERC20_ABI, signer);

    const approvingToast = toast({
      title: (
        <div className="flex gap-1 items-center">
          <TypographyP>Approving</TypographyP>
          <TokenLabel token={token} />
        </div>
      ) as unknown as string,
      description: (
        <div className=" w-full">
          Waiting till {token.symbol} will be approved
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            <SpinnerIcon className="w-6 h-6" />
          </div>
        </div>
      ),
      open: true,
      duration: 1000 * 60,
    });

    try {
      const tx = await tokenContract.approve(spenderAddress, amountWei);

      await tx.wait();

      approvingToast.update({
        id: approvingToast.id,
        title: `Approve successful`,
        description: `Token ${token.symbol} was approved`,
        duration: 3000,
      });

      return tx.hash;
    } catch (error) {
      let message = `Failed to approve token ${token.symbol}`;

      if (error instanceof UserRejectedRequestError) {
        message = error.shortMessage;
      }

      throw new Error(message);
    }
  };

  const mutation = useMutation({
    mutationFn: approveToken,
    mutationKey: ['approveToken'],
    onError(error) {
      toast({
        title: `Approve failed`,
        description: error.message,
      });
    },
  });

  return {
    approveToken: mutation.mutateAsync,
    isApproving: mutation.isPending,
    isApproved: mutation.isSuccess,
    error: mutation.error?.message || '',
  };
};
