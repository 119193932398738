import { TooltipShowRule } from 'klinecharts';

export const applyChartStyles = () => ({
  candle: {
    tooltip: {
      showRule: TooltipShowRule.None,
    },
  },
  indicator: {
    tooltip: {
      showRule: TooltipShowRule.None,
    },
  },

  grid: {
    vertical: {
      color: '#89868675',
    },
    horizontal: {
      color: '#89868675',
    },
  },
  xAxis: {
    axisLine: {
      color: '#99969682',
    },
  },
  yAxis: {
    axisLine: {
      color: '#99969682',
    },
  },
  separator: {
    color: '#99969682',
  },
});
