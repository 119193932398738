import { utils } from 'klinecharts';
import { FC } from 'react';
import { TooltipLabel } from './tooltip-label';

type BollingerBandsTooltipProps = {
  mid: number;
  up: number;
  down: number;
  precision: number;
};

export const BollingerBandsTooltip: FC<BollingerBandsTooltipProps> = ({ mid, up, down, precision }) => {
  return (
    <div className="flex gap-2 backdrop-blur-lg z-10 p-1 rounded-r-md w-fit bg-white/60 dark:bg-primary-black/20">
      <TooltipLabel title="BOLL(12,20)" />
      <TooltipLabel
        className="text-orange-400 dark:text-orange-400"
        value={up ? utils.formatFoldDecimal(up.toFixed(precision), precision) : 0}
        title="UP:"
      />
      <TooltipLabel
        className="text-purple-400 dark:text-purple-400"
        value={mid ? utils.formatFoldDecimal(mid.toFixed(precision), precision) : mid}
        title="MID:"
      />
      <TooltipLabel
        className="text-blue-400 dark:text-blue-400"
        value={down ? utils.formatFoldDecimal(down.toFixed(precision), precision) : 0}
        title="DN:"
      />
    </div>
  );
};
