import { CornerDownRightIcon } from 'lucide-react';
import { FC } from 'react';
import { StopMarketOrderInfo } from '../../../../interfaces';
import { TypographyP } from '../../../ui/typography';

type OrderStatusColumnProps = {
  order: StopMarketOrderInfo;
};

export const OrderStatusColumn: FC<OrderStatusColumnProps> = ({ order }) => {
  return (
    <div className="flex space-y-1.5 flex-col">
      <div className="flex gap-1">
        <TypographyP className=" italic dark:text-amber-400 font-medium text-[11px]">Open</TypographyP>
        <div>
          <TypographyP className="text-center capitalize text-[11px]">{order.openStatus}</TypographyP>
        </div>
      </div>
      {order.closeStatus && (
        <div className="flex gap-1">
          <div className="flex items-center relative">
            <CornerDownRightIcon className="w-2 h-2 absolute top-1/2 left-[-10px] -translate-y-1/2 dark:text-purple-400 text-black/50 mr-1" />
            <TypographyP className="italic dark:text-purple-400 text-black/50 text-[11px]">Close</TypographyP>
          </div>

          <TypographyP className="text-center capitalize text-[11px]">{order.openStatus}</TypographyP>
        </div>
      )}
    </div>
  );
};
