import { ArrowRightIcon, TrashIcon } from 'lucide-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { useDebounce, useGetLatestPairPrice, useGetUSDTokenPrice, useValidatePriceCondition } from '../../../../hooks';
import { FormLimitCloseOrderSchema, PriceConditionTypeEnum, Token } from '../../../../interfaces';
import { cn, getCloseOrderHeaderLabel, getTokenFromPriceConditionType } from '../../../../utils';
import { Button } from '../../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { TypographyH4, TypographyP } from '../../../ui/typography';
import { PriceConditionInput } from '../../order/price-condition-input';
import { PriceConditionSelector } from '../../order/price-condition-selector';
import { TokenSelector } from '../../order/token-selector';

type StopMarketCloseOrderFormProps = {
  networkTokens: Token[];
  chainId: number;
  handleRemoveCloseOrder: () => void;
  orderTokenIn: Token;
};

export const StopMarketCloseOrderForm: FC<StopMarketCloseOrderFormProps> = ({
  networkTokens,
  orderTokenIn,
  chainId,
  handleRemoveCloseOrder,
}) => {
  const formCloseOrder = useFormContext<z.infer<typeof FormLimitCloseOrderSchema>>();

  const closeOrder = formCloseOrder.watch('order');

  const orderTokenOut = closeOrder.tokenOut;
  const orderPriceConditionType = closeOrder.priceCondition.conditionType;
  const orderPriceConditionPrice = closeOrder.priceCondition.value;

  const { isPairLatestPriceFetching, pairLatestPrice } = useGetLatestPairPrice({
    chainId,
    tokenInAddress: orderTokenIn.address,
    tokenOutAddress: orderTokenOut.address,
  });

  const { priceDifferenceWarning, setPriceDifferenceWarning, validatePrice } = useValidatePriceCondition();

  const [tokenInUsdPrice, tokenOutUsdPrice] = useGetUSDTokenPrice([
    {
      oracleAddress: orderTokenIn.usdPriceOracle,
      chainId,
    },
    {
      oracleAddress: orderTokenOut.usdPriceOracle,
      chainId,
    },
  ]);

  const [wasPriceConditionPriceInChanged, setWasPriceConditionPriceInChanged] = useState<boolean>(false);

  const orderPriceConditionValidationError =
    formCloseOrder.formState.errors.order?.priceCondition?.value?.message || '';

  const [priceConditionPriceInput, setPriceConditionPriceInput] = useState<string>(closeOrder.priceCondition.value);

  const availableOrderTokensOut = useMemo(
    () =>
      networkTokens.filter((t) => {
        const isTokenStable = t.isStablecoin;

        if ((isTokenStable && orderTokenIn.isStablecoin) || t.address === orderTokenIn.address) {
          return false;
        }

        return true;
      }),
    [networkTokens, orderTokenIn.address],
  );

  const handleTokenOutChange = useCallback(
    (tokenOut: Token) => {
      const isTokenOutStablecoin = tokenOut.isStablecoin;
      const isTokenInStablecoin = orderTokenIn.isStablecoin;

      let tokenIn = orderTokenIn;

      if (tokenOut.address === tokenIn.address || (isTokenInStablecoin && tokenOut.isStablecoin)) {
        const firstAvailableToken = networkTokens.find((t) => {
          const isNotTheSameToken = t.address !== tokenOut.address;
          const isNotStablecoin = !t.isStablecoin;

          if (isTokenOutStablecoin) {
            return isNotStablecoin && isNotTheSameToken;
          }

          return isNotTheSameToken;
        });

        if (firstAvailableToken) {
          tokenIn = firstAvailableToken;
        }
      }

      setWasPriceConditionPriceInChanged(false);
      setPriceConditionPriceInput('');
      setPriceDifferenceWarning(null);

      formCloseOrder.reset({
        order: {
          tokenOut,
          priceCondition: {
            value: '',
            conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
          },
        },
      });
    },
    [orderTokenIn, networkTokens],
  );

  const handlePriceConditionChange = useCallback(
    (condition: PriceConditionTypeEnum) => {
      formCloseOrder.setValue('order.priceCondition.conditionType', condition, {
        shouldValidate: false,
      });

      if (
        pairLatestPrice &&
        condition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN &&
        !wasPriceConditionPriceInChanged
      ) {
        const marketPrice = 1 / Number(pairLatestPrice);

        handleOrderConditionPriceChange(marketPrice.toFixed(6));
        setPriceConditionPriceInput(marketPrice.toFixed(6));
      }

      if (
        pairLatestPrice &&
        condition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT &&
        !wasPriceConditionPriceInChanged
      ) {
        const marketPrice = Number(pairLatestPrice);

        handleOrderConditionPriceChange(marketPrice.toFixed(6));
        setPriceConditionPriceInput(marketPrice.toFixed(6));
      }

      if (tokenInUsdPrice && condition === PriceConditionTypeEnum.TOKEN_IN_USD && !wasPriceConditionPriceInChanged) {
        const marketPrice = 1 / tokenInUsdPrice;

        handleOrderConditionPriceChange(marketPrice.toFixed(6));
        setPriceConditionPriceInput(marketPrice.toFixed(6));
      }

      if (tokenOutUsdPrice && condition === PriceConditionTypeEnum.TOKEN_OUT_USD && !wasPriceConditionPriceInChanged) {
        const marketPrice = 1 / Number(tokenOutUsdPrice);

        handleOrderConditionPriceChange(marketPrice.toFixed(6));
        setPriceConditionPriceInput(marketPrice.toFixed(6));
      }
    },
    [pairLatestPrice, tokenInUsdPrice, tokenOutUsdPrice],
  );

  const handleOrderConditionPriceChange = (price: string, wasPriceConditionPriceInChangedManually?: boolean) => {
    formCloseOrder.setValue('order.priceCondition.value', price, {
      shouldValidate: true,
    });

    if (wasPriceConditionPriceInChangedManually) setWasPriceConditionPriceInChanged((prev) => (prev ? prev : true));
  };

  const debouncedUpdatePriceInput = useDebounce((value: string) => {
    handleOrderConditionPriceChange(value, true);
  }, 500);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputVal = e.target.value;
    inputVal = inputVal.replace(/[^\d.]/g, '');

    const firstDotIndex = inputVal.indexOf('.');
    if (firstDotIndex !== -1) {
      inputVal = inputVal.slice(0, firstDotIndex + 1) + inputVal.slice(firstDotIndex + 1).replace(/\./g, '');
    }

    setPriceConditionPriceInput(inputVal);

    debouncedUpdatePriceInput(inputVal);
  };

  const handleSetMarketplacePrice = useCallback(() => {
    const price = parseFloat(pairLatestPrice).toFixed(6);

    setPriceConditionPriceInput(price);
    handleOrderConditionPriceChange(price, false);
  }, [pairLatestPrice]);

  useEffect(() => {
    if (pairLatestPrice && !isPairLatestPriceFetching && !wasPriceConditionPriceInChanged) {
      handleSetMarketplacePrice();
    }
  }, [pairLatestPrice, isPairLatestPriceFetching, wasPriceConditionPriceInChanged]);

  useEffect(() => {
    if (pairLatestPrice && !isPairLatestPriceFetching && orderPriceConditionPrice) {
      validatePrice({
        price: orderPriceConditionPrice,
        priceCondition: orderPriceConditionType,
        latestTokenPairPrice: pairLatestPrice,
        orderTokenInUsdPriceOracle: orderTokenIn.usdPriceOracle,
        orderTokenOutUsdPriceOracle: orderTokenOut.usdPriceOracle,
        tokenInUsdPrice,
        tokenOutUsdPrice,
      });
    }
  }, [
    pairLatestPrice,
    tokenInUsdPrice,
    tokenOutUsdPrice,
    isPairLatestPriceFetching,
    orderPriceConditionType,
    orderPriceConditionPrice,
  ]);

  const [showCancelPopover, setShowCancelPopover] = useState(false);

  return (
    <div className="dark:bg-primary-light-gray/20 shadow-md dark:shadow-none p-3 relative rounded-lg">
      <Popover open={showCancelPopover} onOpenChange={(open) => setShowCancelPopover(open)}>
        <PopoverTrigger asChild>
          <Button
            onClick={() => setShowCancelPopover(true)}
            variant="ghost"
            className="p-1 h-fit absolute right-2 top-2"
            aria-label="Remove close Order"
          >
            <TrashIcon />
          </Button>
        </PopoverTrigger>
        <PopoverContent side="left" className="flex items-center flex-col gap-2 p-2.5 max-w-[170px]">
          <TypographyP className="text-center text-sm">Remove close order?</TypographyP>
          <Button
            type="button"
            variant="ghost"
            className="relative bg-red-600 hover:bg-red-500 hover:text-white text-white"
            onClick={() => {
              handleRemoveCloseOrder();
            }}
            aria-label="Cancel Order"
          >
            Remove
          </Button>
        </PopoverContent>
      </Popover>

      <TypographyH4 className="!text-sm mb-2">Close position</TypographyH4>

      <div className="flex items-center">
        <div className="flex flex-col">
          <div className="flex justify-between items-center mb-1">
            <label className="text-left text-xs font-medium">{'You pay'}</label>
          </div>
          <div className={cn('flex items-center border rounded', {})}>
            <TokenSelector
              tokenList={networkTokens}
              selectedToken={orderTokenIn}
              disabled
              className="justify-between px-2 border-none"
            />
          </div>
        </div>

        <ArrowRightIcon className="text-center mx-4 mt-5 h-4 w-5 text-black/60 dark:text-white/80" />

        <div className="flex flex-col w-[110px]">
          <label className="text-left text-xs font-medium mb-1">You get</label>
          <div className="flex items-center justify-center border rounded">
            <TokenSelector
              key={`${orderTokenIn.address}-${orderTokenOut.address}`}
              tokenList={availableOrderTokensOut}
              handleTokenChange={handleTokenOutChange}
              selectedToken={orderTokenOut}
              className="justify-between px-2 border-none"
              contentClassName="justify-between w-full"
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <label className="font-medium text-sm">Condition</label>
      </div>

      <div className="flex flex-col w-full mt-2">
        <div className="text-start text-xs font-medium mb-1 flex items-center">
          {getCloseOrderHeaderLabel(orderTokenIn.symbol, orderTokenOut.symbol, orderPriceConditionType)}
        </div>

        <div className="flex items-center  gap-2">
          <PriceConditionInput
            open={!!orderPriceConditionValidationError || !!priceDifferenceWarning}
            inputValue={priceConditionPriceInput}
            onChange={handlePriceChange}
            disabled={isPairLatestPriceFetching}
            priceConditionValidationError={orderPriceConditionValidationError}
            priceDifferenceWarning={priceDifferenceWarning}
            token={getTokenFromPriceConditionType(orderTokenIn, orderTokenOut, orderPriceConditionType)}
            handleSetMarketplacePrice={handleSetMarketplacePrice}
          />

          <PriceConditionSelector
            handlePriceConditionChange={handlePriceConditionChange}
            tokenIn={orderTokenIn}
            tokenOut={orderTokenOut}
            selectedCondition={orderPriceConditionType}
          />
        </div>
      </div>
    </div>
  );
};
