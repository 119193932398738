type StorageKey = string;

export const localStorageManager = {
  get<T>(key: StorageKey): T | null {
    try {
      const item = localStorage.getItem(key);

      if (!item) return null;

      const parsed = JSON.parse(item);
      const now = Date.now();

      if (parsed.expiration && parsed.expiration < now) {
        this.remove(key);
        return null;
      }

      return parsed.value as T;
    } catch (error) {
      console.error(`Error getting localStorage key "${key}":`, error);
      return null;
    }
  },

  set<T>(key: StorageKey, value: T, expirationMs?: number): void {
    try {
      const item = {
        value,
        expiration: expirationMs ? Date.now() + expirationMs : null,
      };
      localStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
      console.error(`Error setting localStorage key "${key}":`, error);
    }
  },

  remove(key: StorageKey): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing localStorage key "${key}":`, error);
    }
  },

  removeByPrefix(prefix: string): void {
    try {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(prefix)) {
          localStorage.removeItem(key);
        }
      });
    } catch (error) {
      console.error(`Error removing keys with prefix "${prefix}":`, error);
    }
  },

  clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing localStorage:', error);
    }
  },
};
