import { formatUnits } from 'ethers';
import { OhlcPeriod } from '../../interfaces';
import { getTokenByAddress } from './token-list';

export const formatOHLCToUnits = (chainId: number, tokenOutAddress: string, ohlc: OhlcPeriod): OhlcPeriod => {
  if (!ohlc) return ohlc;

  const tokenOutDecimals = getTokenByAddress(chainId, tokenOutAddress)?.decimals || 0;

  return {
    ...ohlc,
    close: formatUnits(ohlc?.close || '0', tokenOutDecimals),
    open: formatUnits(ohlc?.open || '0', tokenOutDecimals),
    low: formatUnits(ohlc?.low || '0', tokenOutDecimals),
    high: formatUnits(ohlc?.high || '0', tokenOutDecimals),
  };
};
