import { CornerDownRightIcon } from 'lucide-react';
import { FC } from 'react';
import { StopMarketOrderInfo } from '../../../../interfaces';
import { getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { TokenLabel } from '../../../custom/token-label';
import { TypographyP, TypographyS } from '../../../ui/typography';

type TokenPairColumnProps = {
  order: StopMarketOrderInfo;
};

export const TokenPairColumn: FC<TokenPairColumnProps> = ({ order }) => {
  const tokenIn = getTokenByTokenId(order.tokenInId);
  const tokenOut = getTokenByTokenId(order.tokenOutId);
  const closeTokenIn = getTokenByTokenId(order.tokenOutId);
  const closeTokenOut = order.closeTokenOutId && getTokenByTokenId(order.closeTokenOutId);

  return (
    <div className="flex space-y-1.5 flex-col">
      <div className="flex gap-1">
        <TypographyP className="dark:text-amber-400 font-medium  italic text-[11px]">Open</TypographyP>
        {tokenIn && tokenOut && (
          <div>
            <div className="flex gap-1 items-center justify-start">
              <TokenLabel token={tokenIn} textClassName="text-[10px]" imgClassName="w-3 h-3" />

              <TypographyS className="text-xs">/</TypographyS>

              <TokenLabel token={tokenOut} textClassName="text-[10px]" imgClassName="w-3 h-3" />
            </div>
          </div>
        )}
      </div>
      {closeTokenIn && closeTokenOut && (
        <div className="flex gap-1">
          <div className="flex items-center relative">
            <CornerDownRightIcon className="w-2 h-2 absolute top-1/2 left-[-10px] -translate-y-1/2 dark:text-purple-400 text-black/50 mr-1" />
            <TypographyP className="italic dark:text-purple-400 text-black/50 text-[11px]">Close</TypographyP>
          </div>

          <div>
            <div className="flex gap-1 items-center justify-start dark:text-white/60 text-black/60">
              <TokenLabel token={closeTokenIn} textClassName="text-[10px]" imgClassName="w-3 h-3" />

              <TypographyS className="text-xs">/</TypographyS>

              <TokenLabel token={closeTokenOut} textClassName="text-[10px]" imgClassName="w-3 h-3" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
