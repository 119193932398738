import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { APIConfig } from '../../configs';
import { UserStopMarketOrdersTokensAllowance } from '../../interfaces';

export type GetUserOrdersTokensAllowanceParams = {
  tokenInId: string;
  tokenOutId: string;
};

export const getUserOrdersTokensAllowance = async (queryParams: string) => {
  return await axios.get<UserStopMarketOrdersTokensAllowance>(
    `${APIConfig.baseURL}/ds/orders/tokens-allowance?${queryParams}`,
    {
      withCredentials: true,
    },
  );
};

export const useGetUserStopMarketOrdersTokensAllowance = (
  params: GetUserOrdersTokensAllowanceParams,
  enabled: boolean = true,
) => {
  const url = new URLSearchParams();

  url.set('tokenInId', `${params.tokenInId}`);
  url.set('tokenOutId', `${params.tokenOutId}`);

  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryFn: () => getUserOrdersTokensAllowance(url.toString()),
    queryKey: ['tokensAllowance', params.tokenInId, params.tokenOutId],
    enabled,
    staleTime: 1000 * 60 * 1,
  });

  return {
    areStopMarketOrdersTokensAllowanceLoading: isFetching || isLoading,
    stopMarketOrdersTokensAllowance: data?.data,
    stopMarketOrdersTokensAllowanceError: error,
    refetchStopMarketOrdersTokensAllowance: refetch,
  };
};
