import { Route, Routes } from 'react-router';
import { StopMarketOrderPage } from '../pages/stop-market-order/stop-market-order-page';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<StopMarketOrderPage />} />
    </Routes>
  );
};
