import { MoonIcon, SunIcon } from 'lucide-react';
import { ThemeEnum, useTheme } from '../../hooks';
import { Toggle } from '../ui/toggle';

export const ThemeToggleWidget = () => {
  const { toggleTheme, isLightTheme } = useTheme();

  return (
    <Toggle
      pressed={isLightTheme}
      size="lg"
      onPressedChange={() => toggleTheme(isLightTheme ? ThemeEnum.DARK : ThemeEnum.LIGHT)}
      aria-label="Toggle theme"
      className="data-[state=on]:bg-transparent  hover:bg-transparent bg-transparent data-[state=on]:text-accent-foreground"
    >
      {isLightTheme ? <MoonIcon className="w-6 h-6" /> : <SunIcon className="w-6 h-6" />}
    </Toggle>
  );
};
