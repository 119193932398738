import { ethers } from 'ethers';
import { getRpcProvider } from '../providers/rpc-providers';

const aggregatorV3InterfaceABI = [
  'function latestRoundData() view returns (uint80 roundId, int256 answer, uint256 startedAt, uint256 updatedAt, uint80 answeredInRound)',
];

export const getUsdPrice = async (chainId: number, usdPriceOracleAddress: string) => {
  try {
    if (!usdPriceOracleAddress || !chainId) return null;

    const priceFeed = new ethers.Contract(usdPriceOracleAddress, aggregatorV3InterfaceABI, getRpcProvider(chainId));
    const roundData = await priceFeed.latestRoundData();

    return Number(roundData.answer) / 1e8;
  } catch (error) {
    console.error(error);

    return null;
  }
};
