import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';
import { FC, memo } from 'react';
import { getNetworks } from '../../../../utils/blockchain/token-list';
import { TypographyP } from '../../../ui/typography';

type ChainColumnProps = {
  chainId: number;
};

const ChainColumnComp: FC<ChainColumnProps> = ({ chainId }) => {
  const networks = getNetworks();
  const network = networks.find((n) => n.chainId === chainId);

  return (
    <div className="flex items-center justify-center">
      {network && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="flex flex-col relative">
              <img src={network.logoUri} alt={`${network.name} logo`} className="w-5 h-5" />
            </TooltipTrigger>
            <TooltipContent
              align="center"
              side="right"
              className="bg-white/30 shadow dark:bg-primary-black/30 max-w-[220px] backdrop-blur-lg p-2"
            >
              <TypographyP className="dark:text-white text-black">{network.name}</TypographyP>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
};

export const ChainColumn = memo(ChainColumnComp);
