import { useQuery } from '@tanstack/react-query';
import { ethers } from 'ethers';
import { useChainId } from 'wagmi';
import { useEthersProvider } from './use-ethers-provider';

const ERC20_ABI = ['function allowance(address owner, address spender) view returns (uint256)'];

interface AllowanceParams {
  tokenAddress: string;
  ownerAddress: string;
  spenderAddress: string;
  enabled?: boolean;
}

export function useGetAllowance({ tokenAddress, ownerAddress, spenderAddress, enabled = true }: AllowanceParams) {
  const chainId = useChainId();
  const provider = useEthersProvider({ chainId });

  const fetchAllowance = async (): Promise<string> => {
    if (!provider) {
      throw new Error('Provider not available');
    }
    const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
    const allowanceBN = await tokenContract.allowance(ownerAddress, spenderAddress);
    return allowanceBN.toString();
  };

  const {
    data: allowance,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['allowance', chainId, tokenAddress, ownerAddress, spenderAddress],
    queryFn: fetchAllowance,
    enabled: enabled && !!provider && !!chainId && !!tokenAddress && !!ownerAddress && !!spenderAddress,
    refetchOnMount: true,
  });

  return {
    allowance,
    isAllowanceLoading: isLoading,
    allowanceError: error,
    refetchTokenAllowance: refetch,
  };
}
