import { JsonRpcSigner } from 'ethers';
import { useMemo } from 'react';
import type { Account, Chain, Client, Transport } from 'viem';
import { type Config, useConnectorClient } from 'wagmi';
import { clientToProvider } from './use-ethers-provider';

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account } = client;
  const provider = clientToProvider(client);

  const signer = new JsonRpcSigner(provider, account.address);

  return signer;
}

export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId });

  return useMemo(() => (client ? clientToSigner(client) : undefined), [client]);
}
