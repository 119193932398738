import { Indicator, IndicatorSeries, IndicatorTemplate, KLineData } from 'klinecharts';
import { IndicatorEnum } from '../../../../interfaces';

export interface SMA {
  sma?: number;
}

export const simpleMovingAverageIndicator: IndicatorTemplate<SMA> = {
  name: IndicatorEnum.SMA,
  shortName: IndicatorEnum.SMA,
  series: IndicatorSeries.Price,
  calcParams: [12, 2],
  precision: 2,
  figures: [{ key: 'sma', title: 'SMA: ', type: 'line' }],
  shouldOhlc: true,
  calc: (dataList: KLineData[], indicator: Indicator<SMA>) => {
    const params = indicator.calcParams as number[];
    let closeSum = 0;
    let smaValue = 0;

    return dataList.map((kLineData: KLineData, i: number) => {
      const sma: SMA = {};
      const close = kLineData.close;
      closeSum += close;

      if (i >= params[0] - 1) {
        if (i > params[0] - 1) {
          smaValue = (close * params[1] + smaValue * (params[0] - params[1] + 1)) / (params[0] + 1);
        } else {
          smaValue = closeSum / params[0];
        }
        sma.sma = smaValue;
      }

      return sma;
    });
  },
};
