import { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector, useGetPairOHLCInfo } from '../../../hooks';
import { ChartDateIntervalRangeEnum, IndicatorEnum } from '../../../interfaces';
import { getCurrentInfoFromInterval, getCurrentInfoInterval, getPrependInfoFromInterval } from '../../../utils';
import { Card, CardContent } from '../../ui/card';
import { Tabs, TabsList, TabsTrigger } from '../../ui/tabs';
import { TypographyH4 } from '../../ui/typography';
import { ChartComponent } from './chart-component';

type ChartLayoutProps = {
  className?: string;
};

export const ChartLayout: FC<ChartLayoutProps> = () => {
  const limitOrderPair = useAppSelector((state) => state.stopMarketOrder.stopMarketOrderPair);
  const [selectedRange, setSelectedRange] = useState<ChartDateIntervalRangeEnum>(ChartDateIntervalRangeEnum.ONE_MONTH);
  const maxTicks = 24; // can be adjusted later in chart configs
  const interval = getCurrentInfoInterval(selectedRange);

  const currentInfoFromInterval = useMemo(() => getCurrentInfoFromInterval(selectedRange), [selectedRange]);
  const prependInfoFromInterval = useMemo(
    () => getPrependInfoFromInterval(currentInfoFromInterval, interval, maxTicks),
    [currentInfoFromInterval, interval, maxTicks],
  );

  const { pairOHLCInfo, isPairOHLCInfoFetching, refetchPairOHLCInfo } = useGetPairOHLCInfo();
  const [indicators] = useState({
    [IndicatorEnum.SMA]: false,
    [IndicatorEnum.EMA]: false,
    [IndicatorEnum.RSI]: false,
    [IndicatorEnum.BOLLINGER_BANDS]: false,
  });
  const [chartWidth, setChartWidth] = useState<number>(0);

  const updateChartDimensions = () => {
    const containerWidth = document.querySelector<HTMLElement>('.chart-layout')?.['offsetWidth'] ?? 0;
    setChartWidth(containerWidth - 40);
  };

  useEffect(() => {
    updateChartDimensions();
    window.addEventListener('resize', updateChartDimensions);

    return () => window.removeEventListener('resize', updateChartDimensions);
  }, []);

  const handleRangeChange = (range: ChartDateIntervalRangeEnum) => {
    setSelectedRange(range);
  };

  const currentOHLCData = useMemo(() => {
    const initialData =
      pairOHLCInfo?.ohlcPeriod
        .filter((d) => Date.parse(d.date) >= currentInfoFromInterval)
        .map((d) => ({
          close: parseFloat(d.close),
          open: parseFloat(d.open),
          low: parseFloat(d.low),
          high: parseFloat(d.high),
          timestamp: Date.parse(d.date),
          volume: parseFloat(d.volume),
        })) ?? [];

    return initialData;
  }, [selectedRange, pairOHLCInfo]);

  const prependOHLCData = useMemo(() => {
    const initialData =
      pairOHLCInfo?.ohlcPeriod
        .filter((d) => Date.parse(d.date) >= prependInfoFromInterval && Date.parse(d.date) < currentInfoFromInterval)
        .map((d) => ({
          close: parseFloat(d.close),
          open: parseFloat(d.open),
          low: parseFloat(d.low),
          high: parseFloat(d.high),
          timestamp: Date.parse(d.date),
        })) ?? [];

    return initialData;
  }, [selectedRange, pairOHLCInfo]);

  const rangeOptions = [
    ChartDateIntervalRangeEnum.ONE_DAY,
    ChartDateIntervalRangeEnum.FIVE_DAYS,
    ChartDateIntervalRangeEnum.ONE_MONTH,
  ];
  const pairLabel = limitOrderPair ? `${limitOrderPair?.tokenIn?.symbol} / ${limitOrderPair?.tokenOut?.symbol}` : '';

  useEffect(() => {
    if (limitOrderPair && limitOrderPair.tokenIn && limitOrderPair.tokenOut) {
      refetchPairOHLCInfo({
        chainId: limitOrderPair.chainId,
        tokenInAddress: limitOrderPair.tokenIn.address,
        tokenOutAddress: limitOrderPair.tokenOut.address,
        from: prependInfoFromInterval,
        interval,
      });
    }
  }, [limitOrderPair?.tokenOut, interval, prependInfoFromInterval, limitOrderPair?.tokenIn]);

  return (
    <Card className="w-full min-w-full chart-layout">
      <CardContent className="flex flex-col ">
        <div className="flex items-center justify-between my-4 mb-6">
          <TypographyH4>{pairLabel}</TypographyH4>

          <div className="flex justify-between items-center gap-4">
            <div className="flex space-x-2 text-xs font-medium">
              <Tabs
                onValueChange={(option) => handleRangeChange(option as ChartDateIntervalRangeEnum)}
                defaultValue={selectedRange}
                className="w-fit"
              >
                <TabsList className="w-full flex items-center">
                  {rangeOptions.map((r) => (
                    <TabsTrigger className="uppercase" key={r} value={r}>
                      {r}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            </div>
          </div>
        </div>

        <ChartComponent
          indicators={indicators}
          chartWidth={chartWidth}
          currentOHLCData={currentOHLCData}
          prependOHLCData={prependOHLCData}
          isDataLoading={isPairOHLCInfoFetching}
          selectedRange={selectedRange}
        />
      </CardContent>
    </Card>
  );
};
