import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import { APIConfig } from '../../configs';
import { PriceConditionTypeEnum } from '../../interfaces';
import { toast } from '../ui/use-toast';

export type CreateStopMarketOrderParams = {
  chainId: number;
  openOrder: {
    amountIn: string;
    tokenIn: string;
    tokenOut: string;
    priceCondition: {
      value: string;
      conditionType: PriceConditionTypeEnum;
    };
  };
  closeOrder: {
    amountIn: string;
    tokenIn: string;
    tokenOut: string;
    priceCondition: {
      value: string;
      conditionType: PriceConditionTypeEnum;
    };
  } | null;
  expirationTime: Date;
};

const createStopMarketOrder = async (params: CreateStopMarketOrderParams) => {
  return await axios.post<{ orderId: number }>(`${APIConfig.baseURL}/ds/orders`, params, {
    withCredentials: true,
  });
};

export const useCreateStopMarketOrder = () => {
  const client = useQueryClient();

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: createStopMarketOrder,
    onSuccess: () => {
      toast({
        title: 'Order created',
        description: 'Stop market order was created!',
      });

      client.invalidateQueries({
        queryKey: ['userOrders'],
      });
    },
    onError(err) {
      console.error(err);
      let message = `Unexpected error occurred: ${err.message}`;

      if (isAxiosError(err)) {
        message = err.response?.data?.error || message;
      }

      toast({
        title: 'Order creation error',
        description: message,
      });
    },
  });

  return {
    createStopMarketOrderError: error,
    isCreateStopMarketOrderPending: isPending,
    createStopMarketOrder: mutateAsync,
  };
};
