const networkList = [
  {
    chainId: 42161,
    name: 'Arbitrum',
    env: 'prod',
    logoUri: 'https://cryptologos.cc/logos/arbitrum-arb-logo.png',
    tokens: [
      {
        name: 'Bridged USDC',
        address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
        symbol: 'USDC.e',
        decimals: 6,
        chainId: 42161,
        isStablecoin: true,
        logoUri: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0x50834f3163758fcc1df9973b6e91f0f0f0434ad3',
      },
      {
        name: 'USDCoin',
        address: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
        symbol: 'USDC',
        decimals: 6,
        chainId: 42161,
        isStablecoin: true,
        logoUri: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0x50834f3163758fcc1df9973b6e91f0f0f0434ad3',
      },
      {
        name: 'Tether USD',
        address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
        symbol: 'USDT',
        chainId: 42161,
        decimals: 6,
        isStablecoin: true,
        logoUri: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0x3f3f5df88dc9f13eac63df89ec16ef6e7e25dde7',
      },
      {
        name: 'Dai Stablecoin',
        address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        symbol: 'DAI',
        chainId: 42161,
        decimals: 18,
        isStablecoin: true,
        logoUri: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0xc5c8e77b397e531b8ec06bfb0048328b30e9ecfb',
      },
      {
        name: 'Wrapped Ether',
        address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        symbol: 'WETH',
        chainId: 42161,
        decimals: 18,
        isStablecoin: false,
        logoUri: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0x639fe6ab55c921f74e7fac1ee960c0b6293ba612',
      },
      {
        name: 'Wrapped BTC',
        address: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
        symbol: 'WBTC',
        chainId: 42161,
        isStablecoin: false,
        decimals: 8,
        logoUri:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
        env: 'prod',
        usdPriceOracle: '0xd0c7101eacbb49f3decccc166d238410d6d46d57',
      },
      {
        name: 'Arbitrum',
        address: '0x912ce59144191c1204e64559fe8253a0e49e6548',
        chainId: 42161,
        isStablecoin: false,
        symbol: 'ARB',
        decimals: 18,
        logoUri: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0xb2A824043730FE05F3DA2efaFa1CBbe83fa548D6',
      },
      {
        name: 'LayerZero',
        address: '0x6985884C4392D348587B19cb9eAAf157F13271cd',
        symbol: 'ZRO',
        isStablecoin: false,
        chainId: 42161,
        decimals: 18,
        logoUri: 'https://assets.coingecko.com/coins/images/28206/standard/ftxG9_TJ_400x400.jpeg?1696527208',
        env: 'prod',
        usdPriceOracle: '0x1940fEd49cDBC397941f2D336eb4994D599e568B',
      },
      {
        name: 'Uniswap',
        address: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
        symbol: 'UNI',
        isStablecoin: false,
        chainId: 42161,
        decimals: 18,
        logoUri: 'https://cryptologos.cc/logos/uniswap-uni-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0x9C917083fDb403ab5ADbEC26Ee294f6EcAda2720',
      },

      {
        address: '0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40',
        name: 'tBTC V2',
        chainId: 42161,
        isStablecoin: false,
        symbol: 'tBTC V2',
        decimals: 18,
        logoUri:
          'https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x18084fbA666a33d37592fA2633fD49a74DD93a88/logo.png',
        env: 'prod',
        usdPriceOracle: '0xE808488e8627F6531bA79a13A9E0271B39abEb1C',
      },
      {
        address: '0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
        name: 'Sushi',
        symbol: 'SUSHI',
        isStablecoin: false,
        chainId: 42161,
        decimals: 18,
        logoUri: 'https://cryptologos.cc/logos/sushiswap-sushi-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0xb2A8BA74cbca38508BA1632761b56C897060147C',
      },
      {
        address: '0x6314C31A7a1652cE482cffe247E9CB7c3f4BB9aF',
        name: '1inch',
        chainId: 42161,
        isStablecoin: false,
        symbol: '1INCH',
        decimals: 18,
        logoUri: 'https://cryptologos.cc/logos/1inch-1inch-logo.svg?v=040',
        env: 'prod',
        usdPriceOracle: '0x4bC735Ef24bf286983024CAd5D03f0738865Aaef',
      },
      {
        address: '0xba5DdD1f9d7F570dc94a51479a000E3BCE967196',
        name: 'Aave',
        chainId: 42161,
        isStablecoin: false,
        symbol: 'AAVE',
        decimals: 18,
        logoUri: 'https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110',
        env: 'prod',
        usdPriceOracle: '0xaD1d5344AaDE45F43E596773Bcc4c423EAbdD034',
      },
      {
        address: '0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60',
        name: 'Lido DAO',
        chainId: 42161,
        isStablecoin: false,
        symbol: 'LDO',
        decimals: 18,
        logoUri: 'https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644',
        env: 'prod',
        usdPriceOracle: '0xA43A34030088E6510FecCFb77E88ee5e7ed0fE64',
      },
      {
        name: 'ChainLink Token',
        address: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
        symbol: 'LINK',
        decimals: 18,
        isStablecoin: false,
        chainId: 42161,
        logoUri:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png',
        env: 'prod',
        usdPriceOracle: '0x86E53CF1B870786351Da77A57575e79CB55812CB',
      },
      {
        name: 'Pendle',
        address: '0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8',
        chainId: 42161,
        isStablecoin: false,
        symbol: 'PENDLE',
        decimals: 18,
        logoUri: 'https://cryptologos.cc/logos/pendle-pendle-logo.png',
        env: 'prod',
        usdPriceOracle: '0x66853E19d73c0F9301fe099c324A1E9726953433',
      },
      {
        name: 'Wrapped liquid staked Ether 2.0',
        symbol: 'WSTETH',
        address: '0x5979D7b546E38E414F7E9822514be443A4800529',
        isStablecoin: false,
        chainId: 42161,
        decimals: 18,
        logoUri: 'https://assets.coingecko.com/coins/images/18834/large/wstETH.png?1696518295',
        env: 'prod',
        usdPriceOracle: '0x07C5b924399cc23c24a95c8743DE4006a32b7f2a', // stETH/USD
      },
      {
        name: 'Curve DAO Token',
        address: '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
        symbol: 'CRV',
        chainId: 42161,
        isStablecoin: false,
        decimals: 18,
        logoUri:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png',
        env: 'prod',
        usdPriceOracle: '0xaebDA2c976cfd1eE1977Eac079B4382acb849325',
      },
      {
        name: 'GMX',
        address: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
        symbol: 'GMX',
        isStablecoin: false,
        chainId: 42161,
        decimals: 18,
        logoUri: 'https://assets.coingecko.com/coins/images/18323/large/arbit.png?1631532468',
        env: 'prod',
        usdPriceOracle: '0xDB98056FecFff59D032aB628337A4887110df3dB',
      },
      {
        address: '0x9623063377AD1B27544C965cCd7342f7EA7e88C7',
        name: 'The Graph',
        chainId: 42161,
        isStablecoin: false,
        symbol: 'GRT',
        decimals: 18,
        logoUri: 'https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566',
        env: 'prod',
        usdPriceOracle: '0x0F38D86FceF4955B705F35c9e41d1A16e0637c73',
      },
      {
        name: 'MAGIC',
        address: '0x539bdE0d7Dbd336b79148AA742883198BBF60342',
        chainId: 42161,
        isStablecoin: false,
        symbol: 'MAGIC',
        decimals: 18,
        logoUri:
          'https://dynamic-assets.coinbase.com/30320a63f6038b944c9c0202fcb2392e6a1bd333814f74b4674774dd87f2d06d64fdd74c2f1ab4639917c75b749c323450408bec7a2737af8ae0c17871aa90de/asset_icons/98d278cda11639ed7449a0a3086cd2c83937ce71baf4ee43bb5b777423c00a75.png',
        env: 'prod',
        usdPriceOracle: '0x47E55cCec6582838E173f252D08Afd8116c2202d',
      },
    ],
  },
  {
    name: 'BNB',
    chainId: 56,
    env: 'prod',
    logoUri: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=040',
    tokens: [],
  },
  {
    name: 'Base',
    chainId: 8453,
    env: 'prod',
    logoUri:
      'https://raw.githubusercontent.com/base-org/brand-kit/refs/heads/main/logo/in-product/Base_Network_Logo.png',
    tokens: [
      {
        name: 'USDC',
        address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        symbol: 'USDC',
        decimals: 6,
        isStablecoin: true,
        chainId: 8453,
        logoUri: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
        env: 'prod',
        usdPriceOracle: '',
      },
      {
        name: 'USDT',
        address: '0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2',
        symbol: 'USDT',
        decimals: 6,
        isStablecoin: true,
        chainId: 8453,
        logoUri: 'https://ethereum-optimism.github.io/data/USDT/logo.png',
        env: 'prod',
        usdPriceOracle: '',
      },
      {
        name: 'Dai Stablecoin',
        address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
        symbol: 'DAI',
        decimals: 18,
        isStablecoin: true,
        chainId: 8453,
        logoUri: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.svg?v=040',

        env: 'prod',
        usdPriceOracle: '',
      },
      {
        name: 'Wrapped eETH',
        address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
        symbol: 'weETH.base',
        decimals: 18,
        chainId: 8453,
        isStablecoin: false,
        env: 'prod',
        usdPriceOracle: '',
        logoUri: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=040',
      },
      {
        name: 'LayerZero',
        address: '0x6985884C4392D348587B19cb9eAAf157F13271cd',
        symbol: 'ZRO',
        decimals: 18,
        isStablecoin: false,
        chainId: 8453,
        env: 'prod',
        usdPriceOracle: '',
        logoUri: 'https://assets.coingecko.com/coins/images/28206/standard/ftxG9_TJ_400x400.jpeg',
      },
      {
        name: 'Virtual Protocol',
        address: '0x0b3e328455c4059EEb9e3f84b5543F74E24e7E1b',
        symbol: 'VIRTUAL',
        decimals: 18,
        isStablecoin: false,
        chainId: 8453,
        env: 'prod',
        usdPriceOracle: '',
        logoUri: 'https://s2.coinmarketcap.com/static/img/coins/64x64/29420.png',
      },
    ],
  },
].map((n) => ({
  ...n,
  tokens: n.tokens.map((t) => ({ ...t, address: t.address.toLowerCase() })),
}));

export const getTokenByTokenId = (tokenId: string) =>
  networkList.flatMap((n) => n.tokens).find((t) => t.address === tokenId.split('_')[1].toLowerCase());

export const getTokenId = (chainId: number, tokenAddress: string) => `${chainId}_${tokenAddress}`;

export const getTokenByAddress = (chainId: number, tokenAddress: string) =>
  networkList.find((n) => n.chainId === chainId)?.tokens.find((t) => t.address === tokenAddress.toLowerCase());

export const getNetworks = () => networkList;
export const getNetworkByChainId = (chainId: number) => networkList.find((n) => n.chainId === chainId);
export const getAvailableTokens = ({
  chainId,
  excludeTokenSymbol = '',
}: {
  chainId: number;
  excludeTokenSymbol?: string;
}) => {
  const networkTokens = (networkList.find((n) => n.chainId === chainId)?.tokens ?? []).sort((a, b) =>
    a.isStablecoin ? -1 : 1 || a.name.localeCompare(b.name),
  );

  if (excludeTokenSymbol) {
    return networkTokens.filter((t) => t.symbol !== excludeTokenSymbol);
  }

  return networkTokens;
};
