import { InfiniteData, keepPreviousData, QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import { APIConfig } from '../../configs';
import {
  OrderStatusEnum,
  PriceConditionPositionTypeEnum,
  SortOptionEnum,
  UserStopMarketOrdersResponse,
} from '../../interfaces';
import { formatWeiToReadablePriceConditionValue } from '../../utils';
import { getTokenByTokenId } from '../../utils/blockchain/token-list';

export type UserOrdersFilter = {
  status?: OrderStatusEnum[];
  chainId?: number;
  tokenIn?: string;
  tokenOut?: string;
  closeTokenIn?: string;
  closeTokenOut?: string;
};

export type GetUserOrdersParams = {
  size: number;
  page: number;
  filter: UserOrdersFilter;
  sort: {
    createdAt?: SortOptionEnum;
  };
};

export const getUserOrders = async (queryParams: string) => {
  return await axios.get<UserStopMarketOrdersResponse>(`${APIConfig.baseURL}/ds/orders?${queryParams}`, {
    withCredentials: true,
  });
};

export const useGetUserStopMarketOrders = (params: GetUserOrdersParams, enabled: boolean) => {
  const url = new URLSearchParams();

  url.set('size', `${params.size}`);
  url.set('page', `${params.page}`);

  if (Object.values(params.filter).length > 0) {
    url.set('filter', JSON.stringify(params.filter));
  }

  if (Object.values(params.sort).length > 0) {
    url.set('sort', JSON.stringify(params.sort));
  }

  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery<
    UserStopMarketOrdersResponse,
    Error,
    InfiniteData<UserStopMarketOrdersResponse, unknown>,
    QueryKey,
    number
  >({
    queryKey: ['userOrders', url.toString()],
    queryFn: async ({ pageParam = 1 }) => {
      url.set('size', `${params.size}`);
      url.set('page', `${pageParam}`);

      const fetchedData = await getUserOrders(url.toString());
      return fetchedData.data;
    },
    staleTime: 1000 * 10, // 10 sec
    initialPageParam: 1,
    getNextPageParam: (_lastGroup) => _lastGroup.page + 1,
    refetchOnWindowFocus: false,
    enabled,
    placeholderData: keepPreviousData,
  });

  const flatData = useMemo(
    () =>
      (data?.pages?.flatMap((page) => page.results) ?? []).map((o) => {
        const tokenIn = getTokenByTokenId(o.tokenInId)!;
        const tokenOut = getTokenByTokenId(o.tokenOutId)!;
        const closeTokenOut = o.closeTokenOutId ? getTokenByTokenId(o.closeTokenOutId) : null;
        const formattedAmountIn = ethers.formatUnits(o.amountIn, tokenIn?.decimals);
        const formattedCloseAmountIn =
          o.closeAmountIn && closeTokenOut ? ethers.formatUnits(o.closeAmountIn, closeTokenOut?.decimals) : null;

        const formattedPriceConditions = o.priceConditions.map((c) => {
          if (c.positionType === PriceConditionPositionTypeEnum.OPEN) {
            return {
              ...c,
              value: formatWeiToReadablePriceConditionValue(c.value, c.conditionType, tokenIn, tokenOut) || '',
            };
          }

          if (c.positionType === PriceConditionPositionTypeEnum.CLOSE && closeTokenOut) {
            return {
              ...c,
              value: formatWeiToReadablePriceConditionValue(c.value, c.conditionType, tokenOut, closeTokenOut) || '',
            };
          }

          return c;
        });

        return {
          ...o,
          amountIn: formattedAmountIn,
          closeAmountIn: formattedCloseAmountIn,
          priceConditions: formattedPriceConditions,
        };
      }),
    [data, url.toString()],
  );

  return {
    areUserStopMarketOrdersLoading: isFetching || isLoading,
    fetchNextPage: fetchNextPage,
    orders: flatData,
  };
};
