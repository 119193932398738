import { useState } from 'react';
import { Card, CardContent } from '../../ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { OrderTable } from './order-table';

export enum OrderListTabEnum {
  ACTIVE = 'active',
  HISTORY = 'history',
}

export type OrderTableFilterType = {
  chainId: number | undefined;
  tokenInId: string | undefined;
  tokenOutId: string | undefined;
  closeTokenInId: string | undefined;
  closeTokenOutId: string | undefined;
};

const initialFilter = {
  chainId: undefined,
  tokenInId: undefined,
  tokenOutId: undefined,
  closeTokenInId: undefined,
  closeTokenOutId: undefined,
};

const OrderList = () => {
  const [activeTab, setActiveTab] = useState<OrderListTabEnum>(OrderListTabEnum.ACTIVE);

  const [activeFilters, setActiveFilters] = useState<{
    active: OrderTableFilterType;
    history: OrderTableFilterType;
  }>({
    active: initialFilter,
    history: initialFilter,
  });

  return (
    <Card className="w-full">
      <CardContent className="py-5">
        <Tabs
          value={activeTab}
          onValueChange={(option) => setActiveTab(option as OrderListTabEnum)}
          defaultValue={OrderListTabEnum.ACTIVE}
          className="w-full mb-2"
        >
          <div className="flex items-center justify-between space-x-2">
            <TabsList className="w-fit flex items-center">
              <TabsTrigger className="" value={OrderListTabEnum.ACTIVE}>
                Active
              </TabsTrigger>
              <TabsTrigger className="" value={OrderListTabEnum.HISTORY}>
                History
              </TabsTrigger>
            </TabsList>
          </div>

          <TabsContent value={OrderListTabEnum.ACTIVE}>
            <OrderTable
              type={OrderListTabEnum.ACTIVE}
              activeFilters={activeFilters.active}
              setActiveFilters={setActiveFilters}
            />
          </TabsContent>
          <TabsContent value={OrderListTabEnum.HISTORY}>
            <OrderTable
              type={OrderListTabEnum.HISTORY}
              activeFilters={activeFilters.history}
              setActiveFilters={setActiveFilters}
            />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default OrderList;
