import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { FC } from 'react';
import {
  LimitOpenOrderType,
  StopMarketOrderQuoteEstimationType,
  UserStopMarketOrdersTokensAllowance,
} from '../../../../interfaces';
import { cn } from '../../../../utils';
import { TypographyP, TypographyS } from '../../../ui/typography';
import { PriceConditionSummary } from '../../order/price-condition-summary-label';
import { SwapAmountLabel } from '../../order/swap-amount-label';
import { StopMarketOrderTokenEstimation } from '../components/stop-market-order-token-estimation-label';
import { StopMarketOpenOrderTokenApprovalState } from '../open-order/stop-market-open-order-token-approval-state';

type StopMarketOrderOpenPositionPreviewProps = {
  openOrder: LimitOpenOrderType['order'];
  isTokenInApproved: boolean;
  isStopMarketOrderQuoteEstimationLoading: boolean;
  openOrderQuoteEstimation: StopMarketOrderQuoteEstimationType | null;
  approveOpenOrderTokenError: string;
  isOpenOrderTokenApproving: boolean;
  areStopMarketOrdersTokensAllowanceLoading: boolean;
  isOpenOrderTokenInAllowanceLoading: boolean;
  amountOfOpenOrderTokenInToBeApprovedForOrders: string | undefined;
  stopMarketOrdersTokensAllowanceError: string | undefined;
  stopMarketOrderQuoteEstimationError: string | undefined;
  openOrderTokenInAllowanceError: string | undefined;
  refetchOpenOrderTokenInAllowance: (options?: RefetchOptions) => Promise<QueryObserverResult<string, Error>>;
  refetchStopMarketOrdersTokensAllowance: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<AxiosResponse<UserStopMarketOrdersTokensAllowance>, Error>>;
  approveOpenOrderTokenHandler: () => Promise<void>;
  refetchStopMarketOrderQuoteEstimation: (options?: RefetchOptions) => Promise<
    QueryObserverResult<
      AxiosResponse<{
        openOrderQuoteEstimation: StopMarketOrderQuoteEstimationType;
        closeOrderQuoteEstimation: StopMarketOrderQuoteEstimationType | null;
      }>,
      Error
    >
  >;
};

export const StopMarketOrderOpenPositionPreview: FC<StopMarketOrderOpenPositionPreviewProps> = ({
  isTokenInApproved,
  openOrder,
  isStopMarketOrderQuoteEstimationLoading,
  openOrderQuoteEstimation,
  approveOpenOrderTokenError,
  isOpenOrderTokenApproving,
  areStopMarketOrdersTokensAllowanceLoading,
  amountOfOpenOrderTokenInToBeApprovedForOrders,
  stopMarketOrdersTokensAllowanceError,
  stopMarketOrderQuoteEstimationError,
  isOpenOrderTokenInAllowanceLoading,
  openOrderTokenInAllowanceError,
  refetchOpenOrderTokenInAllowance,
  refetchStopMarketOrderQuoteEstimation,
  approveOpenOrderTokenHandler,
  refetchStopMarketOrdersTokensAllowance,
}) => {
  return (
    <div>
      <TypographyP className="dark:text-yellow-400 font-medium text-sm mb-2">Open Position</TypographyP>

      <div className="pl-2">
        <SwapAmountLabel
          amountIn={openOrder.amountIn}
          amountOut={null}
          isCloseOrder={false}
          tokenIn={openOrder.tokenIn}
          tokenOut={openOrder.tokenOut}
        />

        <div className="mt-1 text-sm flex items-center gap-1">
          <TypographyS className={cn('dark:text-yellow-400 italic')}>Condition: </TypographyS>
          <PriceConditionSummary
            tokenIn={openOrder.tokenIn}
            tokenOut={openOrder.tokenOut}
            value={openOrder.priceCondition.value}
            closeOrder={false}
          />
        </div>

        <div className="p-3 flex mt-4 flex-col space-y-2 border border-dashed dark:border-primary-light-gray border-primary-light-gray/40 rounded-lg w-full  mb-3">
          <StopMarketOpenOrderTokenApprovalState
            approveTokenError={approveOpenOrderTokenError}
            isTokenApproved={isTokenInApproved}
            isTokenApproving={isOpenOrderTokenApproving}
            openOrderTokenInAllowanceError={openOrderTokenInAllowanceError}
            isOpenOrderTokenInAllowanceLoading={isOpenOrderTokenInAllowanceLoading}
            areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
            tokenIn={openOrder.tokenIn}
            amountOfTokenToBeApproved={amountOfOpenOrderTokenInToBeApprovedForOrders}
            stopMarketOrdersTokensAllowanceError={stopMarketOrdersTokensAllowanceError}
            refetchStopMarketOrdersTokensAllowance={refetchStopMarketOrdersTokensAllowance}
            onTryApproveAgainClick={approveOpenOrderTokenHandler}
            refetchOpenOrderTokenInAllowance={refetchOpenOrderTokenInAllowance}
          />

          <StopMarketOrderTokenEstimation
            tokenIn={openOrder.tokenIn}
            tokenOut={openOrder.tokenOut}
            isTokenInApproved={isTokenInApproved}
            isCloseOrder={false}
            orderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
            orderQuoteEstimation={openOrderQuoteEstimation}
            orderEstimationError={stopMarketOrderQuoteEstimationError}
            refetchStopMarketOrderQuoteEstimation={refetchStopMarketOrderQuoteEstimation}
          />
        </div>
      </div>
    </div>
  );
};
