import { FC } from 'react';
import { cn } from '../../utils';

type TypographyProps = {
  children: React.ReactNode;
  className?: string;
};

export const TypographyH1: FC<TypographyProps> = ({ children, className }) => {
  return (
    <h1 className={cn('scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl', className)}>{children}</h1>
  );
};

export const TypographyH2: FC<TypographyProps> = ({ children, className }) => {
  return <h2 className={cn('scroll-m-20 text-3xl font-semibold tracking-tight', className)}>{children}</h2>;
};

export const TypographyH3: FC<TypographyProps> = ({ children, className }) => {
  return <h3 className={cn('scroll-m-20 text-2xl font-semibold tracking-tight', className)}>{children}</h3>;
};

export const TypographyH4: FC<TypographyProps> = ({ children, className }) => {
  return <h4 className={cn('scroll-m-20 text-xl font-semibold tracking-tight', className)}>{children}</h4>;
};

export const TypographyP: FC<TypographyProps> = ({ children, className }) => {
  return <p className={cn(className)}>{children}</p>;
};

export const TypographyS: FC<TypographyProps> = ({ children, className }) => {
  return <span className={cn(className)}>{children}</span>;
};
