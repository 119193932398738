import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Address } from 'viem';
import { APIConfig } from '../../configs';

export type GenerateVerificationMessageArgs = {
  address: Address;
};

const generateVerificationMessage = ({ address }: GenerateVerificationMessageArgs) =>
  axios
    .get<{
      nonce: string;
    }>(`${APIConfig.baseURL}/u/nonce?address=${address}`)
    .then((response) => response.data);

export const useVerificationMessage = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: generateVerificationMessage,
  });

  return {
    generateVerificationMessage: mutateAsync,
    isVerificationMessageGenerating: isPending,
  };
};
