import { FC } from 'react';
import { cn } from '../../utils';

type IconProps = {
  width?: number;
  height?: number;
  className?: string;
};

export const LogoIcon: FC<IconProps> = ({ width = 24, className, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 6239 2533"
      fill={'currentColor'}
      className={cn('', className)}
    >
      <defs>
        <style type="text/css"></style>
      </defs>
      <g>
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path d="M4315 2013l250 520 -616 0 -257 -520 624 0zm1452 -1491l367 -260 105 -262 -1703 0 0 0 -613 0 -515 1033 -421 0 -515 -1033 -590 0 0 0 -1883 0 105 262 367 260 641 0 0 0 1040 0 347 674 -641 1337 590 0 506 -1005c391,0 739,1 1130,1l-160 -333 345 -674 710 0 0 0 790 0zm-4090 2005l-1197 -1 5 -1823 517 0 0 1301 925 1 -250 522zm2519 -1525c12,3 24,5 36,5l1067 0c306,0 573,219 627,522 6,35 7,67 7,101l0 283c0,349 -289,620 -634,620l-580 0 -248 -517 828 -5c56,0 112,-38 112,-98l0 -283c0,-59 -56,-101 -112,-101l-1042 0 -160 -333 99 -194z" />
      </g>
    </svg>
  );
};
