import { Chart, Indicator, IndicatorFilter, IndicatorTemplate, KLineData } from 'klinecharts';
import { BOLL } from '../../components/order/chart/indicators/boll';
import { Ema } from '../../components/order/chart/indicators/ema';
import { RSI } from '../../components/order/chart/indicators/rsi';
import { SMA } from '../../components/order/chart/indicators/sma';
import { ChartDateIntervalRangeEnum, IndicatorEnum, OHLCIntervalEnum, SMAIndicatorData } from '../../interfaces';

export const getCurrentValueForIndicator = <T = SMAIndicatorData>({
  chart,
  indicator,
  filter,
  data,
  paneId,
}: {
  chart: Chart;
  indicator: IndicatorEnum;
  filter: IndicatorFilter;
  data: KLineData[];
  paneId: string;
}): T => {
  const createdIndicator = chart
    .getIndicators(filter)
    .get(paneId)
    ?.find((i) => i.name === indicator);

  const calculatedValues = createdIndicator?.calc(data, createdIndicator) as [];

  const currentValue = calculatedValues?.at(-1) as T;

  return currentValue;
};

export const groupFlatKLineInfo = (data: KLineData[]) => {
  const groupedCandleStickData = [];
  let currentGroup = null;
  const flatCandleStickData: KLineData[] = [];

  for (let i = 0; i < data.length; i++) {
    const current = data[i];

    if (currentGroup && current.open === currentGroup.open && current.close === currentGroup.close) {
      flatCandleStickData.push(currentGroup);
    } else {
      if (currentGroup) {
        groupedCandleStickData.push(currentGroup);
      }

      currentGroup = { ...current };
    }
  }

  if (currentGroup) {
    groupedCandleStickData.push(currentGroup);
  }

  return {
    groupedCandleStickData,
    flatCandleStickData,
  };
};

export const getCurrentInfoFromInterval = (selectedRange: ChartDateIntervalRangeEnum) =>
  selectedRange === ChartDateIntervalRangeEnum.ONE_DAY
    ? Date.now() - 1000 * 60 * 60 * 24 * 1 // 1 day
    : selectedRange === ChartDateIntervalRangeEnum.FIVE_DAYS
      ? Date.now() - 1000 * 60 * 60 * 24 * 5 // 5 days
      : Date.now() - 1000 * 60 * 60 * 24 * 30; // 30 days

export const getCurrentInfoInterval = (selectedRange: ChartDateIntervalRangeEnum) =>
  selectedRange === ChartDateIntervalRangeEnum.ONE_DAY
    ? OHLCIntervalEnum.ONE_MIN
    : selectedRange === ChartDateIntervalRangeEnum.FIVE_DAYS
      ? OHLCIntervalEnum.FIVE_MIN
      : OHLCIntervalEnum.THIRTY_MIN;

export const getPrependInfoFromInterval = (
  currentInfoFromInterval: number,
  interval: OHLCIntervalEnum,
  maxTicks: number,
) => {
  switch (interval) {
    case OHLCIntervalEnum.FIVE_MIN:
      return currentInfoFromInterval - 1000 * 60 * 5 * maxTicks;
    case OHLCIntervalEnum.THIRTY_MIN:
      return currentInfoFromInterval - 1000 * 60 * 30 * maxTicks;
    case OHLCIntervalEnum.ONE_HOUR:
      return currentInfoFromInterval - 1000 * 60 * 60 * maxTicks;
    case OHLCIntervalEnum.TWO_HOUR:
      return currentInfoFromInterval - 1000 * 60 * 60 * 2 * maxTicks;
    case OHLCIntervalEnum.ONE_DAY:
      return currentInfoFromInterval - 1000 * 60 * 60 * 24 * maxTicks;
    default:
      return currentInfoFromInterval;
  }
};

export const recalculateEMAIndicatorChartFuncHelper = (
  dataList: KLineData[],
  prependOHLCData: KLineData[],
  indicator: Indicator<Ema>,
  indicatorTemplate: IndicatorTemplate<Ema>,
) => {
  const maxCalcParams = (indicator.calcParams as number[]).at(-1) || 0;

  const res = indicatorTemplate.calc([...prependOHLCData.slice(-maxCalcParams + 1), ...dataList], indicator) as Ema[];

  const spliceFactor = prependOHLCData.length < maxCalcParams ? prependOHLCData.length : maxCalcParams - 1;

  return res.splice(spliceFactor);
};

export const recalculateSMAIndicatorChartFuncHelper = (
  dataList: KLineData[],
  prependOHLCData: KLineData[],
  indicator: Indicator<SMA>,
  indicatorTemplate: IndicatorTemplate<SMA>,
) => {
  const maxCalcParams = (indicator.calcParams as number[])[0] || 0;

  const res = indicatorTemplate.calc([...prependOHLCData.slice(-maxCalcParams + 1), ...dataList], indicator) as SMA[];

  const spliceFactor = prependOHLCData.length < maxCalcParams ? prependOHLCData.length : maxCalcParams - 1;

  return res.splice(spliceFactor);
};

export const recalculateRSIIndicatorChartFuncHelper = (
  dataList: KLineData[],
  prependOHLCData: KLineData[],
  indicator: Indicator<RSI>,
  indicatorTemplate: IndicatorTemplate<RSI>,
) => {
  const maxCalcParams = (indicator.calcParams as number[]).at(-1) || 0;

  const res = indicatorTemplate.calc([...prependOHLCData.slice(-maxCalcParams + 1), ...dataList], indicator) as RSI[];

  const spliceFactor = prependOHLCData.length < maxCalcParams ? prependOHLCData.length : maxCalcParams - 1;

  return res.splice(spliceFactor);
};

export const recalculateBOLLIndicatorChartFuncHelper = (
  dataList: KLineData[],
  prependOHLCData: KLineData[],
  indicator: Indicator<BOLL>,
  indicatorTemplate: IndicatorTemplate<BOLL>,
) => {
  const maxCalcParams = (indicator.calcParams as number[])[0] || 0;

  const res = indicatorTemplate.calc([...prependOHLCData.slice(-maxCalcParams + 1), ...dataList], indicator) as BOLL[];

  const spliceFactor = prependOHLCData.length < maxCalcParams ? prependOHLCData.length : maxCalcParams - 1;

  return res.splice(spliceFactor);
};
