import './App.css';
import { BaseLayout, ModalRegistry } from './components';
import { useTheme, useVerifyAccountListener } from './hooks';
import { AppRoutes } from './routes/app-routes';

export const App = () => {
  useVerifyAccountListener();

  const { isThemeSet } = useTheme();

  return !isThemeSet ? null : (
    <>
      <BaseLayout>
        <AppRoutes />
        <ModalRegistry />
      </BaseLayout>
    </>
  );
};
