import { utils } from 'klinecharts';
import { FC, memo } from 'react';
import { cn } from '../../../../utils';
import { TooltipLabel } from './tooltip-label';

type RSITooltipProps = {
  rsi1: number;
  rsi2: number;
  rsi3: number;
  precision: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const RSITooltipComp: FC<RSITooltipProps> = ({ rsi1, rsi2, rsi3, precision, className, ...props }) => {
  return (
    <div
      className={cn(
        'flex gap-2 flex-wrap backdrop-blur-lg z-10 p-1 rounded-r-md w-fit bg-white/60 dark:bg-primary-black/20',
        className,
      )}
      {...props}
    >
      <TooltipLabel title="RSI(6,12,24)" />
      <TooltipLabel
        className="text-orange-400 dark:text-orange-400"
        value={rsi1 ? utils.formatFoldDecimal(rsi1.toFixed(precision), precision) : 0}
        title="RSI1:"
      />
      <TooltipLabel
        className="text-purple-400 dark:text-purple-400"
        value={rsi2 ? utils.formatFoldDecimal(rsi2.toFixed(precision), precision) : 0}
        title="RSI2:"
      />
      <TooltipLabel
        className="text-blue-400 dark:text-blue-400"
        value={rsi3 ? utils.formatFoldDecimal(rsi3.toFixed(precision), precision) : 0}
        title="RSI3:"
      />
    </div>
  );
};

export const RSITooltip = memo(RSITooltipComp);
