import { BrowserProvider, ethers } from 'ethers';
import type { Account, Chain, Client, Transport } from 'viem';

export function clientToProvider(client: Client<Transport, Chain, Account>) {
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new BrowserProvider(transport, network);

  return provider;
}

export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const provider = ethers.getDefaultProvider(chainId);

  return provider;
}
