import { CornerDownRightIcon } from 'lucide-react';
import { FC } from 'react';
import { PriceConditionPositionTypeEnum, StopMarketOrderInfo } from '../../../../interfaces';
import { formatAmount } from '../../../../utils';
import { getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { TypographyP, TypographyS } from '../../../ui/typography';
import { getPriceConditionIconUrl, getPriceConditionLabel } from '../../order/price-condition-selector';

type PriceConditionsColumnProps = {
  order: StopMarketOrderInfo;
};

export const PriceConditionsColumn: FC<PriceConditionsColumnProps> = ({ order }) => {
  const openCondition = order.priceConditions.find((c) => c.positionType === PriceConditionPositionTypeEnum.OPEN);
  const closeCondition = order.priceConditions.find((c) => c.positionType === PriceConditionPositionTypeEnum.CLOSE);

  const tokenIn = getTokenByTokenId(order.tokenInId);
  const tokenOut = getTokenByTokenId(order.tokenOutId);
  const closeTokenIn = getTokenByTokenId(order.tokenOutId);
  const closeTokenOut = order.closeTokenOutId && getTokenByTokenId(order.closeTokenOutId);

  return (
    <div className="flex space-y-1.5 flex-col">
      <div className="flex gap-1">
        <TypographyP className=" italic dark:text-amber-400 font-medium text-[11px]">Open</TypographyP>
        <div>
          {openCondition && tokenIn && tokenOut && (
            <div className="flex gap-1 items-center justify-start text-[10px]">
              {formatAmount({
                value: parseFloat(openCondition.value),
                withoutFormatForSmallValue: true,
              })}
              <TypographyS className="text-[10px]">
                {getPriceConditionLabel(openCondition.conditionType, tokenIn, tokenOut)}
              </TypographyS>
              <img
                className="w-3 h-3"
                src={getPriceConditionIconUrl(openCondition.conditionType, tokenIn, tokenOut)}
                alt="open"
              />
            </div>
          )}
        </div>
      </div>
      {closeCondition && closeTokenIn && closeTokenOut && (
        <div className="flex gap-1">
          <div className="flex items-center relative">
            <CornerDownRightIcon className="w-2 h-2 absolute top-1/2 left-[-10px] -translate-y-1/2 dark:text-purple-400 text-black/50 mr-1" />
            <TypographyP className="italic dark:text-purple-400 text-black/50 text-[11px]">Close</TypographyP>
          </div>

          <div className="flex gap-1 text-[10px] items-center justify-start dark:text-white/60 text-black/60">
            {formatAmount({
              value: parseFloat(closeCondition.value),
              withoutFormatForSmallValue: true,
            })}
            <TypographyS className="text-[10px]">
              {getPriceConditionLabel(closeCondition.conditionType, closeTokenIn, closeTokenOut)}
            </TypographyS>
            <img
              src={getPriceConditionIconUrl(closeCondition.conditionType, closeTokenIn, closeTokenOut)}
              alt="open"
              className="w-3 h-3"
            />
          </div>
        </div>
      )}
    </div>
  );
};
