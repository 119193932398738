export const sortTokens = (token0Address: string, token1Address: string) =>
  [token0Address.toLowerCase(), token1Address.toLowerCase()].sort((a, b) => (a < b ? -1 : 1));

export const getOHLCPairStoreKey = (
  tokenIn: string,
  tokenOut: string,
  chainId: number,
  {
    sortTokenPair,
  }: {
    sortTokenPair?: boolean;
  } = {},
) => {
  let tokenAddressesKey = `${tokenIn}-${tokenOut}`;

  if (sortTokenPair) {
    tokenAddressesKey = sortTokens(tokenIn, tokenOut).join('-');
  }

  const key = `${tokenAddressesKey}-${chainId}`;

  return key;
};
