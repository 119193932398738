import { ethers } from 'ethers';
import { arbitrum, base, optimism } from 'wagmi/chains';

const arbitrumProvider = new ethers.JsonRpcProvider(arbitrum.rpcUrls.default.http[0]);
const optimismProvider = new ethers.JsonRpcProvider(optimism.rpcUrls.default.http[0]);
const baseProvider = new ethers.JsonRpcProvider(base.rpcUrls.default.http[0]);

export const getRpcProvider = (chainId: number) => {
  switch (chainId) {
    case 42161:
      return arbitrumProvider;
    case 10:
      return optimismProvider;
    case 8453:
      return baseProvider;
    default:
      console.error('Unsupported chainId');

      return null;
  }
};
