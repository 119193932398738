import { formatUnits } from 'ethers';
import { ChevronsRightIcon } from 'lucide-react';
import { useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import {
  Button,
  LogoIcon,
  SettingsWidget,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SpinnerIcon,
  TypographyH2,
  TypographyP,
} from '..';
import { useGetAccountBalance } from '../../hooks';
import { formatAmount, shortenAddress } from '../../utils';
import { CustomConnectButton } from '../custom/connect-button';

const DISCONNECT_ICON_URL = 'https://www.svgrepo.com/show/309877/plug-disconnected.svg';

export const Header = () => {
  const { isConnected, address, isReconnecting, connector, chainId } = useAccount();

  const { disconnect, isPending: isDisconnecting } = useDisconnect();

  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

  const accountBalance = useGetAccountBalance({
    isConnected,
    address,
    chainId: chainId || 0,
  });

  const onDisconnect = async () => {
    disconnect();

    setDrawerOpen(false);
  };

  return (
    <header className="fixed w-full backdrop-blur-lg z-20 px-4">
      <nav className="container">
        <div className=" mx-auto flex justify-between items-center">
          <div className="flex items-center gap-2">
            <LogoIcon className="w-16 h-12 dark:text-white text-primary-dark-blue" />
            <TypographyH2 className="text-2xl dark:text-white text-primary-dark-blue font-bold ">
              DEX Standard
            </TypographyH2>
          </div>
          <div className="flex items-center gap-4">
            {isConnected ? (
              <div className="flex items-center space-x-2">
                <Sheet open={isDrawerOpen} onOpenChange={(open) => setDrawerOpen(open)}>
                  <SheetTrigger asChild>
                    <Button variant="outline" className="min-w-[110px]">
                      {connector?.icon ? <img className="w-4 h-4" src={connector.icon} /> : null}
                      {isReconnecting ? <SpinnerIcon className="mx-auto" /> : shortenAddress(address)}
                    </Button>
                  </SheetTrigger>
                  <SheetContent
                    showClose={false}
                    className=" dark:bg-primary-black/20 bg-white/20 backdrop-blur-lg border  rounded-r-none rounded-l-md dark:border-primary-dark-blue border-primary-dark-blue/10"
                    side="right"
                  >
                    <Button
                      onClick={() => setDrawerOpen(false)}
                      className="absolute focus-within:ring-0 top-0 h-full !p-0 group !m-0 flex rounded-l-md shadow-none rounded-r-none items-start !pt-6 justify-center right-[100%] w-12 bg-transparent hover:dark:bg-primary-black/40 hover:bg-primary-black/10 transition-all duration-300 "
                    >
                      <ChevronsRightIcon className="!w-6 !h-6 group-hover:translate-x-1 transition-all shrink-0 dark:text-white text-black/60" />
                    </Button>
                    <SheetHeader>
                      <SheetTitle className="dark:text-white text-black">Account Info</SheetTitle>
                    </SheetHeader>

                    <div className="flex flex-col h-full mt-2">
                      <div className=" dark:bg-primary-black-gradient bg-primary-light-gray/5 flex flex-col justify-between rounded-lg p-4 relative h-[150px]">
                        <div className="flex gap-2 items-center z-20 rounded-lg dark:bg-primary-black/50 bg-white p-2 w-fit px-3">
                          {connector?.icon ? <img className="w-4 h-4" src={connector.icon} /> : null}
                          <TypographyP className="text-sm">{shortenAddress(address)}</TypographyP>
                        </div>

                        <TypographyP className="text-xs ml-2 z-20 dark:text-white/80">
                          Balance:{' '}
                          {formatAmount({
                            value: Number(formatUnits(accountBalance?.value || 0, accountBalance?.decimals)),
                          })}{' '}
                          {accountBalance?.symbol ?? ''}
                        </TypographyP>

                        <Button
                          disabled={isReconnecting || isDisconnecting}
                          className="top-4 right-4 absolute z-20 bg-white hover:bg-red-500 dark:bg-red-800 hover:dark:bg-red-600"
                          onClick={() => {
                            onDisconnect();
                          }}
                        >
                          <img src={DISCONNECT_ICON_URL} alt="Disconnect" className="w-6 h-6" />
                        </Button>

                        <LogoIcon className="w-56 h-32 absolute dark:fill-white/5 fill-black/5 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" />
                      </div>

                      <div className="flex items-center gap-2 mt-auto mb-4 self-end">
                        <LogoIcon className="w-10 h-10 text-white" />
                        <TypographyH2 className="text-xl text-white font-bold ">DEX Standard</TypographyH2>
                      </div>
                    </div>
                  </SheetContent>
                </Sheet>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <CustomConnectButton />
              </div>
            )}

            <SettingsWidget />
          </div>
        </div>
      </nav>
    </header>
  );
};
