import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { ethers } from 'ethers';
import { CheckCircle2Icon, CircleHelpIcon, XCircleIcon } from 'lucide-react';
import { FC } from 'react';
import { Token } from '../../../../interfaces';
import { formatAmount } from '../../../../utils';
import { TokenLabel } from '../../../custom/token-label';
import { SpinnerIcon } from '../../../icons/spinner-icon';
import { Button } from '../../../ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../ui/tooltip';
import { TypographyP, TypographyS } from '../../../ui/typography';

type StopMarketCloseOrderTokenApprovalStateProps = {
  tokenIn: Token;
  isTokenApproving: boolean;
  isTokenApproved: boolean;
  approveTokenError: string;
  areStopMarketOrdersTokensAllowanceLoading: boolean;
  amountOfTokenToBeApproved: string | undefined;
  approveOpenOrderMessage: string;
  stopMarketOrdersTokensAllowanceError: string | undefined;
  closeOrderTokenInAllowanceError: string | undefined;
  isCloseOrderTokenInAllowanceLoading: boolean;
  isStopMarketOrderQuoteEstimationLoading: boolean;
  refetchCloseOrderTokenInAllowance: (options?: RefetchOptions) => Promise<QueryObserverResult<string, Error>>;
  onTryApproveAgainClick: () => Promise<void>;
};

export const StopMarketCloseOrderTokenApprovalState: FC<StopMarketCloseOrderTokenApprovalStateProps> = ({
  tokenIn,
  approveTokenError,
  isTokenApproved,
  isTokenApproving,
  areStopMarketOrdersTokensAllowanceLoading,
  amountOfTokenToBeApproved,
  stopMarketOrdersTokensAllowanceError,
  approveOpenOrderMessage,
  closeOrderTokenInAllowanceError,
  isCloseOrderTokenInAllowanceLoading,
  isStopMarketOrderQuoteEstimationLoading,
  refetchCloseOrderTokenInAllowance,
  onTryApproveAgainClick,
}) => {
  return (
    <div className="flex items-center gap-1">
      <TokenLabel imgClassName="w-3.5 h-3.5" token={tokenIn} textClassName="font-semibold" />
      <div className="flex items-center gap-1">
        {areStopMarketOrdersTokensAllowanceLoading || isCloseOrderTokenInAllowanceLoading ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">
              - checking approved allowance
            </TypographyS>
            <SpinnerIcon className=" w-3 h-3" />
          </>
        ) : closeOrderTokenInAllowanceError ? (
          <>
            -
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="w-fit  group flex items-center text-[11px] justify-center">
                  <TypographyS className="text-xs flex items-center dark:text-white/70 text-black/70 underline ">
                    allowance error
                    <XCircleIcon className="text-red-500 w-3.5 h-3.5 ml-1" />
                  </TypographyS>
                </TooltipTrigger>
                <TooltipContent
                  align="center"
                  side="right"
                  className="bg-white/30 shadow space-y-1 dark:bg-primary-black/30 max-w-[240px] w-fit backdrop-blur-lg p-2"
                >
                  <TypographyS className="text-xs dark:text-white/70 text-black/70  truncate">
                    {closeOrderTokenInAllowanceError}
                  </TypographyS>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <Button
              disabled={isCloseOrderTokenInAllowanceLoading}
              onClick={async () => await refetchCloseOrderTokenInAllowance()}
              variant="ghost"
              className="text-[10px] h-fit p-1 dark:text-white/80 dark:hover:text-white text-black/80 hover:text-black"
            >
              Try again?
            </Button>
          </>
        ) : stopMarketOrdersTokensAllowanceError ? (
          <>
            -
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="w-fit  group flex items-center text-[11px] justify-center">
                  <TypographyS className="text-xs flex items-center dark:text-white/70 text-black/70 underline ">
                    allowance error
                    <XCircleIcon className="text-red-500 w-3.5 h-3.5 ml-1" />
                  </TypographyS>
                </TooltipTrigger>
                <TooltipContent
                  align="center"
                  side="right"
                  className="bg-white/30 shadow space-y-1 dark:bg-primary-black/30 max-w-[240px] w-fit backdrop-blur-lg p-2"
                >
                  <TypographyS className="text-xs dark:text-white/70 text-black/70  truncate">
                    {stopMarketOrdersTokensAllowanceError}
                  </TypographyS>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </>
        ) : isTokenApproving ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">- approving</TypographyS>
            <SpinnerIcon className=" w-3 h-3" />
          </>
        ) : approveOpenOrderMessage ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">- {approveOpenOrderMessage}</TypographyS>
          </>
        ) : isStopMarketOrderQuoteEstimationLoading ? (
          <div className="flex items-center gap-1">
            <TypographyS className="text-xs dark:text-white/70 text-black/70 ">estimating</TypographyS>

            <SpinnerIcon className="w-3 h-3" />
          </div>
        ) : !amountOfTokenToBeApproved ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">- cannot estimate amount</TypographyS>
          </>
        ) : !isTokenApproved && !approveTokenError ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">
              - require approving of{' '}
              {formatAmount({
                value: +ethers.formatUnits(amountOfTokenToBeApproved, tokenIn.decimals),
                withoutFormatForSmallValue: true,
              })}{' '}
              {tokenIn.symbol}
            </TypographyS>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="w-fit mx-auto flex items-center text-[11px] justify-center">
                  <CircleHelpIcon className="dark:text-white/70 dark:hover:text-white text-black/70 hover:text-black ml-1 w-3.5 h-3.5" />
                </TooltipTrigger>
                <TooltipContent
                  align="center"
                  side="right"
                  className="bg-white/30 shadow dark:bg-primary-black/30 max-w-[220px] backdrop-blur-lg p-2"
                >
                  <TypographyP className="dark:text-white text-[11px] text-black first-letter:capitalize">
                    Sum of amounts from active orders and current order.
                  </TypographyP>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </>
        ) : approveTokenError ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70 flex items-center">
              - {approveTokenError}
              <XCircleIcon className="text-red-500 w-3.5 h-3.5" />
            </TypographyS>

            <Button
              disabled={isTokenApproving}
              onClick={async () => await onTryApproveAgainClick()}
              variant="link"
              className="underline text-[10px] h-fit p-0 ml-2 dark:text-white/80 dark:hover:text-white text-black/80 hover:text-black"
            >
              Try again?
            </Button>
          </>
        ) : (
          <>
            <TypographyS className="text-xs dark:text-white/70">- approved</TypographyS>
            <CheckCircle2Icon className="text-green-600 w-3.5 h-3.5" />
          </>
        )}
      </div>
    </div>
  );
};
