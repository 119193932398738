import { Indicator, IndicatorTemplate, KLineData } from 'klinecharts';
import { IndicatorEnum } from '../../../../interfaces';

export interface RSI {
  rsi1?: number;
  rsi2?: number;
  rsi3?: number;
}

export const relativeStrengthIndexIndicator: IndicatorTemplate<RSI> = {
  name: IndicatorEnum.RSI,
  shortName: IndicatorEnum.RSI,
  calcParams: [6, 12, 24],
  figures: [
    { key: 'rsi1', title: 'RSI1: ', type: 'line' },
    { key: 'rsi2', title: 'RSI2: ', type: 'line' },
    { key: 'rsi3', title: 'RSI3: ', type: 'line' },
  ],
  regenerateFigures: (params: unknown[]) =>
    params.map((_: unknown, index: number) => {
      const num = index + 1;

      return { key: `rsi${num}`, title: `RSI${num}: `, type: 'line' };
    }),
  calc: (dataList: KLineData[], indicator: Indicator<RSI>) => {
    const { calcParams, figures } = indicator;
    const params = calcParams as number[];
    const sumCloseAs: number[] = [];
    const sumCloseBs: number[] = [];

    return dataList.map((kLineData, i) => {
      const rsi: Record<string, number> = {};
      const prevClose = (dataList[i - 1] ?? kLineData).close;
      const tmp = kLineData.close - prevClose;
      params.forEach((p, index) => {
        if (tmp > 0) {
          sumCloseAs[index] = (sumCloseAs[index] ?? 0) + tmp;
        } else {
          sumCloseBs[index] = (sumCloseBs[index] ?? 0) + Math.abs(tmp);
        }
        if (i >= p - 1) {
          if (sumCloseBs[index] !== 0) {
            rsi[figures[index].key] = 100 - 100.0 / (1 + sumCloseAs[index] / sumCloseBs[index]);
          } else {
            rsi[figures[index].key] = 0;
          }
          const agoData = dataList[i - (p - 1)];
          const agoPreData = dataList[i - p] ?? agoData;
          const agoTmp = agoData.close - agoPreData.close;
          if (agoTmp > 0) {
            sumCloseAs[index] -= agoTmp;
          } else {
            sumCloseBs[index] -= Math.abs(agoTmp);
          }
        }
      });

      return rsi;
    });
  },
};
