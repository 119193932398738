import { FC } from 'react';
import { PriceConditionTypeEnum, Token } from '../../../interfaces';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../ui/select';

const conditionTypeOptions: PriceConditionTypeEnum[] = [
  PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
  PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN,
  PriceConditionTypeEnum.TOKEN_IN_USD,
  PriceConditionTypeEnum.TOKEN_OUT_USD,
];

export const getPriceConditionLabel = (type: PriceConditionTypeEnum, tokenIn: Token, tokenOut: Token) => {
  const tokenInSymbol = tokenIn.symbol;
  const tokenOutSymbol = tokenOut.symbol;

  if (type === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) return `${tokenInSymbol} / ${tokenOutSymbol}`;
  if (type === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) return `${tokenOutSymbol} / ${tokenInSymbol}`;
  if (type === PriceConditionTypeEnum.TOKEN_IN_USD) return `USD / ${tokenInSymbol}`;
  if (type === PriceConditionTypeEnum.TOKEN_OUT_USD) return `USD / ${tokenOutSymbol}`;
};

export const getIconImg = (uri: string, alt: string) => {
  return <img src={uri} alt={alt} className="w-3.5 h-3.5" />;
};

export const getPriceConditionIcon = (type: PriceConditionTypeEnum, tokenIn: Token, tokenOut: Token) => {
  if (type === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) return getIconImg(tokenIn.logoUri, tokenIn.symbol);
  if (type === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) return getIconImg(tokenOut.logoUri, tokenOut.symbol);
  if (type === PriceConditionTypeEnum.TOKEN_IN_USD || type === PriceConditionTypeEnum.TOKEN_OUT_USD)
    return getIconImg('https://cdn-icons-png.flaticon.com/256/262/262280.png', 'USD');
};

export const getPriceConditionIconUrl = (type: PriceConditionTypeEnum, tokenIn: Token, tokenOut: Token) => {
  if (type === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) return tokenIn.logoUri;
  if (type === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) return tokenOut.logoUri;
  if (type === PriceConditionTypeEnum.TOKEN_IN_USD || type === PriceConditionTypeEnum.TOKEN_OUT_USD)
    return 'https://cdn-icons-png.flaticon.com/256/262/262280.png';
};

type PriceConditionSelectorProps = {
  handlePriceConditionChange: (condition: PriceConditionTypeEnum) => void;
  selectedCondition: PriceConditionTypeEnum;
  tokenIn: Token;
  tokenOut: Token;
};

export const PriceConditionSelector: FC<PriceConditionSelectorProps> = ({
  handlePriceConditionChange,
  selectedCondition,
  tokenIn,
  tokenOut,
}) => {
  return (
    <Select
      onValueChange={(value) => {
        handlePriceConditionChange(value as PriceConditionTypeEnum);
      }}
      value={selectedCondition}
    >
      <SelectTrigger className=" bg-transparent w-fit gap-1 cursor-pointer text-xs">
        {getPriceConditionIcon(selectedCondition, tokenIn, tokenOut)}
        {getPriceConditionLabel(selectedCondition, tokenIn, tokenOut)}
      </SelectTrigger>
      <SelectContent>
        {conditionTypeOptions
          .filter((c) => {
            const tokenInHasOracle = tokenIn.usdPriceOracle;
            const tokenOutHasOracle = tokenOut.usdPriceOracle;

            if (
              (c === PriceConditionTypeEnum.TOKEN_IN_USD && !tokenInHasOracle) ||
              (c === PriceConditionTypeEnum.TOKEN_OUT_USD && !tokenOutHasOracle) ||
              (c === PriceConditionTypeEnum.TOKEN_IN_USD && tokenInHasOracle && tokenIn.isStablecoin) ||
              (c === PriceConditionTypeEnum.TOKEN_OUT_USD && tokenOutHasOracle && tokenOut.isStablecoin)
            ) {
              return false;
            }

            return true;
          })
          .map((option) => (
            <SelectItem key={option} value={option} className="">
              <div className="flex gap-1 items-center">
                {getPriceConditionIcon(option, tokenIn, tokenOut)}
                {getPriceConditionLabel(option, tokenIn, tokenOut)}
              </div>
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};
