import { FC } from 'react';
import { Token } from '../../../interfaces';
import { cn, formatAmount } from '../../../utils';
import { TokenLabel } from '../../custom/token-label';
import { TypographyP, TypographyS } from '../../ui/typography';

type SwapAmountLabelProps = {
  amountIn: string | null;
  amountOut: string | null;
  tokenIn: Token;
  tokenOut: Token;
  isCloseOrder: boolean;
};

export const SwapAmountLabel: FC<SwapAmountLabelProps> = ({ amountIn, amountOut, tokenIn, tokenOut, isCloseOrder }) => {
  return (
    <div className="text-sm flex">
      <TypographyP className="mr-1">
        Swap
        {amountIn ? (
          <TypographyS
            className={cn('mx-1 dark:text-yellow-400', {
              'dark:text-purple-400': isCloseOrder,
            })}
          >
            {formatAmount({
              value: +amountIn,
              withoutFormatForSmallValue: true,
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
              precise: true,
            })}
          </TypographyS>
        ) : null}
      </TypographyP>
      {<TokenLabel token={tokenIn} imgClassName="w-3.5 h-3.5 " />}
      <TypographyP className="mx-1">for </TypographyP>
      {amountOut ? (
        <>
          <TypographyS
            className={cn('mr-1 dark:text-yellow-400', {
              'dark:text-purple-400': isCloseOrder,
            })}
          >
            {formatAmount({
              value: +amountOut,
              withoutFormatForSmallValue: true,
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
              precise: true,
            })}{' '}
          </TypographyS>
        </>
      ) : null}
      {<TokenLabel token={tokenOut} imgClassName="w-3.5 h-3.5" />}
    </div>
  );
};
