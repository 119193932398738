import { FC } from 'react';
import { LimitCloseOrderType, LimitOpenOrderType } from '../../../../interfaces';
import { Button } from '../../../ui/button';
import { StopMarketOrderInsufficientError } from './stop-market-order-insufficient-error';

type StopMarketOrderPreviewModalActionsProps = {
  showInsufficientTokenInBalanceError: boolean;
  amountOfOpenOrderTokenInToBeApprovedForOrders: string | undefined;
  openOrder: LimitOpenOrderType['order'];
  closeOrder: LimitCloseOrderType['order'] | null;
  isOpenOrderTokenTotalAmountApproved: boolean;
  areStopMarketOrdersTokensAllowanceLoading: boolean;
  isOpenOrderTokenApproving: boolean;
  isStopMarketOrderQuoteEstimationLoading: boolean;
  isCloseOrderTokenApproving: boolean;
  isCreateStopMarketOrderPending: boolean;
  isCloseOrderTokenTotalAmountApproved: boolean;
  approveOpenOrderTokenHandler: () => Promise<void>;
  approveCloseOrderTokenHandler: () => Promise<void>;
  createStopMarketOrderHandler: () => Promise<void>;
};

export const StopMarketOrderPreviewModalActions: FC<StopMarketOrderPreviewModalActionsProps> = ({
  showInsufficientTokenInBalanceError,
  amountOfOpenOrderTokenInToBeApprovedForOrders,
  openOrder,
  closeOrder,
  isCloseOrderTokenTotalAmountApproved,
  isOpenOrderTokenApproving,
  isOpenOrderTokenTotalAmountApproved,
  isCreateStopMarketOrderPending,
  approveOpenOrderTokenHandler,
  approveCloseOrderTokenHandler,
  createStopMarketOrderHandler,
  areStopMarketOrdersTokensAllowanceLoading,
  isStopMarketOrderQuoteEstimationLoading,
  isCloseOrderTokenApproving,
}) => {
  if (showInsufficientTokenInBalanceError && amountOfOpenOrderTokenInToBeApprovedForOrders) {
    return (
      <StopMarketOrderInsufficientError
        amount={amountOfOpenOrderTokenInToBeApprovedForOrders}
        token={openOrder.tokenIn}
      />
    );
  }

  if (!isOpenOrderTokenTotalAmountApproved) {
    return (
      <Button
        className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
        onClick={approveOpenOrderTokenHandler}
        disabled={areStopMarketOrdersTokensAllowanceLoading || isOpenOrderTokenApproving}
        loading={isOpenOrderTokenApproving}
      >
        Allow to use {openOrder.tokenIn.symbol}
      </Button>
    );
  }

  if (isStopMarketOrderQuoteEstimationLoading) {
    return (
      <Button
        className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
        disabled={isStopMarketOrderQuoteEstimationLoading}
      >
        Estimating order quote
      </Button>
    );
  }

  if (closeOrder && isOpenOrderTokenTotalAmountApproved && !isCloseOrderTokenTotalAmountApproved) {
    return (
      <Button
        className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
        onClick={approveCloseOrderTokenHandler}
        disabled={areStopMarketOrdersTokensAllowanceLoading || isCloseOrderTokenApproving}
        loading={isCloseOrderTokenApproving}
      >
        Allow to use {openOrder.tokenOut.symbol}
      </Button>
    );
  }

  return (
    <Button
      className="bg-blue-500 hover:bg-blue-600 capitalize dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 rounded-lg text-sm font-medium"
      loading={isCreateStopMarketOrderPending}
      disabled={isCreateStopMarketOrderPending}
      onClick={createStopMarketOrderHandler}
    >
      Confirm and place order
    </Button>
  );
};
