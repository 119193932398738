import { ArrowRightLeftIcon } from 'lucide-react';
import { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { useDebounce, useGetUSDTokenPrice, ValidatePriceConditionParams } from '../../../../hooks';
import { FormLimitOpenOrderSchema, PriceConditionTypeEnum, Token } from '../../../../interfaces';
import { cn, getOpenOrderHeaderLabel, getTokenFromPriceConditionType } from '../../../../utils';
import { Button } from '../../../ui/button';
import { Input } from '../../../ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../ui/tooltip';
import { TypographyH4, TypographyS } from '../../../ui/typography';
import { PriceConditionInput } from '../../order/price-condition-input';
import { PriceConditionSelector } from '../../order/price-condition-selector';
import { TokenSelector } from '../../order/token-selector';

type StopMarketOpenOrderFormProps = {
  networkTokens: Token[];
  chainId: number;
  tokenAmountInput: string;
  priceConditionPriceInput: string;
  wasPriceConditionPriceInChanged: boolean;
  priceDifferenceWarning: {
    type: 'high' | 'low';
    delta: number;
  } | null;
  insufficientBalanceError: boolean;
  selectedTokenBalance: string | undefined;
  pairLatestPrice: string;
  isPairLatestPriceFetching: boolean;
  handleSetMarketplacePrice: () => void;
  setTokenAmountInput: React.Dispatch<React.SetStateAction<string>>;
  setPriceConditionPriceInput: React.Dispatch<React.SetStateAction<string>>;
  handleSelectedTokensChange: ({ tokenIn, tokenOut }: { tokenIn: Token; tokenOut: Token }) => void;
  validateOpenOrderPrice: (params: ValidatePriceConditionParams) => void;
  handleOrderConditionPriceChange: (price: string, wasPriceConditionPriceInChangedManually?: boolean) => void;
};

export const StopMarketOpenOrderForm: FC<StopMarketOpenOrderFormProps> = ({
  tokenAmountInput,
  priceConditionPriceInput,
  wasPriceConditionPriceInChanged,
  priceDifferenceWarning,
  networkTokens,
  chainId,
  insufficientBalanceError,
  selectedTokenBalance,
  pairLatestPrice,
  isPairLatestPriceFetching,
  setPriceConditionPriceInput,
  setTokenAmountInput,
  handleSetMarketplacePrice,
  handleSelectedTokensChange,
  validateOpenOrderPrice,
  handleOrderConditionPriceChange,
}) => {
  const formOpenOrder = useFormContext<z.infer<typeof FormLimitOpenOrderSchema>>();

  const openOrder = formOpenOrder.watch('order');

  const orderAmountIn = openOrder.amountIn;
  const orderTokenIn = openOrder.tokenIn;
  const orderTokenOut = openOrder.tokenOut;
  const orderPriceConditionType = openOrder.priceCondition.conditionType;
  const orderPriceConditionPrice = openOrder.priceCondition.value;

  const availableOrderTokensOut = networkTokens.filter((t) => {
    const isTokenStable = t.isStablecoin;

    if (isTokenStable && orderTokenIn.isStablecoin) {
      return false;
    }

    return true;
  });

  const [tokenInUsdPrice, tokenOutUsdPrice] = useGetUSDTokenPrice([
    {
      oracleAddress: orderTokenIn.usdPriceOracle,
      chainId,
    },
    {
      oracleAddress: orderTokenOut.usdPriceOracle,
      chainId,
    },
  ]);

  const amountInValidationError = formOpenOrder.formState.errors.order?.amountIn?.message || '';
  const orderPriceConditionValidationError = formOpenOrder.formState.errors.order?.priceCondition?.value?.message || '';

  const handleAmountInChange = useCallback((newAmount: string) => {
    formOpenOrder.setValue('order.amountIn', newAmount, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, []);

  const handleTokenInChange = useCallback(
    (tokenIn: Token) => {
      const isTokenInStablecoin = tokenIn.isStablecoin;
      const isTokenOutStablecoin = orderTokenOut.isStablecoin;

      let tokenOut = orderTokenOut;

      if (tokenIn.address === tokenOut.address || isTokenOutStablecoin) {
        const firstAvailableToken = networkTokens.find((t) => {
          const isNotTheSameToken = t.address !== tokenIn.address;
          const isNotStablecoin = !t.isStablecoin;

          if (isTokenInStablecoin) {
            return isNotStablecoin && isNotTheSameToken;
          }

          return isNotTheSameToken;
        });

        if (firstAvailableToken) {
          tokenOut = firstAvailableToken;
        }
      }

      handleSelectedTokensChange({ tokenIn, tokenOut });
    },
    [orderTokenOut, networkTokens],
  );

  const handleTokenOutChange = useCallback(
    (tokenOut: Token) => {
      const isTokenOutStablecoin = tokenOut.isStablecoin;
      const isTokenInStablecoin = orderTokenIn.isStablecoin;

      let tokenIn = orderTokenIn;

      if (tokenOut.address === tokenIn.address || (isTokenInStablecoin && tokenOut.isStablecoin)) {
        const firstAvailableToken = networkTokens.find((t) => {
          const isNotTheSameToken = t.address !== tokenOut.address;
          const isNotStablecoin = !t.isStablecoin;

          if (isTokenOutStablecoin) {
            return isNotStablecoin && isNotTheSameToken;
          }

          return isNotTheSameToken;
        });

        if (firstAvailableToken) {
          tokenIn = firstAvailableToken;
        }
      }

      handleSelectedTokensChange({ tokenIn, tokenOut });
    },
    [orderTokenIn, networkTokens],
  );

  const handleBalanceClick = useCallback(() => {
    if (selectedTokenBalance) {
      handleAmountInChange(selectedTokenBalance);
      setTokenAmountInput(selectedTokenBalance);
    }
  }, [selectedTokenBalance]);

  const debouncedUpdateAmountIn = useDebounce((value: string) => {
    handleAmountInChange(value.toString());
  }, 500);

  const handleTokenAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputVal = e.target.value;
    inputVal = inputVal.replace(/[^\d.]/g, '');

    const firstDotIndex = inputVal.indexOf('.');
    if (firstDotIndex !== -1) {
      inputVal = inputVal.slice(0, firstDotIndex + 1) + inputVal.slice(firstDotIndex + 1).replace(/\./g, '');
    }

    setTokenAmountInput(inputVal);

    debouncedUpdateAmountIn(inputVal);
  };

  const handlePriceConditionChange = useCallback(
    (condition: PriceConditionTypeEnum) => {
      formOpenOrder.setValue('order.priceCondition.conditionType', condition, {
        shouldValidate: false,
      });

      if (
        pairLatestPrice &&
        condition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN &&
        !wasPriceConditionPriceInChanged
      ) {
        const marketPrice = 1 / Number(pairLatestPrice);

        handleOrderConditionPriceChange(marketPrice.toFixed(6));
        setPriceConditionPriceInput(marketPrice.toFixed(6));
      }

      if (
        pairLatestPrice &&
        condition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT &&
        !wasPriceConditionPriceInChanged
      ) {
        const marketPrice = Number(pairLatestPrice);

        handleOrderConditionPriceChange(marketPrice.toFixed(6));
        setPriceConditionPriceInput(marketPrice.toFixed(6));
      }

      if (tokenInUsdPrice && condition === PriceConditionTypeEnum.TOKEN_IN_USD && !wasPriceConditionPriceInChanged) {
        const marketPrice = 1 / tokenInUsdPrice;

        handleOrderConditionPriceChange(marketPrice.toFixed(6));
        setPriceConditionPriceInput(marketPrice.toFixed(6));
      }

      if (tokenOutUsdPrice && condition === PriceConditionTypeEnum.TOKEN_OUT_USD && !wasPriceConditionPriceInChanged) {
        const marketPrice = Number(1 / tokenOutUsdPrice);

        handleOrderConditionPriceChange(marketPrice.toFixed(6));
        setPriceConditionPriceInput(marketPrice.toFixed(6));
      }
    },
    [pairLatestPrice, tokenInUsdPrice, tokenOutUsdPrice],
  );

  const debouncedUpdatePriceInput = useDebounce((value: string) => {
    handleOrderConditionPriceChange(value, true);
  }, 500);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputVal = e.target.value;
    inputVal = inputVal.replace(/[^\d.]/g, '');

    const firstDotIndex = inputVal.indexOf('.');
    if (firstDotIndex !== -1) {
      inputVal = inputVal.slice(0, firstDotIndex + 1) + inputVal.slice(firstDotIndex + 1).replace(/\./g, '');
    }

    setPriceConditionPriceInput(inputVal);

    debouncedUpdatePriceInput(inputVal);
  };

  useEffect(() => {
    if (pairLatestPrice && !isPairLatestPriceFetching && !wasPriceConditionPriceInChanged) {
      handleSetMarketplacePrice();
    }
  }, [pairLatestPrice, isPairLatestPriceFetching, wasPriceConditionPriceInChanged]);

  useEffect(() => {
    if (pairLatestPrice && !isPairLatestPriceFetching && orderPriceConditionPrice) {
      validateOpenOrderPrice({
        latestTokenPairPrice: pairLatestPrice,
        orderTokenInUsdPriceOracle: orderTokenIn.usdPriceOracle,
        orderTokenOutUsdPriceOracle: orderTokenOut.usdPriceOracle,
        price: orderPriceConditionPrice,
        priceCondition: orderPriceConditionType,
        tokenInUsdPrice,
        tokenOutUsdPrice,
      });
    }
  }, [
    pairLatestPrice,
    tokenInUsdPrice,
    tokenOutUsdPrice,
    isPairLatestPriceFetching,
    orderPriceConditionType,
    orderPriceConditionPrice,
    validateOpenOrderPrice,
  ]);

  return (
    <div className="dark:bg-primary-light-gray/20 shadow-md dark:shadow-none p-3 rounded-lg">
      <TypographyH4 className="!text-sm mb-2">Open position</TypographyH4>

      <div className="flex items-start">
        <div className="flex flex-col w-full">
          <div className="flex justify-between items-center mb-1">
            <label className="text-left text-xs font-medium">{'You pay'}</label>
            {selectedTokenBalance !== undefined && (
              <div className="text-xs text-gray-600 cursor-pointer" onClick={handleBalanceClick}>
                Balance: {parseFloat(selectedTokenBalance).toLocaleString()}
              </div>
            )}
          </div>
          <div
            className={cn('flex items-center border rounded', {
              ' border-red-400 ': amountInValidationError || insufficientBalanceError,
            })}
          >
            <TooltipProvider>
              <Tooltip open={!!amountInValidationError}>
                <TooltipTrigger className="flex flex-col">
                  <Input
                    value={tokenAmountInput}
                    placeholder="0.0"
                    onChange={handleTokenAmountChange}
                    className={cn('p-1 mr-2 border-none', {
                      'text-red-500  placeholder:text-red-500 focus-visible:ring-0':
                        amountInValidationError || insufficientBalanceError,
                    })}
                  />
                </TooltipTrigger>
                <TooltipContent side="bottom" className="bg-white/30 dark:bg-primary-black/30 backdrop-blur-lg p-2">
                  {amountInValidationError && (
                    <TypographyS className="text-red-500">{amountInValidationError}</TypographyS>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TokenSelector
              key={`${orderTokenIn.address}-${orderTokenOut.address}`}
              tokenList={networkTokens}
              handleTokenChange={handleTokenInChange}
              selectedToken={orderTokenIn}
              className="px-2 border-none"
            />
          </div>
          <div className="flex gap-2 items-center mt-1">
            {tokenInUsdPrice && orderAmountIn && (
              <div className="text-xs text-gray-500 ">~${(+orderAmountIn * tokenInUsdPrice).toFixed(2)}</div>
            )}

            {insufficientBalanceError && (
              <TypographyS className="text-red-500 text-xs ml-auto">Insufficient balance</TypographyS>
            )}
          </div>
        </div>

        <Button
          variant="ghost"
          onClick={() => handleSelectedTokensChange({ tokenIn: orderTokenOut, tokenOut: orderTokenIn })}
          className="text-md text-center mx-4 mt-5 bg-transparent hover:bg-transparent text-black/60 hover:text-black/40 dark:text-white/80 hover:dark:text-white/60"
        >
          <ArrowRightLeftIcon />
        </Button>

        <div className="flex flex-col w-[110px]">
          <label className="text-left text-xs font-medium mb-1">You get</label>
          <div className="flex items-center justify-center border rounded">
            <TokenSelector
              key={`${orderTokenIn.address}-${orderTokenOut.address}`}
              tokenList={availableOrderTokensOut}
              handleTokenChange={handleTokenOutChange}
              selectedToken={orderTokenOut}
              className="justify-between px-2 border-none"
              contentClassName="justify-between w-full"
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <label className="font-medium text-sm">Condition</label>
      </div>

      <div className="flex flex-col w-full mt-2">
        <div className="text-start text-xs font-medium mb-1 flex items-center">
          {getOpenOrderHeaderLabel(orderTokenIn.symbol, orderTokenOut.symbol, orderPriceConditionType)}
        </div>

        <div className="flex items-center   gap-2">
          <PriceConditionInput
            open={!!orderPriceConditionValidationError || !!priceDifferenceWarning}
            inputValue={priceConditionPriceInput}
            onChange={handlePriceChange}
            disabled={isPairLatestPriceFetching}
            priceConditionValidationError={orderPriceConditionValidationError}
            priceDifferenceWarning={priceDifferenceWarning}
            token={getTokenFromPriceConditionType(orderTokenIn, orderTokenOut, orderPriceConditionType)}
            handleSetMarketplacePrice={handleSetMarketplacePrice}
          />

          <PriceConditionSelector
            handlePriceConditionChange={handlePriceConditionChange}
            tokenIn={orderTokenIn}
            tokenOut={orderTokenOut}
            selectedCondition={orderPriceConditionType}
          />
        </div>
      </div>
    </div>
  );
};
