import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { APIConfig } from '../../configs';
import { OHLCIntervalEnum, OHLCTOkenPairResponse } from '../../interfaces';
import { clearPairOHLCInfo, setIsPairOHLCLoading, setPairOHLCInfo } from '../../store';
import { formatOHLCToUnits } from '../../utils';
import { useAppDispatch } from '../store';

export type FetchOHLCArgs = {
  chainId: number;
  tokenInAddress: string;
  tokenOutAddress: string;
  interval: OHLCIntervalEnum;
  enabled?: boolean;
  from?: number;
  to?: number;
  limit?: number;
};

const fetchOHLC = async ({
  chainId,
  tokenInAddress,
  tokenOutAddress,
  interval,
  from,
  to,
  limit,
}: FetchOHLCArgs): Promise<OHLCTOkenPairResponse> => {
  const queryParams = new URLSearchParams();

  queryParams.append('interval', interval);

  if (from) {
    queryParams.append('from', from.toString());
  }

  if (to) {
    queryParams.append('to', to.toString());
  }

  if (limit) {
    queryParams.append('limit', limit.toString());
  }

  const queryParamsString = queryParams.toString();

  return axios
    .get<OHLCTOkenPairResponse>(
      `${APIConfig.baseURL}/ds/${chainId}/ohlc/${tokenInAddress}/${tokenOutAddress}?${queryParamsString}`,
    )
    .then((response) => response.data);
};

export const useGetPairOHLCInfo = () => {
  const dispatch = useAppDispatch();

  const { data, mutateAsync, isPending, error, variables } = useMutation({
    mutationFn: (args: FetchOHLCArgs) => fetchOHLC(args),
    onMutate() {
      dispatch(setIsPairOHLCLoading(true));
    },
    onSuccess(data, variabled) {
      const ohlcPeriod = [...data.ohlcPeriod].sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

      dispatch(
        setPairOHLCInfo({
          ...data,
          ohlcPeriod: ohlcPeriod.map((ohlc) => formatOHLCToUnits(variabled.chainId, variabled.tokenOutAddress, ohlc)),
          oldestTime: data.oldestTime,
        }),
      );
    },
    onError(_, variables) {
      dispatch(
        clearPairOHLCInfo({
          tokenInAddress: variables.tokenInAddress,
          tokenOutAddress: variables.tokenOutAddress,
          chainId: variables.chainId,
        }),
      );
    },
  });

  const ohlcPeriod = data?.ohlcPeriod
    ? [...data.ohlcPeriod]
        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
        .map((p) => formatOHLCToUnits(variables.chainId, variables.tokenOutAddress, p))
    : [];

  const pairOHLCInfo = data?.pair
    ? {
        ...data,
        ohlcPeriod,
        oldestTime: new Date(data.oldestTime),
      }
    : null;

  return {
    pairOHLCInfoError: error,
    isPairOHLCInfoFetching: isPending,
    pairOHLCInfo,
    refetchPairOHLCInfo: mutateAsync,
  };
};
