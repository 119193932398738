import { ChartLayout } from '../../components/order/chart/chart-layout';
import OrderList from '../../components/order/list/order-list';
import { StopMarketOrderForm } from '../../components/order/stop-market-order/stop-market-order-form';

export const StopMarketOrderPage = () => {
  return (
    <div className="grid grid-cols-12 gap-2 container px-2">
      <div className="col-span-8">
        <ChartLayout />
        <div className="my-2">
          <OrderList />
        </div>
      </div>
      <div className="col-span-4">
        <StopMarketOrderForm />
      </div>
    </div>
  );
};
