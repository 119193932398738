import { FC } from 'react';
import { Network } from '../../interfaces';
import { cn } from '../../utils';

type NetworkLabelProps = {
  network: Network;
  className?: string;
};

export const NetworkLabel: FC<NetworkLabelProps> = ({ network, className }) => {
  return (
    <div className={cn('flex items-center ', className)}>
      <img src={network.logoUri} alt={`${network.name} logo`} className="w-5 h-5 mr-2" />
      <span className="text-sm font-medium">{network.name}</span>
    </div>
  );
};
