import { useQuery } from '@tanstack/react-query';
import { getBalance } from '@wagmi/core';
import { Address } from 'viem';
import { wagmiConfig } from '../../configs';

export const useGetAccountBalance = ({
  isConnected,
  address,
  chainId,
}: {
  isConnected: boolean;
  address?: Address;
  chainId: number;
}) => {
  const { data } = useQuery({
    queryKey: ['accountBalance', chainId, address],
    queryFn: () =>
      getBalance(wagmiConfig, {
        address: address || ('' as Address),
      }),
    enabled: isConnected && !!address && !!chainId,
    refetchOnWindowFocus: true,
    staleTime: 1 * 60 * 1000, // 1 minute
  });

  return data;
};
