import { AxisRange, Chart } from 'klinecharts';

export const calculateInitialYAxisRange = (chart: Chart, defaultRange: AxisRange) => {
  const dataList = chart.getDataList();

  if (dataList.length === 0) {
    return {
      from: 0,
      to: 1,
      range: 1,
      realFrom: 0,
      realTo: 1,
      realRange: 1,
      displayFrom: 0,
      displayTo: 1,
      displayRange: 1,
    };
  }

  const chartWidth = chart.getSize('candle_pane')?.width || 0;

  if (!chartWidth) return defaultRange;

  const barSpace = chart.getBarSpace();

  const maxBarsFitInChart = Math.ceil(chartWidth / (barSpace.bar + barSpace.halfGapBar)) * 2;

  const fittedDataList = dataList.slice(-maxBarsFitInChart);

  let min = fittedDataList[0].low;
  let max = fittedDataList[0].high;

  fittedDataList.forEach((d) => {
    if (d.low < min) min = d.low;
    if (d.high > max) max = d.high;
  });

  const padding = (max - min) * 0.05;
  min -= padding;
  max += padding;

  const range = max - min;

  const axisRange: AxisRange = {
    from: min,
    to: max,
    range,
    realFrom: min,
    realTo: max,
    realRange: max,
    displayFrom: min,
    displayTo: max,
    displayRange: max,
  };

  return axisRange;
};
