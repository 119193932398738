import { utils } from 'klinecharts';
import { FC } from 'react';
import { TooltipLabel } from './tooltip-label';

type PriceTooltipProps = {
  volume: number;
  open: number;
  high: number;
  low: number;
  close: number;
  maxChartPrice: number;
  timestamp: number;
  precision: number;
};

export const PriceTooltip: FC<PriceTooltipProps> = ({ volume, open, high, low, precision, close, maxChartPrice }) => {
  const tooltipWidth = utils.calcTextWidth(utils.formatFoldDecimal(maxChartPrice, precision).toString(), 21);

  return (
    <div>
      <div className="flex w-fit flex-wrap gap-2 backdrop-blur-lg z-10 p-1 rounded-r-md bg-white/60 dark:bg-primary-black/20">
        <TooltipLabel
          style={{
            width: tooltipWidth,
          }}
          value={utils.formatFoldDecimal(open, precision)}
          title="Open:"
        />
        <TooltipLabel
          style={{
            width: tooltipWidth,
          }}
          value={utils.formatFoldDecimal(high, precision)}
          title="High:"
        />
        <TooltipLabel
          style={{
            width: tooltipWidth,
          }}
          value={utils.formatFoldDecimal(low, precision)}
          title="Low:"
        />
        <TooltipLabel
          style={{
            width: tooltipWidth,
          }}
          value={utils.formatFoldDecimal(close, precision)}
          title="Close:"
        />
      </div>
      <div className="flex w-fit flex-wrap gap-2 backdrop-blur-lg z-10 p-1 rounded-r-md bg-white/60 dark:bg-primary-black/20">
        <TooltipLabel
          style={{
            width: tooltipWidth,
          }}
          value={utils.formatFoldDecimal(volume, precision)}
          title="Vol:"
        />
      </div>
    </div>
  );
};
